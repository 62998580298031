import { ProjectCircle } from "../../mobx/ConfigStore"

/**
 * calulates the radius via some black magic -- creates a smooth scale from 5 to a max
 * not used anymore
 *
 * @param value the total value of the project
 */
export const getCircleSize = (value: number) => {
  const radius: number = (value * Math.exp(-Math.log10(value * 10))) / 200 + 5
  /// value/ 800000 + 5
  // 0 values will return null, so prevent that
  return radius ? radius : 5
}

/**
 * Finds the size catagory for the circle on the radar, with discrete steps
 *
 * @param value the value the circles are being sized on
 * @param projectCircles the catagories to check
 */
export const getCircleCatagory = (
  value: number,
  projectCircles: ProjectCircle[]
) => {
  // descending order
  projectCircles = projectCircles.slice().sort((a, b) => {
    return b.min - a.min
  })

  for (const catagory of projectCircles) {
    if (catagory.min <= value) {
      return catagory.size
    }
  }
  // base case
  return 0
}
