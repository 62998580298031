import { find, propEq, merge } from 'ramda';
import { mapProjects } from '../processCSV';
import { setConstraintsFromProject } from './setConstraintsFromProject';

const parseProjects = ({ data, state }: { data: any[]; state: any }) => {
  const projects = mapProjects(data);

  const newProjects = state.projects.map((p: { ID: any }) => {
    const project = find(propEq('dotId', p.ID))(projects);
    // @ts-ignore
    p = merge(p, project);
    return p;
  });
  const constraints = setConstraintsFromProject(projects[0], state);
  if (!constraints || !newProjects) {
    return;
  }

  return { constraints, projects: newProjects };
};

export { parseProjects };
