import * as React from "react"
import { observer, inject } from "mobx-react"
import ScenariosStore from "../../../mobx/ScenariosStore"

import SubHeader from "material-ui/Subheader"
import UiStore from "../../../mobx/UiStore"
import BaselineStore from "../../../mobx/BaselineStore"
import { toJS } from "mobx"
import { SelectField, MenuItem } from "material-ui"
import FlatButton from "material-ui/FlatButton"
import CapabilityStore from "../../../mobx/CapabilitiesStore"

export interface Props { }

export interface InjectedProps extends Props {
  baselineStore: BaselineStore
  scenariosStore: ScenariosStore
  capabilityStore: CapabilityStore
  uiStore: UiStore
}

export interface State {
  currentSelected: any
}

const styles: { [key: string]: React.CSSProperties } = {
  wrapper: {
    flex: "0 1 100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  container: {
    flex: "0 1 70%",
    overflowY: "auto",
    overflowX: "hidden",
    // zIndex: 100,
    padding: "20px",
    maxHeight: "400px",
    marginBottom: "10px",
    paddingTop: "0px"
  }
}

@inject(({ store }) => {
  return {
    scenariosStore: store.scenariosStore,
    uiStore: store.uiStore,
    baselineStore: store.baselineStore,
    capabilityStore: store.capabilityStore
  }
})
@observer
class Contributions extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      currentSelected: ""
    }
  }

  private onChangeContribution = (event: any, index: any, value: any) => {
    this.injected.capabilityStore.capabilityUpdate(
      this.injected.uiStore.capabilityMapClicked,
      value
    )
    this.reCalulate()
  }

  private reCalulate = () => {
    const { scenariosStore, capabilityStore } = this.injected
    this.injected.scenariosStore.reCalulateStrategicIndex("working")
    scenariosStore.edit()
    capabilityStore.updated = false
  }

  public render(): JSX.Element {
    const { uiStore, baselineStore } = this.injected

    const capabilities = toJS(baselineStore.capabilities)
    const currentcode = uiStore.capabilityMapClicked

    let index = capabilities.findIndex((cap: any) => cap.code === currentcode)
    let name = ""
    if (index !== -1) {
      name = capabilities[index].name
    }

    const capMode = uiStore.CapabilityMapMode
    const MaturityMode = uiStore.CapabilityMapMaturiyMode
    const mode = capMode === "maturity" ? MaturityMode : capMode
    let body = <div />

    switch (mode) {
      case "heatmap":
        break
      case "maturityPortfolio":
      case "maturityTarget":
        break

      case "importanceLevel":
        const { capabilityStore } = this.injected
        const multiID = capabilityStore.capabilityMultiplier(currentcode)
        const multi = capabilityStore.getMultiplierLevel(
          multiID ? multiID.multiId : -1
        )

        const currentMulti = multi ? multi.name : ""
        const Recalulate = capabilityStore.updated

        let mutliList = capabilityStore.multiplierLevelsList

        body = (
          <div>
            <div> Current Importance Level : {currentMulti} </div>
            <SelectField
              floatingLabelText="Select Importance Level"
              value={multiID}
              onChange={this.onChangeContribution}
              autoWidth={true}
            >
              {mutliList.map((m, index) => (
                <MenuItem
                  key={m.id}
                  value={m.id}
                  primaryText={m.name}
                  secondaryText={m.multiplier}
                />
              ))}
            </SelectField>
            <FlatButton disabled={!Recalulate} onClick={this.reCalulate}>
              Manually Recalulate
            </FlatButton>
          </div>
        )
        break

      default:
        break
    }

    return (
      <div>
        <SubHeader> Contribution : </SubHeader>
        {name}
        {body}
      </div>
    )
  }
}

export default Contributions
