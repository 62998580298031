import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';

import UiStore from '../mobx/UiStore';
import ScenariosStore from '../mobx/ScenariosStore';

import FlatButton from 'material-ui/FlatButton';
import Reply from 'material-ui/svg-icons/content/reply';
import ADD from 'material-ui/svg-icons/content/add';
import Settings from 'material-ui/svg-icons/action/settings';
import Visibility from 'material-ui/svg-icons/action/visibility';
import VisibilityOff from 'material-ui/svg-icons/action/visibility-off';
import Refresh from 'material-ui/svg-icons/navigation/refresh';
import Save from 'material-ui/svg-icons/content/save';
import PermissionStore from '../mobx/PermissionStore';

export interface Props {
  showExcluded: boolean;
  reloadData: () => void;
  resetScenario: () => void;
  changeShowExcluded: () => void;
  optimiseScenario:() => void
}

export interface InjectedProps extends Props {
  uiStore: UiStore;
  permissionStore: PermissionStore;
  scenariosStore: ScenariosStore;
}

@inject(({ store }) => {
  return {
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    permissionStore: store.permissionStore
  };
})
@observer
class ScenarioControlPanel extends React.Component<Props, {}> {
  private get injected() {
    return this.props as InjectedProps;
  }

  private addProject = () => {
    this.injected.uiStore.showAddProjectPopup = true;
  };
  private saveAsScenarioToDatabase = () => {
    this.injected.uiStore.openShowSaveScenarioPopup();
  };
  private settingsDialogOpen = () => {
    this.injected.uiStore.showScenarioSettingsDialog = true;
  };

  public render(): JSX.Element {
    const { scenariosStore } = this.injected;
    const optimizedMessage = scenariosStore.editedFromOptimised ? 'NOT OPTIMISED' : 'OPTIMISED'
    
    const canOptimise = this.injected.permissionStore.canOptimise()

    let hidden = (
      <FlatButton
        onClick={this.props.changeShowExcluded}
        icon={<VisibilityOff />}
        label="Hidden"
        labelPosition="after"
      />
    );
    if (this.props.showExcluded) {
      hidden = (
        <FlatButton
          onClick={this.props.changeShowExcluded}
          icon={<Visibility />}
          label="Visible"
          labelPosition="after"
        />
      );
    }

    return (
      <div style={{ position: 'relative', zIndex: 100, padding: '10px' }}>
        <div
          style={{
            color: '#4d4d4d',
            padding: 4,
            marginBottom: 6,
            fontFamily: 'sans-serif',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <div
              style={{
                fontSize: 18,
                paddingLeft: 4
              }}
            >
              {scenariosStore.working.name}
            </div>
            <div style={{ fontSize: '70%', paddingLeft: 4 }}>
              {optimizedMessage}
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex'
          }}
        >
          <FlatButton
            label="Edit"
            labelPosition="after"
            icon={<Settings />}
            onClick={this.settingsDialogOpen}
          />
          <FlatButton
            onClick={this.props.reloadData}
            labelPosition="after"
            icon={<Refresh />}
            label={'Reset'}
          />
          <FlatButton
            onClick={this.saveAsScenarioToDatabase}
            disabled={!scenariosStore.editedFromBaseline}
            labelPosition="after"
            label={'Save As'}
            icon={<Save />}
          />
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex'
          }}
        >
          <FlatButton
            onClick={this.addProject}
            label="Add Project"
            labelPosition="after"
            icon={<ADD />}
          />
          {hidden}
          
          <FlatButton
          onClick={this.props.optimiseScenario}
          label="Optimise"
          labelPosition="after"
          disabled={!canOptimise}          
          />
         
        </div>
      </div>
    );
  }
}

export default ScenarioControlPanel;
