import * as React from "react"
import * as ReactDOM from "react-dom"
import { observer, inject } from "mobx-react"

import UiStore from "../../mobx/UiStore"
import PermissionStore from "../../mobx/PermissionStore"
import { NumberBeautify } from "../../utils/NumberBeautify"

interface Props { }

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    uiStore: store.uiStore
  }
})
@observer
class HoverBox extends React.Component<Props, any> {
  private get injected() {
    return this.props as InjectedProps
  }
  public componentDidUpdate() {
    const domElement = ReactDOM.findDOMNode(this as any) as Element
    if (domElement !== null) {
      if (
        this.injected.uiStore.hoverBoxValue.position.y -
        domElement.getBoundingClientRect().height / 2 <
        0
      ) {
        this.injected.uiStore.hoverBoxValue.position.y =
          domElement.getBoundingClientRect().height / 2
      }

      if (
        this.injected.uiStore.hoverBoxValue.position.x -
        domElement.getBoundingClientRect().width / 2 <
        0
      ) {
        this.injected.uiStore.hoverBoxValue.position.x =
          domElement.getBoundingClientRect().width / 2
      }
    }
  }

  public render(): JSX.Element {
    const { uiStore } = this.injected
    if (!uiStore.showHoverBox) {
      return <div />
    }

    let boxContent = uiStore.hoverBoxValue.content

    if (!isNaN(parseFloat(boxContent))) {
      boxContent = NumberBeautify.numberFormat(parseFloat(boxContent)) + ""
    }

    return (
      <div
        id="thisBox"
        style={{
          zIndex: 1501,
          position: "absolute",
          pointerEvents: "none",
          left: uiStore.hoverBoxValue.position.x,
          top: uiStore.hoverBoxValue.position.y
        }}
      >
        <div
          style={{
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgb(33,33,33)",
            color: "#fff",
            borderRadius: "4px",
            padding: "15px"
          }}
        >
          <div
            id="title"
            style={{
              position: "relative",
              fontSize: "60%",
              textAlign: "center"
            }}
          >
            {uiStore.hoverBoxValue.title}
          </div>
          <div
            id="namePlace"
            style={{
              position: "relative",
              fontSize: "90%",
              textAlign: "center",
              display: "flex",
              maxWidth: "140px"
            }}
          >
            <div style={{ width: "100%" }}>{boxContent}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default HoverBox
