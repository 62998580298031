import * as React from "react"
import * as ReactDOM from "react-dom"

import { snapTools } from "./SnapTools/snapTools"
import LoadingSymbol from "./RightSideComponents/LoadingSymbol"
import {
  organiseBenefits,
  organiseInitiatives,
  organiseObjectives,
  organiseGoals,
  organiseCapabilities,
  calculateSpacing
} from "../utils/radarUtils"

import { Initiative, Objective } from "../mobx/BaselineStore"
import Project from "../mobx/Project"

export interface Props {
  selectedDotClicked: Project | undefined
  selectedDot: Project | undefined
  benefits: any[]
  projectInitiatives: Initiative[]
  goals: any[]
  objectives: Objective[]
  capabilityMap: any
  showHoverbox: (content: any, title?: any) => void
  hideHoverbox(): void
  changeHoverboxPosition: (x: number, y: number) => void
}

let domElement: any = null

class RadarOuterCircle extends React.Component<Props, any> {
  public myState: any
  constructor(props: Props) {
    super(props)
    this.state = {
      componentID: "snapContainer",
      thisSnap: null,
      lastJob: null,
      maxSize: 700,
      padding: 80,
      thisSize: 40,
      projectCircles: [],
      ringPositions: [],
      strategyLength: 4,
      selectedDot: null,
      animationHandler: null,
      frameRate: 60,
      segmentStore: [],
      selectedSegments: [],
      displayLoading: true
    }

    this.myState = {
      componentID: "snapContainer",
      thisSnap: null,
      lastJob: null,
      maxSize: 700,
      padding: 80,
      thisSize: 40,
      projectCircles: [],
      ringPositions: [],
      strategyLength: 4,
      selectedDot: null,
      selectedDotClicked: null,
      animationHandler: null,
      frameRate: 60,
      segmentStore: [],
      selectedSegments: [],
      displayLoading: true,
      fill: "#778089"
    }
  }

  private createUniqueID() {
    this.myState.componentID = snapTools.createUniqueID()
  }

  public componentWillMount() {
    this.createUniqueID()
  }

  public componentDidMount() {
    const thisDom = ReactDOM.findDOMNode(this) as any
    if (thisDom !== null) {
      domElement = thisDom.getElementsByTagName("svg")[0]
    }
    // if everything is ready when created create the elements, if not wait for an update

    if (this.myState.thisSnap == null) {
      // not 100% on how updating works so make sure it runs once (doesn't work in didMount properly)
      this.radarSetup()
      this.createSegments()
    }
  }

  public componentDidUpdate() {
    if (this.myState.thisSnap == null) {
      // not 100% on how updating works so make sure it runs once (doesn't work in didMount properly)
      this.radarSetup()
      this.createSegments()
    }

    if (this.myState.selectedDotClicked !== this.props.selectedDotClicked) {
      this.myState.selectedDotClicked = this.props.selectedDotClicked
      this.highlightSegments()
    }

    if (this.myState.selectedDot !== this.props.selectedDot) {
      this.myState.selectedDot = this.props.selectedDot
      this.highlightSegments()
    }
  }
  private fillCapability = (segment: any) => {
    switch (segment.level) {
      case 0:
        segment.path.attr("fill", "rgb(50, 100, 150)")
        break
      case 1:
        segment.path.attr("fill", "rgb(5, 111, 157)")
        break
      case 2:
        segment.path.attr("fill", "rgb(1, 141, 202)")
        break
      default:
        segment.path.attr("fill", "rgb(1, 141, 202)")
        break
    }
    return segment
  }
  private highlightSegments() {
    if (
      (this.myState.selectedDot !== null &&
        this.myState.selectedDot !== undefined) ||
      (this.myState.selectedDotClicked !== null &&
        this.myState.selectedDotClicked !== undefined)
    ) {
      let selectedProject: Project = this.myState.selectedDotClicked
      if (
        this.myState.selectedDot != null &&
        this.myState.selectedDot !== undefined
      ) {
        selectedProject = this.myState.selectedDot
      }

      if (selectedProject !== null) {
        const protectSegments: any[] = []
        let theseCapabilties = selectedProject.capabilities
        const theseCapabilitiesOld: any[] = []
        theseCapabilties.forEach((capability: any) => {
          theseCapabilitiesOld.push(capability.id)
        })

        theseCapabilties = theseCapabilitiesOld

        for (let i = this.myState.segmentStore.length - 1; i >= 0; --i) {
          if (
            "initiative" + selectedProject.initiativeId ===
            this.myState.segmentStore[i].id
          ) {
            this.myState.segmentStore[i].path.attr("fill", "rgb(119, 145, 131)")
          } else if (
            theseCapabilties.indexOf(this.myState.segmentStore[i].id) !== -1
          ) {
            this.myState.segmentStore[i] = this.fillCapability(
              this.myState.segmentStore[i]
            )

            if (this.myState.segmentStore[i].parent != null) {
              protectSegments.push(this.myState.segmentStore[i].parent)
            }
          } else {
            this.myState.segmentStore[i].path.attr("fill", this.myState.fill)
            for (let j = 0; j < protectSegments.length; j++) {
              if (this.myState.segmentStore[i].id === protectSegments[j]) {
                if (this.myState.segmentStore[i].parent !== "") {
                  protectSegments.push(this.myState.segmentStore[i].parent)
                }
                this.myState.segmentStore[i] = this.fillCapability(
                  this.myState.segmentStore[i]
                )
              }
            }
          }
        }
      }
    } else {
      for (let i = this.myState.segmentStore.length - 1; i >= 0; --i) {
        this.myState.segmentStore[i].path.attr("fill", this.myState.fill)
      }
    }
  }
  private getCenterCircle = () => {
    return (
      this.myState.maxSize / 2 + this.myState.padding + this.myState.thisSize
    )
  }
  private radarSetup() {
    this.myState.thisSnap = snapTools.createSnap(this.myState.componentID)
    this.setState({ displayLoading: false })

    const centerCircle = this.getCenterCircle()

    const outerBack = snapTools.pieSectorPath(
      { x: centerCircle, y: centerCircle },
      this.myState.maxSize / 2 + 10,
      this.myState.maxSize / 2 + this.myState.thisSize,
      0,
      359.999
    )

    snapTools.drawPath(
      this.myState.thisSnap.snap,
      outerBack,
      "0px",
      this.myState.fill,
      "white"
    )

    let backCircle = snapTools.createCircle(
      this.myState.thisSnap.snap,
      this.myState.maxSize,
      this.myState.padding + this.myState.thisSize,
      "none"
    )
    backCircle.attr("r", this.myState.maxSize / 2 + this.myState.thisSize)
    backCircle.attr("strokeWidth", "1px")

    backCircle = snapTools.createCircle(
      this.myState.thisSnap.snap,
      this.myState.maxSize,
      this.myState.padding + this.myState.thisSize,
      "none"
    )
    backCircle.attr("r", this.myState.maxSize / 2 + this.myState.thisSize - 30)
    backCircle.attr("strokeWidth", "1px")
  }

  private organiseSegments() {
    let outerArray: any[] = []
    const { capabilityMap } = this.props
    const capabilityOrganised = organiseCapabilities(capabilityMap)

    outerArray = outerArray.concat(capabilityOrganised)

    const benefitsOrganised = organiseBenefits(this.props.benefits)
    const initiativesOrganised = organiseInitiatives(
      this.props.projectInitiatives
    )
    const goalsOrganised = organiseGoals(this.props.goals)
    const objectivesOrganised = organiseObjectives(this.props.objectives)

    outerArray.push(benefitsOrganised)
    outerArray.push(initiativesOrganised)
    outerArray.push(goalsOrganised)
    outerArray.push(objectivesOrganised)

    return outerArray
  }

  private createSegments() {
    const outerArray = this.organiseSegments()
    const spaceDegree = calculateSpacing(outerArray)
    let trackDegree = -90

    const centerCircle = this.getCenterCircle()

    const segmentGroup = this.myState.thisSnap.snap.g()
    const dividerGroup = this.myState.thisSnap.snap.g()
    outerArray.forEach((outerArrayElement: any) => {
      const initialDegree = trackDegree

      // add dividing segements between the different types
      const dividingPosition = snapTools.positionAtAngle(
        { x: centerCircle, y: centerCircle },
        trackDegree + 90,
        this.myState.maxSize / 2 + 10
      )
      const dividingLine = snapTools.drawLineAtAngle(
        {
          x: dividingPosition.x + centerCircle,
          y: dividingPosition.y + centerCircle
        },
        trackDegree + 90,
        this.myState.thisSize - 10
      )
      const dividingLinePath = snapTools.drawPath(
        this.myState.thisSnap.snap,
        dividingLine,
        "3px",
        "none",
        "white"
      )

      dividerGroup.append(dividingLinePath)
      outerArrayElement.items.forEach((item: any) => {
        const outerBack = snapTools.pieSectorPath(
          { x: centerCircle, y: centerCircle },
          this.myState.maxSize / 2 + 10,
          this.myState.maxSize / 2 + this.myState.thisSize,
          trackDegree,
          spaceDegree
        )

        const thisPath = snapTools.drawPath(
          this.myState.thisSnap.snap,
          outerBack,
          "0.5px",
          this.myState.fill,
          "white"
        )

        thisPath.attr("pointer-events", "auto")

        segmentGroup.append(thisPath)

        let segmentObject = {}

        if (typeof item.level !== "undefined") {
          segmentObject = {
            typeName: outerArrayElement.name,
            id: item.id,
            parent: item.parent,
            name: item.name,
            path: thisPath,
            level: item.level,
            degree: spaceDegree / 2 + trackDegree
          }
        } else {
          segmentObject = {
            typeName: outerArrayElement.name,
            id: item.id,
            parent: item.parent,
            name: item.name,
            path: thisPath,
            degree: spaceDegree / 2 + trackDegree
          }
        }

        this.myState.segmentStore.push(segmentObject)

        snapTools.applyMouseOver2(segmentObject, this.myState.thisSnap)
        snapTools.applyMouseOut2(segmentObject, this.myState.thisSnap)

        trackDegree += spaceDegree
      })

      // initialDegree is set before the last loop
      const endDegree = trackDegree
      const labelPosition = snapTools.positionAtAngle(
        { x: centerCircle, y: centerCircle },
        initialDegree + (endDegree - initialDegree) / 2 + 90,
        this.myState.maxSize / 2 + 10 + this.myState.thisSize + 5
      )
      const labelHold = this.myState.thisSnap.snap.g()

      labelHold.attr(
        "style",
        " transform:translate(" +
        (centerCircle + labelPosition.x) +
        "px," +
        (centerCircle + labelPosition.y) +
        "px)"
      )

      if (outerArrayElement.items.length > 0) {
        const thisLabel = this.myState.thisSnap.snap.text(
          0,
          0,
          outerArrayElement.name
        )
        thisLabel.attr("fill", "#333")
        thisLabel.attr(
          "style",
          "transform:translate(0px, " +
          thisLabel.getBBox().height / 2 +
          "px);\
                font-size:12px"
        )

        if (initialDegree + (endDegree - initialDegree) / 2 + 90 > 180) {
          thisLabel.attr(
            "style",
            "transform:translate(-" +
            thisLabel.getBBox().width +
            "px, " +
            thisLabel.getBBox().height / 2 +
            "px);\
                    font-size:12px"
          )
        }

        labelHold.append(thisLabel)
      }
    })
  }

  private searchSnap = () => {
    if (this.myState.thisSnap != null) {
      if (typeof this.myState.thisSnap.customProperties !== "undefined") {
        if (
          typeof this.myState.thisSnap.customProperties.ringID !== "undefined"
        ) {
          this.myState.selectedSegments = [
            this.myState.thisSnap.customProperties.ringID
          ]

          const protectSegments: any[] = []
          this.myState.segmentStore.forEach((segmentStoreItem: any) => {
            if (this.myState.selectedSegments[0] === segmentStoreItem.id) {
              this.props.showHoverbox(
                segmentStoreItem.name,
                segmentStoreItem.typeName
              )

              if (domElement != null) {
                const thisPosition = domElement.getBoundingClientRect()

                const centerCircle = this.getCenterCircle()

                const dividingPosition = snapTools.positionAtAngle(
                  { x: centerCircle, y: centerCircle },
                  segmentStoreItem.degree + 90,
                  this.myState.maxSize / 2 + 120
                )
                const x = thisPosition.left + dividingPosition.x + centerCircle
                const y = thisPosition.top + dividingPosition.y + centerCircle
                this.props.changeHoverboxPosition(x, y)
              }

              segmentStoreItem.path.attr("fill", "rgb(255,0,0)")

              if (segmentStoreItem.parent !== "") {
                protectSegments.push(segmentStoreItem.parent)
              }
            } else {
              protectSegments.forEach((protectSegment: any) => {
                if (segmentStoreItem.id === protectSegment) {
                  if (segmentStoreItem.parent !== "") {
                    protectSegments.push(segmentStoreItem.parent)
                  }
                  segmentStoreItem.path.attr("fill", "rgb(255, 0, 0)")
                }
              })
            }
          })
        } else {
          this.myState.selectedSegments = []
          this.myState.segmentStore.forEach((segment: any) => {
            segment.path.attr("fill", this.myState.fill)
          })
        }
      }
    }
  }

  private clearSelection = () => {
    this.myState.selectedSegments = []
    this.props.hideHoverbox()
    this.myState.segmentStore.forEach((segment: any) => {
      segment.path.attr("fill", this.myState.fill)
    })

    this.highlightSegments()
  }

  public render(): JSX.Element {
    const returnItems = (
      <div
        style={{
          pointerEvents: "none",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: "0px",
          left: "0px",
          zIndex: 200,
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <LoadingSymbol inUse={this.state.displayLoading}>
          <svg
            onMouseMove={this.searchSnap}
            onMouseOut={this.clearSelection}
            id={this.myState.componentID}
            style={{
              pointerEvents: "none",
              width:
                this.myState.maxSize +
                this.myState.padding * 2 +
                this.myState.thisSize * 2,
              height:
                this.myState.maxSize +
                this.myState.padding * 2 +
                this.myState.thisSize * 2
            }}
          />
        </LoadingSymbol>
      </div>
    )

    return returnItems
  }
}

export default RadarOuterCircle
