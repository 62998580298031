import * as React from "react"
import { observer, inject } from "mobx-react"

import PermissionStore from "../../../mobx/PermissionStore"
import { getCurrentScenarios } from "../../../utils/getData"
import { ScenarioProps } from "../../../mobx/Scenario"
import { loadAndFormatScenario } from "../../../utils/loadData"
import { projectStatusToString } from "../../../utils/projectState"



import Toggle from "material-ui/Toggle"
import CircularProgress from "material-ui/CircularProgress"
import {MenuItem, SelectField } from "material-ui"
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
  } from "material-ui/Table"
import { map, sum } from "lodash"
import Project, {status} from "../../../mobx/Project"

import Button from "material-ui/FlatButton"
import { printSetup, exportToPrint } from "../../../utils/exportToPrint"
import ConfigStore from "../../../mobx/ConfigStore"

type Props = {
    scenarios: any
}

interface InjectedProps extends Props {
    permissionStore: PermissionStore,
    configStore: ConfigStore
}

export interface State {
    loaded: boolean
    summary : boolean
    selectedScenarios: number[]
    loadedScenarios: any[],
    projects: any[]
}

@inject(({store}) =>{
    return{
        permissionStore: store.permissionStore,
        configStore: store.configStore
    }
})

@observer
export class SummaryComparison extends React.Component<Props,State>{
    private get injected(){
        return this.props as InjectedProps
    }
    constructor(props: Props){
        super(props)
        this.state = {
            loaded: false,
            summary: true,
            selectedScenarios: [0],
            loadedScenarios: [],
            projects: []
        }
    }

    /**
     * changes summary state....
     * @param event not used
     */
    private onSummary = (event:any) =>{
        this.setState({summary : !this.state.summary})
    }

    private onScenariosChange = async (event: any, index: any, values: any) =>{
        this.setState({selectedScenarios: values, loaded:false})
        this.reloadScenarios(values)
    }

    private reloadScenarios = async (selectedScenarios:number[]) =>{
        const {
            permissionStore,
          } = this.injected
          const token = permissionStore.getToken()
        let scenarios: ScenarioProps[] = []
        let projects: any[] = []
        //get scenario ID's
        let scenarioIDs = []
        
        let statusNum = Object.keys(status).length /2
        for (let s =0; s < selectedScenarios.length; s++){
            let scenarioID  = this.props.scenarios[selectedScenarios[s]].id  
            scenarioIDs.push(scenarioID)
        }
        //get actual scenarios -- look at checking if we already have a scenario loaded
        for (let s =0; s < scenarioIDs.length; s++){
            let scenario = await loadAndFormatScenario(scenarioIDs[s], token)
            scenarios.push(scenario)
        }
        //get projects -- have to loop though each scenario
        // need to add a status counter here
        for (let s = 0; s < scenarios.length; s++){
            for (let p = 0; p < scenarios[s].projects.length; p++){
                let index = projects.findIndex((project:Project)=> project.code == scenarios[s].projects[p].code )
                let status = scenarios[s].projects[p].status as number
                if(index == -1){
                    let newStatus = new Array(statusNum).fill(0)
                    newStatus[status] = 1
                    projects.push({code:scenarios[s].projects[p].code, status: newStatus })
                }else{
                    projects[index].status[status] +=1
                }

            }
        }
        let projectsList = Array.from(projects)
        this.setState({loadedScenarios:scenarios, projects: projectsList, loaded :true})
    }

    private print = () => {
        const summary = this.state.summary
        const loadedScenarios = this.state.loadedScenarios
        

        let printContents:any, popupWin: any;
        let heading = "{baseline name} Projects Status Summary"
        let scenarioList = ""

        let printElements =document.getElementById('print-section')
        printContents = printElements != null ? printElements.innerHTML : "";

        if(summary){
            scenarioList = "Scenarios displayed: "
            loadedScenarios.map((scenario:any) => {
                console.log(scenario)
                scenarioList += scenario.name as string + ", "
            })
        }

        let setup: printSetup = {title : heading, subHeading: scenarioList,
            printContents : printContents, logoURL: this.injected.configStore.primaryLogoURL}
        
        exportToPrint(setup)

        
    }

    public render(): JSX.Element{
        //get required parts
        const summary = this.state.summary
        const scenarios = this.props.scenarios
        const projects = this.state.projects
        const loadedScenarios = this.state.loadedScenarios
        const selectedScenarios = this.state.selectedScenarios
        const loaded = this.state.loaded

        //variables
        let exportDisabled = false

        //create body
        let body = <div 
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
            <CircularProgress size={200} thickness={7} />
        </div>
        //show prompt if only one scenario selected
        if(selectedScenarios.length == 1){
            exportDisabled = true
            body = <h3 style= {styles.centered}>Please select more then one Scenario</h3>
        }
        //show summary
        else if(loaded && summary){
            let statusNum = Object.keys(status).length /2
            let statusList: any[] = []
            for(let s = 0; s < statusNum; s++){
                statusList.push(s)
            }
            body = <div id="print-section">
            <Table style={styles.table} >
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                <TableRow>
                <TableHeaderColumn>Projects</TableHeaderColumn>
                {statusList.map((stat:any, index:any) =>{
                    console.log(stat)
                    return <TableHeaderColumn>{projectStatusToString(stat)}</TableHeaderColumn>
                    }
                )}
                </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false} showRowHover={true} >
                {projects.map((project: any, id:any) => {
                    let projectStatus = new Array(statusNum)
                        for (let s = 0; s < scenarios.length; s++){
                            // do something
                        }
                        return <TableRow>
                            <TableRowColumn>{project.code}</TableRowColumn>
                            {statusList.map((stat:any, index:any) =>{
                                let rowStyle = {}
                                if(project.status[index] == 0){
                                    rowStyle = { color:"#E0E0E0"}
                                }
                                return <TableRowColumn style = {rowStyle}>{project.status[index]}</TableRowColumn> 
                                }
                            )}
                        </TableRow>   
                    })            
                }
            </TableBody>
        </Table>
        </div>
            
        }
        //show full detailed list
        else if(loaded){
            body = <div id="print-section">
            <Table style={styles.table}>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                <TableRow>
                <TableHeaderColumn>Projects</TableHeaderColumn>
                {loadedScenarios.map((scenario:any, index:any)=>{
                 return<TableHeaderColumn tooltip={scenario.description}>{scenario.name}
                </TableHeaderColumn>
                })
                }
                </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false} showRowHover={true} >
                {projects.map((project: any, id:any) => {
                        return <TableRow>
                            <TableRowColumn>{project.code}</TableRowColumn>
                            {loadedScenarios.map((scenario:any, index:any)=>{
                                let p = scenario.projects.findIndex((proj:Project) => proj.code == project.code)
                                if(p != -1){
                                   return <TableRowColumn >{projectStatusToString(scenario.projects[p].status)}</TableRowColumn>
                                }else{
                                    return <TableRowColumn >N/A</TableRowColumn>    
                                }
                            })}
                        </TableRow>   
                    })            
                }
            </TableBody>
        </Table>
        </div>
        }

        return(
            <div>
                <div style={styles.centered}>
                    <Toggle label={"Show Summary"}
                    onToggle={this.onSummary}
                    toggled={summary} 
                    >

                    </Toggle>
                    
                    <SelectField
                    floatingLabelText = "Select Scenarios"
                    onChange = {this.onScenariosChange}
                    multiple = {true}
                    value = {selectedScenarios}
                    
                    >
                        {scenarios.map((scenario:any, scenarioIndex:any) =>{
                                return(
                                    <MenuItem
                                    key={scenarioIndex}
                                    value ={scenarioIndex}
                                    primaryText={scenario.name}
                                    />
                                )
                            }
                        )}
                    </SelectField>
                    <Button onClick={this.print} style={styles.button} disabled = {exportDisabled} >
                        Export to PDF
                    </Button>
                </div>
                {body}
            </div>
        )

    }
}

const styles: any = {
    centered: {
        width: "40%", 
        margin: "auto",
        textAlign: "center"
    },
    button :{
        marginLeft:"30%",
        marginRight:"30%"
    },
    row: {
      display: "flex",

    },
    twoColumn: {
        flex: "50%",
        marginTop:"50px",
        marginRight:"5px",
        marginLeft:"5px"
    },
    hidden: {
        display: "none",        
    },
    borderLeft:{
        borderLeft: "1px solid #E0E0E0"
    },
    table:{
        minHeight: "50px"
    }

  }

  export default SummaryComparison