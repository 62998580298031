export const getJobs = (token: string): Promise<any> => {
  return makeGetRequest(`projectData`, token)
}

export const getConfig = (token: string, id: string): Promise<any> => {
  return makeGetRequest(`config/${id}`, token)
}

export const getCurrentScenarios = (token: string): Promise<any> => {
  return makeGetRequest(`scenarios`, token)
}

export const getScenario = (token: string, id: string): Promise<any> => {
  return makeGetRequest(`scenario/${id}`, token)
}

export const getGlobalData = (token: string): Promise<any> => {
  return makeGetRequest("globalData", token)
}

export const getBaselines = (token: string): Promise<any> => {
  return makeGetRequest("baselines", token)
}

export const getCapabilities = (token: string): Promise<any> => {
  return makeGetRequest("capabilities/current", token)
}

export const getMaturityLevels = (token: string): Promise<any> => {
  return makeGetRequest("maturitylevels/current", token)
}

// export const getLogo = (token:string, id:string): Promise<any> =>{
//   return makeImageGetRequest(`image/logo/${id}`, token)
// }

// export const getLogos = (token:string) : Promise<any> => {
//   return makeGetRequest("image/logos", token)
// }

const makeGetRequest = (url: string, token: string): Promise<any> => {
  return new Promise((resolve, reject) =>
    fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token
      }
    })
      .then(async result => {
        resolve(result.json())
      })
      .catch(reject)
  )
}

const makeImageGetRequest = (url: string, token: string): Promise<any> => {
  return new Promise((resolve, reject) =>
    fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token
      }
    })
      .then(async result => {
        resolve(result.blob())
      })
      .catch(reject)
  )
}
