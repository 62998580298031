import { drawLineAtAngle } from './drawLineAtAngle'
import { positionAtAngle } from './positionAtAngle'
import {
  applyMouseMove,
  applyMouseOut,
  applyMouseOut2,
  applyMouseOver,
  applyMouseOver2
} from './applyMouse'
import { createSnap, createUniqueID } from './createSnap'
import { createPattern } from './createPattern'
import { createCircle } from './createCircle'
import { createRect, createLine } from './createRect'
import { drawPath } from './drawPath'
import { pieSectorPath } from './pieSectorPath'
import { arcLinePath, arcLinePath2 } from './arcLinePath'
import { pieLinkedOuterPath } from './pieLinkedOuterPath'
import { pieDividingSegments } from './pieDividngSegments'

export const snapTools = {
  createSnap,
  createUniqueID,
  createPattern,
  createCircle,
  createRect,
  createLine,
  drawPath,
  pieSectorPath,
  arcLinePath,
  arcLinePath2,
  pieLinkedOuterPath,
  pieDividingSegments,
  drawLineAtAngle,
  positionAtAngle,
  applyMouseMove,
  applyMouseOut,
  applyMouseOut2,
  applyMouseOver,
  applyMouseOver2
}
