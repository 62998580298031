import * as React from "react"

import { observer } from "mobx-react"

import ResourceBar from "./ResourceBar"
import Project from "../../mobx/Project"
import { isNullOrUndefined } from "util"

interface Props {
  dialChoice: any[]
  selectedProject: string | undefined
  projectArray: Project[]
  showHoverbox: (content: any, title?: any) => void
  hideHoverbox(): void
  changeHoverboxPosition: (x: number, y: number) => void
}

const ResourceBarList = observer((props: Props) => {
  const {
    dialChoice,
    showHoverbox,
    hideHoverbox,
    changeHoverboxPosition,
    selectedProject,
    projectArray
  } = props
  let benefitsString = isNullOrUndefined(projectArray[0].benefits.name) ? "Benefits" : projectArray[0].benefits.name

  return (
    <div style={styles.resourceBarContainer}>
      <div style={styles.resourceBarContainer}>
        <div style={styles.nameContainer}>Expenditure</div>
        <ResourceBar
          projectArray={projectArray}
          showHoverbox={showHoverbox}
          hideHoverbox={hideHoverbox}
          changeHoverboxPosition={changeHoverboxPosition}
          compareTo={""}
          selectedProject={selectedProject}
          resource={""}
          resourceIndex={"Expenditure"}
        />
        <div style={styles.nameContainer}>{benefitsString}</div>
        <ResourceBar
          projectArray={projectArray}
          showHoverbox={showHoverbox}
          hideHoverbox={hideHoverbox}
          changeHoverboxPosition={changeHoverboxPosition}
          compareTo={""}
          resource={""}
          resourceIndex={benefitsString}
          selectedProject={selectedProject}
        />
        {dialChoice.map((x: any) => {
          if (x.name !== "Expenditure" && x.name !== "benefit") {
            return (
              <div key={`BarGroup${x.id}`} style={styles.resourceBarContainer}>
                <div style={styles.nameContainer}>{x.name}</div>
                <ResourceBar
                  resource={""}
                  compareTo={""}
                  resourceIndex={x.name}
                  selectedProject={selectedProject}
                  projectArray={projectArray}
                  showHoverbox={showHoverbox}
                  changeHoverboxPosition={changeHoverboxPosition}
                  hideHoverbox={hideHoverbox}
                />
              </div>
            )
          }
          return <div key={`BarGroup${x.id}`} />
        })}
      </div>
    </div>
  )
})

const styles: { [index: string]: React.CSSProperties } = {
  nameContainer: {
    paddingLeft: "20px",
    paddingBottom: "3px",
    paddingTop: "10px",
    justifyContent: "space-around"
  },
  resourceBarListContainer: {
    paddingBottom: "20px",
    paddingTop: "20px",
    backgroundColor: "none"
  },
  resourceBarContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around"
  }
}

export { ResourceBarList }
