import { createListOfElements } from "../processCSV"
import { Initiative, Strategy } from "./interfaces"

/**
 * parses the propertise file
 * contains: Capabilities, strategies,initiatives, goals, lifecycle status of projects,contributions, prerequisite types
 *
 * @param param0
 */
const parseProperties = ({ data, state }: { data: any[]; state: any }) => {
  const capabilities = createListOfElements(data, "Capability", state.elements)
  const strategies = createListOfElements(data, "Strategy", state.elements).map(
    (s: any) => {
      const strategy: Strategy = {
        ID: s.ID,
        id: s.id,
        name: ""
      }
      return strategy
    }
  )

  const initiatives = createListOfElements(
    data,
    "Initiative",
    state.elements
  ).map((i: any) => {
    const initiative: Initiative = {
      ID: i.ID,
      id: i.id,
      name: "",
      strategy: ""
    }
    return initiative
  })

  const contributions = createListOfElements(
    data,
    "Contribution",
    state.projects
  )

  const projects = createListOfElements(data, "Project", state.projects)

  const prerequisites = createListOfElements(
    data,
    "Prerequisite",
    state.projects
  )

  if (
    !capabilities ||
    !contributions ||
    !initiatives ||
    !projects ||
    !strategies
  ) {
    return
  }

  return {
    capabilities,
    contributions,
    initiatives,
    projects,
    strategies,
    prerequisites,
    propertiesLoaded: true
  }
}

export { parseProperties }
