export const drawPath = (snap: any, path: any, width: any, fill: any, strokeColor: any) => {
  if (fill == null) {
    fill = 'rgba(255,0,0,0.2)'
  }

  if (strokeColor == null) {
    strokeColor = 'rgba(255,255,255,1.0)'
  }

  path = snap.path(path)

  path.attr({
    stroke: strokeColor,
    strokeWidth: width,
    fill,
    'stroke-linejoin': 'round'
  })

  return path
}
