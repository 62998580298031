import * as R from "ramda"
import { setConstraintsFromProject } from "./setConstraintsFromProject"
import { Constraint } from "./interfaces"
import { stringify } from "querystring"

const parseResources = ({ data, state }: { data: any[]; state: any }) => {
  let success = true
  // const projects = state.projects.map(
  //   (p: {
  //     resources: {
  //       map: (arg0: (res: { name: any; unit: any }) => void) => void;
  //     };
  //   }) => {
  //     p.resources.map((res: { name: any; unit: any }) => {
  //       const resource = R.find(R.propEq('resource_name', res.name))(data);
  //       if (!resource || !resource.resource_unit) {
  //         success = false;
  //       }
  //       res.unit = resource.resource_unit;
  //     });
  //     return p;
  //   }
  // );
  // if (!success) {
  //   return;
  // }

  // const constraints = setConstraintsFromProject(projects[0], state);

  const constraints: Constraint[] = state.constraints

  data.map((res: any, index: number) => {
    if (!res.resource_name || !res.resource_unit) {
      success = false
    }
    constraints[index].name = res.resource_name
    constraints[index].unit = res.resource_unit
  })


  if (!success) {
    return
  }
  return { constraints }
}

export { parseResources }
