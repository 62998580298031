import * as React from "react"
import { observer, inject } from "mobx-react"
import ScenariosStore from "../mobx/ScenariosStore"
import { ProjectProps, Mandatory } from "../mobx/Project"
import Settings from "material-ui/svg-icons/action/settings"
import Lock from "material-ui/svg-icons/action/lock"
import IconButton from "material-ui/IconButton"
import { redA700 } from "material-ui/styles/colors"
import getBenefit from "../utils/ProjectSolution"
import UiStore from "../mobx/UiStore"
import BaselineStore from "../mobx/BaselineStore"

interface InjectedProps extends Props {
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
}

export interface Props {
  project: ProjectProps
  showExcluded: boolean
  mouseEnter: () => void
  mouseLeave: () => void
  onPressProject: () => void
  onPressEdit: () => void
  newFontStyle: "italic" | "normal"
}

const styles: { [key: string]: React.CSSProperties } = {
  activeDotListElement: {
    whiteSpace: "nowrap",
    paddingBottom: 7,
    paddingTop: 7,
    color: "#333",
    fontSize: 12,
    userSelect: "none",
    cursor: "pointer",
    marginLeft: 3
  },
  activeDotListElement_highlighted: {
    whiteSpace: "nowrap",
    paddingBottom: 7,
    paddingTop: 7,
    color: "#333",
    background: "white",
    fontSize: 12,
    userSelect: "none",
    cursor: "pointer",
    borderLeft: "3px solid red"
  },
  deadDotListElement: {
    whiteSpace: "nowrap",
    paddingBottom: 7,
    paddingTop: 7,
    color: "#333",
    fontSize: 12,
    userSelect: "none",
    cursor: "pointer",
    marginLeft: 3,
    opacity: 0.5
  },
  activeDots: {
    direction: "ltr"
  }
}
@inject(({ store }) => {
  return {
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore
  }
})
@observer
export class ProjectListItem extends React.Component<Props, {}> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
  }

  public render(): JSX.Element {
    const scenariosStore = this.injected.scenariosStore
    const showProjectValue = this.injected.uiStore.showProjectValue
    const baselineStore = this.injected.baselineStore
    const x = this.props.project

    if (
      x.mandatory === Mandatory.Excluded &&
      this.props.showExcluded === false
    ) {
      return <div />
    }

    let mainStyle = styles.activeDotListElement
    let lock = "hidden"
    const idStyle: React.CSSProperties = {
      color: "darkslategrey",
      padding: 8,
      opacity: 1
    }
    const nameStyle: React.CSSProperties = {
      fontStyle: this.props.newFontStyle,
      fontWeight: "normal"
    }

    if (x.alive === false || x.mandatory === Mandatory.Excluded) {
      mainStyle = styles.deadDotListElement
      idStyle.opacity = 0.5
    }

    if (x.mandatory === Mandatory.Mandatory) {
      nameStyle.fontWeight = 900
    }

    let benefit = ""
    if (scenariosStore !== undefined && baselineStore.loaded) {
      if (
        scenariosStore.selectedProjectCode !== x.code &&
        scenariosStore.highlightedProjectCode === x.code
      ) {
        mainStyle = styles.activeDotListElement_highlighted
        if (!scenariosStore.selectedProject) {
          idStyle.color = "red"
        }
      }
      if (scenariosStore.selectedProjectCode === x.code) {
        lock = "visible"
        idStyle.color = "red"
      }
      let pIndex = scenariosStore.working.projects.findIndex(p => p.id == x.id)
      if (pIndex !== -1) {
        benefit =
          getBenefit(
            scenariosStore.working,
            pIndex,
            baselineStore.periods.length
          )[0].benefit
      }
    }
    let extra = <span style={idStyle}> </span>
    if (showProjectValue) {
      extra = <span style={idStyle}> {benefit}</span>
    }
    return (
      <div
        style={mainStyle}
        id={`activeDotList${x.code}`}
        key={x.code}
        onMouseOver={this.props.mouseEnter}
        onMouseOut={this.props.mouseLeave}
      >
        <IconButton
          onClick={this.props.onPressEdit}
          style={{
            width: 16,
            height: 16,
            padding: 0,
            float: "left"
          }}
          iconStyle={{ width: 14, height: 14 }}
        >
          <Settings />
        </IconButton>
        <div onClick={this.props.onPressProject}>
          <IconButton
            style={{
              width: 16,
              height: 16,
              padding: 0,
              float: "left"
            }}
            iconStyle={{ width: 14, height: 14, color: redA700 }}
          >
            <Lock visibility={lock} />
          </IconButton>
          <span style={idStyle}>{x.code}</span>
          <span style={nameStyle}>{x.name}</span>
          {extra}
        </div>
      </div>
    )
  }
}
