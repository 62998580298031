export const createPattern = (patternType: string, snapElement: any) => {
  let p: any = null

  switch (patternType) {
    case 'diagonalLines':
      p = snapElement.path('M10-5-10,15M15,0,0,15M0-5-20,15').attr({
        fill: 'none',
        stroke: '#fff',
        strokeWidth: 2
      })
      if (p !== null) {
        p = p.pattern(0, 0, 10, 10)

        break
      }
  }
  return p
}
