import { colourMap } from "../../config"
import { MaturityLevel } from "./interfaces"
import { MultiplierLevel } from "./../../mobx/CapabilitiesStore"
import map from "ramda/es/map"

const parseCapabilityLevels = ({ data }: { data: any[] }) => {
  const parsedData = data.filter(
    row => row.type && row.level && row.label && row.value
  )
  if (parsedData.length !== data.length) {
    return
  }
  const maturityLevelData = data.filter(
    d => d.type.toLowerCase() === "maturity"
  )

  const importanceLevelData = data.filter(
    d => d.type.toLowerCase() === "importance"
  )

  const maturityLevels: MaturityLevel[] = maturityLevelData.map(
    (row: { level: string; label: string; value: string }, index: number) => {
      const item = {
        level: parseInt(row.level),
        label: row.label,
        value: parseInt(row.value),
        color: colourMap[6][index]
      }

      return item
    }
  )

  const importanceLevels: any[] = importanceLevelData.map(
    (row: { level: string; label: string; value: string }, index: number) => {
      const importance = {
        level: parseInt(row.level),
        label: row.label,
        value: parseInt(row.value),
        color: colourMap[1][index]
      }

      return importance
    }
  )

  return { maturityLevels: maturityLevels, importanceLevels: importanceLevels }
}

export { parseCapabilityLevels }
