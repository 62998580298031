import { arcLinePath2 } from './arcLinePath'

export const pieLinkedOuterPath = (center: any, segmentArray: any, scale: any) => {
  // calculate the path to outline varying pie segments even at irregular sizes
  // ask for the angle and the radius

  let path = ''

  for (let i = 0; i < segmentArray.length; i++) {
    let start = false

    if (i === 0) {
      start = true
    }

    path += arcLinePath2(
      center,
      segmentArray[i] * scale,
      i * (360 / segmentArray.length) - 90,
      360 / segmentArray.length,
      start
    )
    path += ' '
  }

  path += ' Z'

  return path
}
