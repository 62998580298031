import { observable, toJS } from "mobx"
import RootStore from "./RootStore"

export interface HeatMap {
  id: number
  color: string
  value: number
  label: string
}

export interface ProjectCircle {
  min: number
  size: number
  label: string
}

export interface ConfigProps {
  maturityMap: number
  companyId: string
  configId: number
  heatMap: HeatMap[]
  primaryLogo: string
  partnerLogo: string
}

class ConfigStore {
  public rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
  @observable public maturityMap: number = 0
  @observable public companyId: string = ""
  @observable public configId: number = 1
  @observable public primaryLogo: string = "acuitas.svg"
  @observable public primaryLogoURL: string = ""
  @observable public partnerLogo: string = ""
  @observable public partnerLogoURL: string = ""
  @observable public forceRefresh: boolean = false
  @observable
  public heatMap: HeatMap[] = [
    {
      id: 1,
      color: "#F44336",
      value: 1,
      label: "low"
    },
    {
      id: 2,
      color: "#FFC107",
      value: 5,
      label: "medium"
    },
    {
      id: 3,
      color: "#4CAF50",
      value: 10,
      label: "high"
    }
  ]
  @observable
  public projectCircleScale: ProjectCircle[] = [
    {
      min: 0,
      size: 5,
      label: "0-20K"
    },
    {
      min: 20000,
      size: 10,
      label: "20k - 100k"
    },
    {
      min: 100000,
      size: 15,
      label: "100k - 1M"
    },
    {
      min: 1000000,
      size: 20,
      label: "1M - ∞"
    }
  ]

  // set the config for the application
  public setHeatMap(newHeatMap: [HeatMap]): void {
    this.heatMap = newHeatMap
  }
  public capabilityMapValues = false

  /**
   * set config
   * @param newConfig
   */
  public setConfig(newConfig: ConfigProps): void {
    this.maturityMap = newConfig.maturityMap || this.maturityMap
    this.companyId = newConfig.companyId || this.companyId
    this.heatMap = newConfig.heatMap || this.heatMap
    this.configId = newConfig.configId || this.configId
    this.primaryLogo = newConfig.primaryLogo || this.primaryLogo
    this.partnerLogo = newConfig.partnerLogo || this.partnerLogo
  }
}

export default ConfigStore
