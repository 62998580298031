import { CSSProperties } from 'react';
import {
  lightBlue100
,
} from 'material-ui/styles/colors';

const styles: { [key: string]: CSSProperties } = {
  root: {
    marginBottom: 0,
    display: 'flex',
    flex: '0 1 100%',
    overflowY: 'hidden'
  },
  leftWrapper: {
    flex: '0 0 400px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    borderRight: '1px solid #eee',
    background: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px'
  },
  rightWrapper: {
    flex: '0 1 100%',
    padding: 20,
    // display: 'grid',
    // gridTemplateColumns: '50% 50%',
    // gridTemplateRows: 'auto',
    // gridAutoFlow: 'row dense',  
    overflowY: 'scroll',
    background: '#fff'
  },
  reportSection: {
    margin: 50,
    border: '1px solid blue'
  },
  reportMenuItem: {
      borderBottom: '1px solid #eee'
  },
  reportMenuItemSelected: {
      borderBottom: '1px solid #eee',
      fontWeight: 600,
      backgroundColor: lightBlue100
  }
};
export default styles;
