export const drawLineAtAngle = (start: any, degree: any, scale: any) => {
  let path = 'M0 0 Z'

  if (scale !== 0 && !isNaN(degree)) {
    const position = {
      x: scale * Math.cos(Math.PI * (degree - 90) / 180),
      y: scale * Math.sin(Math.PI * (degree - 90) / 180)
    }

    path =
      'M' +
      start.x +
      ',' +
      start.y +
      ' L' +
      (position.x + start.x) +
      ',' +
      (position.y + start.y)
  }

  return path
}
