import * as R from "ramda"
import { Initiative, Strategy } from "./dataimport/interfaces"
import propEq from "ramda/es/propEq"
import project from "ramda/es/project"

/**
 *
 * @param csvdata
 */

export const mapProjects = (csvdata: any[]) => {
  // const resources = R.keys(csvdata[0]).filter(x =>
  //   R.startsWith("resource_name", x.toString())
  // )
  // const filteredResources = resources.filter(x => csvdata[0][x] !== "")
  // const indexes = filteredResources.map(x => R.drop(13, x.toString()))
  // const projectResources = (project: any) => {
  //   const res = indexes.map(i => {
  //     const newRes = {
  //       name: project[`resource_name${i}`],
  //       value: project[`projectresource_value${i}`],
  //       used: project[`projectresource_used${i}`],
  //       remains: project[`projectresource_remains${i}`],
  //       unit: ""
  //     }
  //     return newRes
  //   })
  //   return res
  // }

  const mappedData = csvdata
    .map(p => {

      return {
        dotId: p.project_id,
        name: p.project_name,
        status: p.project_status,
        benefitPeriod: p.projectbenefit_period,
        benefit: p.projectbenefit_value,
        benefitString: p.projectbenefit_string,
        projectType: p.type_id,
        duration: p.projectscenario_duration,
        // TODO remove temporarily unless decide what to do with it
        // startPeriods: p.projectscenario_startperiods,
        startPeriods: p.projectscenario_startperiods,
        startPeriod: p.projectscenario_startperiod,
        projectPhase: p.phase_id,
        mandatory: p.projectscenario_mandatory,
        resources: new Array<any>(parseInt(p.projectscenario_duration))
      }
    })
    .filter(x => x.dotId !== "")

  return mappedData
}

/**
 *
 * @param csvdata
 * @param ids
 */
function createListOfItems(csvdata: any[], ids: string[]) {
  const list: any[] = []
  ids.map(id => {
    const itemProps = R.mergeAll(
      R.filter(x => x.ID === id, csvdata).map(row => {
        const object = {
          [row.Key]: row.Value
        }
        return object
      })
    )
    list.push(R.mergeAll([{ id }, itemProps]))
  })
  return list
}

/**
 * used to connect names to ID's from CSV
 * @param csvdata
 * @param items
 */
export const setNamesToItems = (csvdata: any[], items: any[]) => {
  return items.map(item => {
    const name = R.filter(x => x.ID === item.id, csvdata).map(row => row.Name)
    item.name =
      !name || !name.length ? "Name not found for " + item.ID : name[0]
    return item
  })
}

/**
 *
 *
 * @param csvdata
 * @param elementType
 * @param elementsArray not used currently?
 */
export const createListOfElements = (
  csvdata: any[],
  elementType: string,
  elementsArray: any[]
) => {
  let ids
  //
  if (elementType === "Contribution" || elementType === "Prerequisite") {
    ids = R.filter(x => R.trim(x.Key) === elementType, csvdata).map(row => {
      return row.ID
    })
  } else if(elementType === "Capability"){
    //capabilities are no longer listed as a type so checking for subType as type
    ids = R.filter(x => R.trim(x.Key) === "SubType", csvdata).map(row =>{
      return row.ID
    })

}else{
    //
    ids = R.filter(
      x => x.Key === "Type" && R.trim(x.Value) === elementType,
      csvdata
    ).map(row => {
      return row.ID
    })
  }

  return createListOfItems(csvdata, ids)
}

/**
 *
 * @param csvdata
 * @param capabilities
 */
export const createCapabilityMap = (csvdata: any[], capabilities: any[]) => {
  const capabilityMap = R.filter(cap => cap.SubType === "Level 0", capabilities)
  capabilityMap.map(capability => {
    const level1Children = R.filter(
      x => x.Type === "CompositionRelationship" && x.Source === capability.id,
      csvdata
    ).map(row => {
      let cap = R.filter(
        x => x.SubType === "Level 1" && x.id === row.Target,
        capabilities
      )[0]
      cap = R.merge({ id: row.Target }, cap)

      return cap
    })

    capability.children = !level1Children ? [] : level1Children

    const children1 = capability.children
    for (let level1index = 0; level1index < children1.length; level1index++) {
      const level2Children = R.filter(
        x =>
          x.Type === "CompositionRelationship" &&
          x.Source === children1[level1index].id,
        csvdata
      ).map(row => {
        let cap = R.filter(
          x => x.SubType === "Level 2" && x.id === row.Target,
          capabilities
        )[0]
        cap = R.merge(cap, { id: row.Target })
        return cap
      })

      capability.children[level1index].children = !level2Children
        ? []
        : level2Children
    }
  })

  return capabilityMap
}

/**
 *
 * @param csvdata
 * @param projects
 * @param contributions
 * @param capabilities
 */
export const createProjectCapabilitiesMap = (
  csvdata: any[],
  projects: any[],
  contributions: any[],
  capabilities: any[]
) => {
  const projectCapabilities: any[] = []

  contributions.map(contribution => {
    const item = R.filter(x => x.ID === contribution.id, csvdata).map(row => {
      const project = R.filter(x => x.id === row.Source, projects)[0]
      const capability = R.filter(x => x.id === row.Target, capabilities)[0]
      return {
        dotId: project.ID,
        id: capability.ID,
        contribution: contribution.Contribution
      }
    })
    projectCapabilities.push(item[0])
  })

  const projectCapabilitiesMap: any[] = []
  projects.map(project => {
    const items: any[] = R.filter(
      x => x.dotId === project.ID,
      projectCapabilities
    ).map(row => {
      return { id: row.id, contribution: row.contribution }
    })
    const item = { dotId: project.ID, capabilities: JSON.stringify(items) }
    projectCapabilitiesMap.push(item)
  })

  return projectCapabilitiesMap
}

/**
 *
 *
 * @param projects
 * @param linkedIntitiatives
 * @param prerequisiteTypes
 * @param csvdata
 */
export const mergeElementsToProjects = (
  projects: ReadonlyArray<any>,
  linkedIntitiatives: any[] | ReadonlyArray<any>,
  prerequisiteTypes: any[] | ReadonlyArray<any>,
  csvdata: any
) => {
  const newProjects = projects.map(p => {
    const relations = csvdata.filter((x: any) => x.Source === p.id)
    const prerequisites = csvdata.filter((x: any) => x.Target === p.id)
    const projectPrerequisites = prerequisites
      .filter((r: any) => R.find(R.propEq("id", r.Source))(projects))
      .map((r: any) => {
        const prereq = R.find(R.propEq("id", r.Source))(projects)
        const prereqType = prerequisiteTypes.find((p: any) => p.id == r.ID)
        if (prereqType !== undefined) {
          // @ts-ignore
          return { name: prereq.dotId, type: prereqType.Prerequisite }
        } else {
          // @ts-ignore
          return { name: prereq.dotId, type: "before" }
        }
      })

    const projectInitiatives = relations.filter((r: any) =>
      R.find(R.propEq("id", r.Target))(linkedIntitiatives)
    )
    p.prerequisites = JSON.stringify(projectPrerequisites)
    if (projectInitiatives.length) {
      const initiative = R.find(R.propEq("id", projectInitiatives[0].Target))(
        linkedIntitiatives
      )
      // @ts-ignore
      p.initiative = initiative.ID
      // @ts-ignore
      p.strategy = initiative.strategy
    } else {
      p.initiative = "IN03-09"
      p.strategy = "S03"
    }
    return p
  })

  return newProjects
}

/**
 * merges an incoming csv of attributes to the existing project data, also adds benefits to the resources list (stored in same db table)
 *
 * @param projects the existing project data
 * @csvdata the incoming attributes data from the csv file
 */
export const mergeAttributesToProjects = (
  projects: ReadonlyArray<any>,
  constraints: ReadonlyArray<any>,
  csvdata: any
) => {
  const newProjects = projects.map(p => {
    //create a 2d array for resources over periods
    let resources = new Array(constraints.length)
      .fill(0)
      .map(x => Array())
    // constraints
    const attributes = csvdata.filter((x: any) => x.ProjID === p.ID)
    constraints.map((constraint, index: number) => {
      const values = attributes.filter(
        (x: any) => x.Attribute === constraint.name
      )
      values.map((value: any) => {
        resources[index].push({
          start: value.Start,
          end: value.End,
          value: value.value
        })
      })
    })


    //also map benefits from this file
    let benefits = new Array()
    const rawBenefits = attributes.filter(
      (x: any) => x.Attribute === p.benefitString
    )
    rawBenefits.map((value: any) => {
      //push the resource on
      benefits.push({
        start: value.Start,
        end: value.End,
        value: value.value
      })
    })
    p.benefits = benefits
    p.resources = resources
    return p
  })

  return newProjects
}

export const linkStrategiesToInitiatives = (
  csvdata: any,
  strategies: Strategy[],
  initiatives: Initiative[]
): any[] => {
  const linkedIntitiatives = initiatives.map(i => {
    let strategy
    let strategyRelation = csvdata
      .filter((x: any) => x.Target === i.id)
      .filter((r: any) => R.find(R.propEq("id", r.Source))(strategies))

    if (strategyRelation.length === 0) {
      strategyRelation = csvdata
        .filter((x: any) => x.Source === i.id)
        .filter((r: any) => R.find(R.propEq("id", r.Target))(strategies))
      strategy = R.find(R.propEq("id", strategyRelation[0].Target))(strategies)
    } else {
      strategy = R.find(R.propEq("id", strategyRelation[0].Source))(strategies)
    }
    // @ts-ignore
    i.strategy = strategy.ID
    return i
  })
  return linkedIntitiatives
}
