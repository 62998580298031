import { observable, computed, toJS, ObservableMap, action } from "mobx"
import { isUndefined, isNullOrUndefined, types } from "util"
import { formatProjects, Scenario, ScenarioProps } from "./Scenario"
import Project, { ProjectProps, Prerequisites, status } from "./Project"
import attribute from "./Attribute"
import RootStore from "./RootStore"
import { number } from "prop-types"
import { type } from "os"
import { duration } from "moment"
import { stringify } from "querystring"
import project from "ramda/es/project"
import { stat } from "fs"
import { calculateStatus } from "../utils/projectState"

class ScenariosStore {
  public rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable private _baseline: Scenario = new Scenario(this.rootStore)
  @observable private _working: Scenario = new Scenario(this.rootStore)
  @observable private _optimised: Scenario = new Scenario(this.rootStore)
  @observable private _old: Scenario = new Scenario(this.rootStore)

  @observable public id: number = 0
  @observable public baselineId: number = 0
  @observable public loaded: boolean = false

  @observable public selectedProjectCode: string | undefined = undefined
  @observable public highlightedProjectCode: string | undefined = undefined

  @computed
  get displayProjectCode(): string | undefined {
    if (this.selectedProjectCode) {
      return this.selectedProjectCode
    }
    return this.highlightedProjectCode
  }

  @computed
  get editedFromOptimised(): boolean {
    return this._working.version !== this.optimised.version
  }

  @computed
  get editedFromBaseline(): boolean {
    return this._working.version !== 0
  }
  @computed
  get optimised(): Scenario {
    return this._optimised
  }
  @computed
  get baseline(): Scenario {
    return this._baseline
  }
  @computed
  get working(): Scenario {
    return this._working
  }
  @computed
  get old(): Scenario {
    return this._old
  }

  public edit = () => {
    this._working.version++
  }

  private hasProjectChanged = (
    prevProject: any,
    nextProject: any,
    alive = true,
    duration = false,
    startPeriod = false
  ) => {
    return (
      (alive && prevProject.alive !== nextProject.alive) ||
      (duration && prevProject.duration !== nextProject.duration) ||
      (startPeriod && prevProject.startPeriod !== nextProject.startPeriod)
    )
  }

  public getScenarioDifference = () => {
    const oldProjects = toJS(this._old.projects)
    const optimisedProjects = toJS(this.optimised.projects)
    return this.CompareProjects(oldProjects, optimisedProjects)
  }

  public getDifferenceFromBaseline = () => {
    const baselineProjects = toJS(this._baseline.projects)
    const optimisedProjects = toJS(this.optimised.projects)
    return this.CompareProjects(baselineProjects, optimisedProjects)
  }

  private CompareProjects = (Projects1: Project[], Projects2: Project[]) => {
    const difference: Project[] = []
    if (!isNullOrUndefined(Projects1)) {
      Projects1.forEach((Project1) => {
        const project2 = Projects2.find(
          (subProject2) => subProject2.code === Project1.code
        )
        if (project2) {
          if (this.hasProjectChanged(Project1, project2)) {
            difference.push(project2)
          }
        } else {
          difference.push(Project1)
        }
      })
    }
    return difference
  }

  public setOptimised = (solutionArray: any[]) => {
    if (
      solutionArray[0].solution !== "infeasable" ||
      solutionArray[0].solution !== "error"
    ) {
      this._old = toJS(this.optimised)
      // TODO - update projects in optimised
      const solutionItem = solutionArray[0].solution.projects
      const projects = this._working.projects
      projects.forEach((p: Project) => {
        if (p.status == status.in_flight || status.scheduled) {
          p.alive = false
          p.edited = true
        }
      })
      for (let i = 0; i < solutionItem.length; i++) {
        const selectedProject = projects.filter(
          (p: Project) => p.code === solutionItem[i].project
        )[0]
        //don't set in_flight start periods or edited status
        if(selectedProject.status != status.in_flight)
        {
        selectedProject.startPeriod = solutionItem[i].starting_period + 0
        selectedProject.edited = true
        }else{
          selectedProject.edited = false
        }
        selectedProject.alive = true
        // selectedProject.edited = true
      }
      projects.forEach((p: Project) => {
        calculateStatus(p, this._working.currentPeriod)
      })
      // update editing tracking
      this._working.increaseVersion()
      this.edit()
      this._working.version = toJS(this._working.version) // TODO: why is this here?
      // copy data to optimised
      this._optimised = toJS(this._working)
    }
  }
  @computed
  get displayProject(): any | undefined {
    return this.getProject(this.displayProjectCode)
  }

  @computed
  get selectedProject(): any | undefined {
    return this.getProject(this.selectedProjectCode)
  }

  public setSelectedProject = (code: string | undefined) => {
    if (
      this._working.projects.find(
        (project: Project) => project.code === code
      ) ||
      isUndefined(code)
    ) {
      this.selectedProjectCode = code
    } else {
      this.selectedProjectCode = undefined
      throw new Error("invaild project code : " + code)
    }
  }

  @computed
  get highlightedProject(): any | undefined {
    return this.getProject(this.highlightedProjectCode)
  }

  public setHighlightedProject = (code: string | undefined) => {
    if (
      this._working.projects.find(
        (project: Project) => project.code === code
      ) ||
      isUndefined(code)
    ) {
      if (this.highlightedProjectCode !== code) {
        this.highlightedProjectCode = code
      }
    } else {
      this.highlightedProjectCode = undefined
      throw new Error("invaild project code: " + code)
    }
  }

  public addProject = (Project: Project) => {
    if (Project) {
      this._working.projects.push(Project)
      this.edit()
    }
  }

  /**
   * returns the project with given code, or undefined if none is found
   * uses working scenario for project lookup
   */
  public getProject = (code: string | undefined): any | undefined => {
    const localProject = this._working.projects
    if (code) {
      return localProject[this._working.codeToIndex(code)]
    } else {
      return undefined
    }
  }

  @action
  public reCalulateStrategicIndex = (
    target: "working" | "baseline" | "optimised"
  ) => {
    // const multi = this.rootStore.capabilityStore.multiplierLevelsList
    // const capMulti = this.rootStore.capabilityStore.capabilityMultipliersList

    // Is this the best way to do this?
    let projects
    switch (target) {
      case "baseline":
        projects = toJS(this.baseline.projects)
        break

      case "optimised":
        projects = toJS(this.optimised.projects)
        break

      case "working":
      default:
        projects = toJS(this.working.projects)
        break
    }

    for (let p = 0; p < projects.length; p++) {
      this.calculateProjectStrategicIndex(projects[p])
      // let strategicIndex = 0
      // projects[p].capabilities.forEach((cap) => {
      //   let multiplier = capMulti.find((i) => i.capId === cap.id)
      //   // doing this way cause comiplier error for simple if(multiplier){}
      //   let mID = multiplier ? multiplier.multiId : -1
      //   // values are *1 to make js not treat as string
      //   if (mID !== -1) {
      //     let multiValue = multi.find((m) => m.id === mID)
      //     if (multiValue) {
      //       strategicIndex += multiValue.multiplier * (cap.contribution * 1)
      //     } else {
      //       strategicIndex += cap.contribution * 1 // TODO combine these?
      //     }
      //   } else {
      //     strategicIndex += cap.contribution * 1 // TODO combine these?
      //   }
      // })
      // projects[p].strategicIndex = strategicIndex
    }

    switch (target) {
      case "baseline":
        this.baseline.projects = projects
        break

      case "optimised":
        this.optimised.projects = projects
        break

      case "working":
      default:
        this.working.projects = projects
        break
    }
    this.working.projects = projects
  }

  public calculateProjectStrategicIndex(project: Project){
    const multi = this.rootStore.capabilityStore.multiplierLevelsList
    const capMulti = this.rootStore.capabilityStore.capabilityMultipliersList
    let strategicIndex = 0
    project.capabilities.forEach((cap) => {
      let multiplier = capMulti.find((i) => i.capId === cap.id)
      // doing this way cause comiplier error for simple if(multiplier){}
      let mID = multiplier ? multiplier.multiId : -1
      // values are *1 to make js not treat as string
      if (mID !== -1) {
        let multiValue = multi.find((m) => m.id === mID)
        if (multiValue) {
          strategicIndex += multiValue.multiplier * (cap.contribution * 1)
        } else {
          strategicIndex += cap.contribution * 1 // TODO combine these?
        }
      } else {
        strategicIndex += cap.contribution * 1 // TODO combine these?
      }
    })
    project.strategicIndex = strategicIndex
  }

  @action
  public updateBaseline() {
    this._baseline.name = toJS(this._optimised.name)
    this._baseline.description = toJS(this._optimised.description)
    this._baseline.constraints = toJS(this._optimised.constraints)
    this._baseline.projects = toJS(this._optimised.projects)
    this._baseline.settings = toJS(this._optimised.settings)
    this._baseline.version = 0

    this._optimised.version = 0
    this._working = toJS(this._baseline)
    // this._optimised = toJS(this._baseline)
  }
  public getScenarioId = (): number => {
    return this.id
  }

  @action
  public setScenario(scenario: ScenarioProps) {
    if (this.loaded === true) {
      this._old = toJS(this.optimised)
    }

    this.id = scenario.id
    this._baseline.name = scenario.name
    this._baseline.description = scenario.description
    this._baseline.constraints = scenario.constraints
    this._baseline.projects = scenario.projects
    this._baseline.settings = scenario.settings
    this._baseline.isBaseline = scenario.isBaseline
    this._baseline.currentPeriod = scenario.currentPeriod
    this._baseline.version = 0

    this.reset()

    this.selectedProjectCode = scenario.selectedProjectCode || undefined
    this.highlightedProjectCode = scenario.highlightedProjectCode || undefined
    this.loaded = true
    this.baselineId = scenario.baselineId
  }

  public reset = () => {
    this._working = toJS(this._baseline)
    this._optimised = toJS(this._baseline)

    this.selectedProjectCode = undefined
    this.highlightedProjectCode = undefined
  }
}

export default ScenariosStore
