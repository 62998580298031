import { observable } from "mobx"
import * as R from "ramda"
import RootStore from "./RootStore"
import { Dialog } from "material-ui"
import { throws } from "assert"

export interface BaselineProps {
  loaded: boolean
  selectedConstraint: number | null
  currentBaseline: number
  benefits: any[]
  constraints: any[] //TODO make sure this matches attributes in scenarios OR change OptimisationParameter in Scenario
  initiatives: Initiative[]
  strategies: Strategy[]
  types: Type[]
  capabilityMap: CapabilityMap[]
  capabilities: Capability[]
  maturityMap: any[]
  periods: any[]
  goals: any[]
  capabilityMultipliers: any[]
}

// export interface CapabilityMap {
//   id: string
//   level: number
//   name: string
//   children?: CapabilityMap[]
//   matureCurrent?: any
//   matureTarget?: any
// }

export interface CapabilityMap {
  id: number
  code: string
  level: number
  name: string
  children?: CapabilityMap[]
  MaturityCurrentId?: number
  MaturityCurrent?: any
  MaturityTargetId?: number
  MaturityTarget?: any
}

// { children: CapabilityMap[]; id: number; code: string; name: string; level: number; ParentId: number | null; parent: string; MaturityCurrent: MaturityLevel | null; MaturityTarget: MaturityLevel | null; }'
// is not assignable to type 'CapabilityMap'.

export interface MaturityLevel {
  color: string
  id: number
  label: string
  level: number
  value: number
}

export interface Initiative {
  id: number
  code: string
  name: string
  StrategyId: number
}
export interface Objective {
  id: string
  objective_name: string
}

export interface Strategy {
  id: number
  code: string
  name: string
}
export interface Type {
  id: number
  code: string
  name: string
  color: string
}
// export interface Capability {
//   id: string
//   name: string
//   level: string
//   parent: string
// }

export interface Capability {
  id: number
  code: string
  name: string
  level: number
  ParentId: number | null
  parent: string
  MaturityCurrent: MaturityLevel | null
  MaturityTarget: MaturityLevel | null
}

class BaselineStore {
  public rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
  @observable public loaded: boolean = false
  @observable public selectedConstraint: number | null = null
  @observable public currentBaseline: number = 0
  @observable public initiatives: Initiative[] = []
  @observable public strategies: Strategy[] = []
  @observable public types: Type[] = []
  @observable public constraints: any[] = []
  @observable public benfits: any[] = []
  @observable public projects: any[] = []
  @observable public periods: any[] = []
  @observable public capabilities: Capability[] = []
  @observable public goals: any[] = []
  @observable public capabilityMap: CapabilityMap[] = []
  @observable public maturityMap: any[] = []

  public getStrategyCode(id: number) {
    const strat = this.strategies.find((s: Strategy) => s.id === id)
    if (strat) {
      return strat.code
    }
    return undefined
  }

  public setSelectedConstraint = (id: number) => {
    this.selectedConstraint = id
  }

  public getStrategyFromId(id: number) {
    const strategie = this.strategies.find((s: Strategy) => s.id === id)
    if (strategie) {
      return strategie
    } else {
      return this.strategies[0]
    }
  }

  public getTypeFromId(id: number) {
    const type = this.types.find((t: Type) => t.id === id)
    if (type) {
      return type
    } else {
      return this.types[0]
    }
  }

  public getInitiativeFromId(id: number) {
    const initiative = this.initiatives.find((i: Initiative) => i.id === id)
    if (initiative) {
      return initiative
    } else {
      return this.initiatives[0]
    }
  }

  public getCapabilityFromCode(code: string) {
    const capability = this.capabilities.find(
      (c: Capability) => c.code === code
    )
    if (capability) {
      return capability
    } else {
      return this.capabilities[0]
    }
  }

  /***
  * handles importing data from the database
  * 
  */
  public importBaseline(data: any) {
    const capabilityMap = mapCapabilities(data.capabilities)
    const newBaseline: BaselineProps = {
      initiatives: R.flatten(
        data.projectStrategies.map((strategy: any) => {
          const stratInitiatives = strategy.ProjectInitiatives.map(
            (initiative: any) => {
              const mappedInitiative = {
                id: initiative.id,
                code: initiative.code,
                name: initiative.name,
                StrategyId: initiative.ProjectStrategyId
              }
              return mappedInitiative
            }
          )

          return stratInitiatives
        })
      ),
      capabilityMap,
      maturityMap: data.maturityMap,
      strategies: data.projectStrategies,
      types: data.projectType,
      constraints: JSON.parse(data.constraints),
      benefits: data.benefits,
      currentBaseline: data.BaselineId,
      loaded: true,
      selectedConstraint: null,
      capabilities: data.capabilities,
      capabilityMultipliers: [],
      periods: data.periodSettings,
      goals: data.goals
    }

    // const settings = JSON.parse(data.settings)
    // let capabilities: Capability[] = []
    // settings.capabilities.forEach((capability: any) => {
    //   capabilities = capabilities.concat(
    //     this.unpackCapabilities(capability, "")
    //   )
    // // })
    // newBaseline.capabilities = capabilities

    this.setBaseline(newBaseline)
  }

  public setBaseline(newBaseline: BaselineProps) {
    this.loaded = newBaseline.loaded
    this.selectedConstraint = newBaseline.selectedConstraint
    this.currentBaseline = newBaseline.currentBaseline
    this.constraints = newBaseline.constraints
    this.benfits = newBaseline.benefits
    this.strategies = newBaseline.strategies
    this.capabilityMap = newBaseline.capabilityMap
    this.types = newBaseline.types
    this.initiatives = newBaseline.initiatives
    this.periods = newBaseline.periods
    this.capabilities = newBaseline.capabilities
    this.goals = newBaseline.goals
    this.maturityMap = newBaseline.maturityMap
  }
}

/***
   * recursively seperates capabilities out into a sim
   */
 export const convertCapabilities = (
    capabilities: Capability[],
    parentId: number | null
  ) => {
    const nextLevelCapabilities = getCapabilitiesByParentId(
      capabilities,
      parentId
    )
    return nextLevelCapabilities.map((capability: Capability) => {
      const capabilityMapItem: CapabilityMap = {
        ...capability,
        children: convertCapabilities(capabilities, capability.id)
      }
      return capabilityMapItem
    })
  }

  /***
   * Simpily calls convertCapabilities
   */
  export const mapCapabilities = (capabilities: Capability[]): CapabilityMap[] => {
    const capabilityMap: CapabilityMap[] = convertCapabilities(
      capabilities,
      null
    )
    return capabilityMap
  }

  export const getCapabilitiesByParentId = (
    capabilities: Capability[],
    parentId: number | null
  ): Capability[] => {
    return capabilities.filter(cap => cap.ParentId === parentId)
  }

export default BaselineStore
