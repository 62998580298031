export const auth0 = {
  clientId: process.env.AUTH0_CLIENTID || "",
  domain: process.env.AUTH0_DOMAIN || ""
}

// 100, 200, 300, 500, 700, 900
export const colourMap = [
  ["#FFCDD2", "#EF9A9A", "#E57373", "#F44336", "#D32F2F", "#B71C1C"], // red
  ["#FFECB3", "#FFE082", "#FFD54F", "#FFC107", "#FFA000", "#FF6F00"], // amber
  ["#C5CAE9", "#9FA8DA", "#7986CB", "#3F51B5", "#303F9F", "#1A237E"], // indigo
  ["#B3E5FC", "#81D4FA", "#4FC3F7", "#03A9F4", "#0288D1", "#01579B"], // light blue
  ["#C8E6C9", "#A5D6A7", "#81C784", "#4CAF50", "#388E3C", "#1B5E20"], // green
  ["#F44336", "#D2593B", "#B16E40", "#8F8446", "#6E994B", "#4CAF50"], // red green blend
  ["#ffffff", "#bfbfbf", "#ffe699", "#9bc2e6", "#0099cc", "#a9d08e"]
]
// 200, 500, 700
export const colorDropDown = [
  { color: "#EF9A9A", text: "black" },
  { color: "#F44336", text: "white" },
  { color: "#D32F2F", text: "white" },
  { color: "#FFE082", text: "black" },
  { color: "#FFC107", text: "black" },
  { color: "#FFA000", text: "black" },
  { color: "#9FA8DA", text: "white" },
  { color: "#3F51B5", text: "white" },
  { color: "#303F9F", text: "white" },
  { color: "#81D4FA", text: "black" },
  { color: "#03A9F4", text: "black" },
  { color: "#0288D1", text: "white" },
  { color: "#A5D6A7", text: "black" },
  { color: "#4CAF50", text: "black" },
  { color: "#388E3C", text: "white" },
  { color: "#CE93D8", text: "black" },
  { color: "#9C27B0", text: "white" },
  { color: "#7B1FA2", text: "white" }
]

export const inputFiles: any[] = [
  {
    label: "Capability Levels",
    name: "CapabilityLevels",
    step: 1,
    validated: false,
    error: false
  },
  {
    label: "Project Type",
    name: "ProjectTypes",
    step: 2,
    validated: false,
    error: false
  },
  {
    label: "Time Periods",
    name: "TimePeriods",
    step: 3,
    validated: false,
    error: false
  },
  {
    label: "Properties",
    name: "Properties",
    step: 4,
    validated: false,
    error: false
  },

  {
    label: "Elements",
    name: "Elements",
    step: 5,
    validated: false,
    error: false
  },
  {
    label: "Projects",
    name: "Projects",
    step: 6,
    validated: false,
    error: false
  },
  {
    label: "Relations",
    name: "Relations",
    step: 7,
    validated: false,
    error: false
  },
  {
    label: "Resources",
    name: "Resources",
    step: 8,
    validated: false,
    error: false
  },
  {
    label: "Attributes",
    name: "Attributes",
    step: 9,
    valudated: false,
    error: false
  }
]
