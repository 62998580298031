import * as React from 'react';
import { Provider } from 'mobx-react';

// import BusinessStore, { BusinessDatas } from './mobx/businessData'
// import PermissionStore, { Permissions } from './mobx/permissions'
// import UiStore, { UI } from './mobx/ui'
// import ScenarioStore, { Scenarios } from './mobx/scenarios'
import Routes from './routes';
// import ConfigStore, { Config } from './mobx/config'
// import BaselineStore, { Baseline } from './mobx/baseline'
import RootStore from './mobx/RootStore';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

const store = new RootStore();
export class App extends React.Component<any, any> {
  // public BusinessStore: BusinessDatas
  // public PermissionStore: Permissions
  // public UiStore: UI
  // public ScenarioStore: Scenarios
  // public ConfigStore: Config
  // public BaselineStore: Baseline

  constructor(props: any) {
    super(props);
    // this.BusinessStore = BusinessStore
    // this.PermissionStore = PermissionStore
    // this.UiStore = UiStore
    // this.ScenarioStore = ScenarioStore
    // this.ConfigStore = ConfigStore
    // this.BaselineStore = BaselineStore
  }

  public render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider>
          <Routes />
        </MuiThemeProvider>
      </Provider>
    );
  }
}
export default App;
