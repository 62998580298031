import * as React from 'react';
import { observer, inject } from 'mobx-react';

import UiStore from '../mobx/UiStore';
import PermissionStore from '../mobx/PermissionStore';
import ScenariosStore from '../mobx/ScenariosStore';

import { Tabs, Tab } from 'material-ui/Tabs';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import ClearIcon from 'material-ui/svg-icons/content/clear';

import { ProjectSettingsTab } from './ProjectSettingsTab'
import { SensitivityReportTab } from './SensitivityReportTab'

export interface State {
  tab: string;
}

interface Props {}

interface InjectedProps extends Props {
  permissionStore: PermissionStore;
  uiStore: UiStore;
  scenariosStore: ScenariosStore;
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore
  };
})
@observer
class ProjectSettingsDialog extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps;
  }
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: 'settings'
    };
  }

  private onDialogCloseBtnClick = () => {
    this.injected.uiStore.showProjectPropertiesPopup = false;
    this.injected.scenariosStore.setSelectedProject(undefined);
  };

  private onTabActive = (tab: Tab) => {
    this.setState({ tab: tab.props.value });
  };

  private tabContent = (tab: string): JSX.Element => {
    if (tab === 'settings') {
      return <ProjectSettingsTab />      
    } else {
      return (
        <SensitivityReportTab />
      );
    }
  };

  public render(): JSX.Element {
    const selectedProject = this.injected.scenariosStore.selectedProject;

    const title = (
      <div className={'title'}>
        <div style={styles.titleHeader}>
          <h3 style={styles.titleHeaderContent}>
            Project Settings - {selectedProject && selectedProject.name}
            <IconButton
              onClick={this.onDialogCloseBtnClick}
              style={styles.closeButton}
              iconStyle={styles.closeButtonIcon}
            >
              <ClearIcon />
            </IconButton>
          </h3>
        </div>
        <div style={styles.titleContent}>
          <Tabs
            tabItemContainerStyle={styles.tabItemContainer}
            value={this.state.tab}
            style={styles.titleSubHeader}
            inkBarStyle={styles.inkBarStyle}
          >
            <Tab
              value="settings"
              buttonStyle={styles.tab}
              label="Settings"
              onActive={this.onTabActive}
            />
            <Tab
              value="sensitivityReport"
              buttonStyle={styles.tab}
              label="Sensitivity Report"
              onActive={this.onTabActive}
            />
          </Tabs>
        </div>
      </div>
    );

    return (
      <Dialog
        title={title}
        modal={false}
        autoDetectWindowHeight={false}
        open={this.injected.uiStore.showProjectPropertiesPopup}
        autoScrollBodyContent={true}
        titleStyle={styles.title}
        bodyStyle={styles.dialogBody}
      >
        {this.tabContent(this.state.tab)}
      </Dialog>
    );
  }
}

const styles: { [key: string]: React.CSSProperties } = {
  closeButton: {
    padding: 0
  },
  closeButtonIcon: {
    width: 36,
    height: 36,
    color: 'white'
  },
  dialogBody: {
    minHeight: '65vh',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    backgroundColor: '#FFF',
    color: 'white',
    padding: 0
  },
  titleHeader: {
    backgroundColor: '#5859BC',
    color: 'white',
    padding: 0,
    flex: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleContent: {
    flex: 1,
    width: '100%'
  },

  titleHeaderContent: {
    backgroundColor: '#5859BC',
    fontSize: '1em',
    color: 'white',
    padding: '0.75rem',
    margin: 0,
    flex: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleSubHeader: {
    backgroundColor: '#FFF',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    color: 'white',
    padding: 0,
    flex: 1,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
};

export default ProjectSettingsDialog;
