import * as React from "react"
import FlatButton from "material-ui/FlatButton"
import { observer, inject } from "mobx-react"

import PermissionStore from "../../mobx/PermissionStore"
import IconButton from "material-ui/IconButton"
import AddCircle from "material-ui/svg-icons/content/add-circle"
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table"

import { getBaselines } from "../../utils/getData"

interface InjectedProps extends Props {
  permissionStore: PermissionStore
}

export interface Props {}
export interface State {
  currentBaseline?: number
  baselines: any[]
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore
  }
})
@observer
export class BaselineSelection extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      currentBaseline: undefined,
      baselines: []
    }
  }

  public async componentDidMount() {
    await this.loadBaselines()
  }

  private loadBaselines = async () => {
    const { permissionStore } = this.injected
    const data = await getBaselines(permissionStore.getToken())
    const currentNumber = data.baselines.filter(
      (b: { isCurrent: any }) => b.isCurrent
    )[0].id

    this.setState({
      baselines: data.baselines,
      currentBaseline: currentNumber
    })
  }

  private saveBaselineIsCurrent = async (): Promise<any> => {
    const body = {
      id: this.state.currentBaseline
    }
    const token = this.injected.permissionStore.getToken()
    await fetch(`${process.env.REACT_APP_API_URL}/baseline`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      body: JSON.stringify(body)
    })
  }

  private handleSave = () => {
    this.saveBaselineIsCurrent()
  }
  private handleChange = (value: number | undefined) => {
    this.setState({ currentBaseline: value })
  }

  public render(): JSX.Element {
    if (this.state.baselines === undefined) {
      return <div />
    }

    return (
      <div>
        <h3>Change Selected Baseline</h3>
        <Table>
          <TableHeader displaySelectAll={false} adjustForCheckbox={true}>
            <TableRow>
              <TableHeaderColumn>Name</TableHeaderColumn>
              <TableHeaderColumn>Description</TableHeaderColumn>
              <TableHeaderColumn>Created At</TableHeaderColumn>
              <TableHeaderColumn>Make Current</TableHeaderColumn>
            </TableRow>
          </TableHeader>

          <TableBody displayRowCheckbox={false}>
            {this.state.baselines.map((baseline, i) => {
              const onSelectBaseline = () => {
                this.handleChange(baseline.id)
              }
              const icon =
                this.state.currentBaseline === baseline.id ? (
                  <div />
                ) : (
                  <AddCircle />
                )

              return (
                <TableRow key={baseline.id}>
                  <TableRowColumn>{baseline.name}</TableRowColumn>
                  <TableRowColumn>
                    <div style={{ textOverflow: "ellipsis" }} />
                    {baseline.description}
                  </TableRowColumn>
                  <TableRowColumn>{baseline.createdAt as Date}</TableRowColumn>
                  <TableRowColumn>
                    <IconButton onClick={onSelectBaseline}>{icon}</IconButton>
                  </TableRowColumn>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <FlatButton
          key={"saveButton"}
          label="Save"
          primary={true}
          onClick={this.handleSave}
        />
      </div>
    )
  }
}
