import { setNamesToItems } from '../processCSV';

const parseElements = ({ data, state }: { data: any[]; state: any }) => {
  if (!state.capabilities || !state.propertiesLoaded) {
    return;
  }

  const capabilities = setNamesToItems(data, state.capabilities);
  const strategies = setNamesToItems(data, state.strategies);
  if (!capabilities || !strategies) {
    return;
  }

  return {
    capabilities,
    elements: data,
    elementsLoaded: true,
    strategies
  };
};

export { parseElements };
