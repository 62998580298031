import * as React from 'react';
import Paper from 'material-ui/Paper';
import IconButton from 'material-ui/IconButton';
import Menu from 'material-ui/svg-icons/navigation/menu';
import PieChart from 'material-ui/svg-icons/editor/pie-chart';
import Tune from 'material-ui/svg-icons/image/tune';
import UiStore from '../../mobx/UiStore';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { styles } from './styles';
import ScenariosStore from '../../mobx/ScenariosStore';
import PermissionStore from '../../mobx/PermissionStore';
import ConfigStore from '../../mobx/ConfigStore';

interface InjectedProps extends Props {
  uiStore: UiStore;
  scenariosStore: ScenariosStore;
  permissionStore: PermissionStore;
  configStore: ConfigStore;
}

type Props = RouteComponentProps<{}>;

@inject(({ store }) => {
  return {
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    permissionStore: store.permissionStore,
    configStore: store.configStore
  };
})

@observer
export class Header extends React.Component<Props, {}> {
  private get injected() {
    return this.props as InjectedProps;
  }
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  private handleToggle = () => {
    this.injected.uiStore.toggleMenu();
  };

  public render(): JSX.Element {
    const isScenario = this.props.location.pathname.includes('/scenarios/');
    const scenarioId = this.injected.scenariosStore.getScenarioId();

    const baseScenarioPath = `/scenarios`;
    const radarPath = scenarioId
      ? `${baseScenarioPath}/${scenarioId}/radar`
      : baseScenarioPath;
    const ganttPath = scenarioId
      ? `${baseScenarioPath}/${scenarioId}/gantt`
      : baseScenarioPath;

    return (
      <Paper zDepth={1} style={styles.header}>
        <IconButton
          onClick={this.handleToggle}
          style={{ position: 'absolute', right: 16, top: 0 }}
        >
          <Menu />
        </IconButton>

        {isScenario &&
          <Link style={styles.link} to={ganttPath}>
            <IconButton
              tooltip="Gantt Chart"
              style={{
                position: 'absolute',
                borderRight: '1px solid #ccc',
                right: 66,
                top: 0
              }}
            >
              <Tune />
            </IconButton>
          </Link>}

        {isScenario &&
          <Link style={styles.link} to={radarPath}>
            <IconButton
              tooltip="Radar"
              style={{
                position: 'absolute',
                borderRight: '1px solid #ccc',
                right: 116,
                top: 0
              }}
            >
              <PieChart />
            </IconButton>
          </Link>}

        <Link
          to={'/'}
          style={{
            width: '305px',
            height: '50px',
            left: 16,
            display: 'flex',
            position: 'relative',
            flexDirection: 'row'
          }}
        >
          <img
            src={`/logos/${process.env.REACT_APP_PARTNER_LOGO_FILE}`}
            style={{
              height: '100%'
            }}
          />
        </Link>
      </Paper>
    );
  }
}

export default withRouter(Header);
