import * as React from 'react';

export const styles: { [key: string]: React.CSSProperties } = {
  container: {
    width: '100%',
    position: 'relative',
    paddingBottom: '10px'
  },
  inputBox: {
    position: 'relative',
    float: 'left',
    clear: 'left',
    margin: 5
  },
  titleHeader: {
    backgroundColor: '#5859BC',
    color: 'white',
    padding: 0,
    flex: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleHeaderContent: {
    backgroundColor: '#5859BC',
    fontSize: '1em',
    color: 'white',
    padding: '0.75rem',
    margin: 0,
    flex: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
};
