import { Capability } from '../mobx/BaselineStore';

export const calculateRingPositions = (timeslots: number, maxSize: number) => {
  const thisOffset: any[] = []

  for (let i = 0; i < timeslots; i++) {
    const powerNumber = 0.85
    const maxPower = Math.pow(powerNumber, timeslots)
    const minPower = Math.pow(powerNumber, 0)
    const thisPower = Math.pow(powerNumber, i + 1)

    let thisScale = minPower - maxPower
    thisScale = maxSize / thisScale
    thisOffset.push(maxSize - thisScale * (thisPower - maxPower))
  }

  return thisOffset
}

export const organiseBenefits = (benefitsArray: any[]) => {
  const benefitsOrganised: { name: string; items: any[] } = {
    name: 'BENEFITS',
    items: []
  }
  benefitsArray.forEach((benefit: any) => {
    const outerObject = {
      id: 'benefit' + benefit.id,
      parent: '',
      name: benefit.project_benefits
    }

    benefitsOrganised.items.push(outerObject)
  })

  return benefitsOrganised
}

export const organiseInitiatives = (initiativesArray: any[]) => {
  const initiativesOrganised: { name: string; items: any[] } = {
    name: 'INITIATIVES',
    items: []
  }
  initiativesArray.forEach((initiative: any) => {
    const outerObject = {
      id: 'initiative' + initiative.id,
      parent: '',
      name: initiative.name
    }

    initiativesOrganised.items.push(outerObject)
  })

  return initiativesOrganised
}

export const organiseObjectives = (objectivesArray: any[]) => {
  const objectivesOrganised: { name: string; items: any[] } = {
    name: 'OBJECTIVES',
    items: []
  }
  objectivesArray.forEach((objective: any) => {
    const outerObject = {
      id: 'objective' + objective.id,
      parent: '',
      name: objective.objective_name
    }

    objectivesOrganised.items.push(outerObject)
  })

  return objectivesOrganised
}

export const organiseGoals = (goalsArray: any[]) => {
  const goalsOrganised: { name: string; items: any[] } = {
    name: 'GOALS',
    items: []
  }
  goalsArray.forEach((goal: any) => {
    const outerObject = {
      id: 'goal' + goal.id,
      parent: '',
      name: goal.goal_name
    }

    goalsOrganised.items.push(outerObject)
  })

  return goalsOrganised
}

export const organiseCapabilitiesOld = (capabilityMap: any[]) => {
  const capabilitiesOrganised: any[] = []

  // organise into old system, needs fix
  // this is very ugly
  for (let i = 0; i < capabilityMap.length; i++) {
    if (capabilitiesOrganised[parseInt(capabilityMap[i].level)] == undefined) {
      capabilitiesOrganised.push({
        name: 'CAPABILITIES LEVEL ' + capabilityMap[i].level,
        items: []
      })
    }
    let outerObject = {
      id: capabilityMap[i].id,
      parent: '',
      name: capabilityMap[i].name,
      level: capabilityMap[i].level
    }
    capabilitiesOrganised[parseInt(capabilityMap[i].level)].items.push(
      outerObject
    )
  }

  for (let i = 0; i < capabilityMap.length; i++) {
    for (let j = 0; j < capabilityMap[i].children.length; j++) {
      if (
        capabilitiesOrganised[parseInt(capabilityMap[i].children[j].level)] ==
        undefined
      ) {
        capabilitiesOrganised.push({
          name: 'CAPABILITIES LEVEL ' + capabilityMap[i].children[j].level,
          items: []
        })
      }
      let outerObject = {
        id: capabilityMap[i].children[j].id,
        parent: capabilityMap[i].id,
        name: capabilityMap[i].children[j].name,
        level: capabilityMap[i].children[j].level
      }
      capabilitiesOrganised[
        parseInt(capabilityMap[i].children[j].level)
      ].items.push(outerObject)
    }
  }

  for (let i = 0; i < capabilityMap.length; i++) {
    for (let j = 0; j < capabilityMap[i].children.length; j++) {
      for (
        let jj = 0;
        jj < capabilityMap[i].children[j].children.length;
        jj++
      ) {
        if (
          capabilitiesOrganised[
            parseInt(capabilityMap[i].children[j].children[jj].level)
          ] == undefined
        ) {
          capabilitiesOrganised.push({
            name:
              'CAPABILITIES LEVEL ' +
              capabilityMap[i].children[j].children[jj].level,
            items: []
          })
        }
        const outerObject = {
          id: capabilityMap[i].children[j].children[jj].id,
          parent: capabilityMap[i].children[j].id,
          name: capabilityMap[i].children[j].children[jj].name,
          level: capabilityMap[i].children[j].children[jj].level
        }
        capabilitiesOrganised[
          parseInt(capabilityMap[i].children[j].children[jj].level, undefined)
        ].items.push(outerObject)
      }
    }
  }
  return capabilitiesOrganised
}

export const organiseCapabilities = (capabilityMap: Capability[]) => {
  const capabilitiesOrganised: any[] = []

  capabilityMap.forEach(capability => {
    const level = capability.level
    let array = capabilitiesOrganised[level]
    if (!array) {
      array = capabilitiesOrganised[level] = {
        name: 'CAPABILITIES LEVEL ' + level,
        items: []
      }
    }
    array.items.push(capability)
  })

  return capabilitiesOrganised
}

export const calculateSpacing = (outerArray: any[]) => {
  let totalNumber = 0
  outerArray.forEach((outerArrayItem: any) => {
    totalNumber += outerArrayItem.items.length
  })

  totalNumber = 360 / totalNumber

  return totalNumber
}
