import * as React from "react"
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table"

export interface Props {
  projects: any[]
}

class DataImportProjectsTable extends React.Component<Props, {}> {
  constructor(props: any) {
    super(props)
  }

  public render(): JSX.Element {
    if (!this.props.projects || !this.props.projects.length) {
      return <div />
    } else {
      return (
        <Table>
          <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
            <TableRow>
              <TableHeaderColumn>ID</TableHeaderColumn>
              <TableHeaderColumn>Name</TableHeaderColumn>
              <TableHeaderColumn>Duration</TableHeaderColumn>
              <TableHeaderColumn>Benefit</TableHeaderColumn>
              <TableHeaderColumn>Phase</TableHeaderColumn>
              <TableHeaderColumn>Type</TableHeaderColumn>
              <TableHeaderColumn>Initiative</TableHeaderColumn>
              <TableHeaderColumn>Strategy</TableHeaderColumn>
              <TableHeaderColumn>Prerequisites</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {this.props.projects.map((p, index) => {
              const id = p.ID
              const name = p.name || "N/A"
              const duration = p.duration || "N/A"
              const benefit = p.benefit || "N/A"
              const phase = p.projectPhase || "N/A"
              const projectType = p.projectType || "N/A"
              const initiative = p.initiative || "N/A"
              const strategy = p.strategy || "N/A"
              const prerequisites = p.prerequisites || "N/A"
              return (
                <TableRow key={index}>
                  <TableRowColumn> {id}</TableRowColumn>
                  <TableRowColumn> {name}</TableRowColumn>
                  <TableRowColumn> {duration}</TableRowColumn>
                  <TableRowColumn> {benefit}</TableRowColumn>
                  <TableRowColumn> {phase}</TableRowColumn>
                  <TableRowColumn> {projectType}</TableRowColumn>
                  <TableRowColumn> {initiative}</TableRowColumn>
                  <TableRowColumn> {strategy}</TableRowColumn>
                  <TableRowColumn> {prerequisites}</TableRowColumn>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )
    }
  }
}

export default DataImportProjectsTable
