import * as auth0 from 'auth0-js'
import { resolveSoa } from 'dns'
import * as localforage from 'localforage'

import history from './history'

export type AuthType = auth0.WebAuth
const AUTH0_DOMAIN = 'acuitas.au.auth0.com'

export const initializeAuth = () => {
  const Auth: AuthType = new auth0.WebAuth({
    domain: 'acuitas.au.auth0.com',
    clientID: 'tfxeWEoxgDidyMqBH3srfw0_QH20BK6K',
    redirectUri: `${process.env.REACT_APP_AUTH0_CALLBACK_URL}`,
    audience: `https://${AUTH0_DOMAIN}/userinfo`,
    responseType: 'token id_token',
    scope: 'openid profile app_metaData'
  })

  return Auth
}

export const login = (Auth: AuthType) => {
  return Auth.authorize()
}

export const handleAuthentication = (Auth: AuthType, successCallback: any) => {
  Auth.parseHash(async (err, authResult) => {
    // console.log('HANDLE AUTH PARSE HASH');
    // console.log(err, authResult)
    if (authResult && authResult.accessToken && authResult.idToken) {
      const user = getUserFromAuthResult(authResult)
      await setSession(authResult, user)
      successCallback(authResult)
    }
    if (err) {
      alert(`Error: ${err.error}. Check the console for further details.`)
    }
    return false
  })
}

export const getUserFromAuthResult = (authResult: any) => {
  const {
    idTokenPayload,
    accessToken,
    expiresIn,
    idToken,
    state,
    tokenType
  } = authResult
  const { name, nickname, picture } = idTokenPayload
  const namespace = `https://${AUTH0_DOMAIN}_authorization`
  const rolesNamespace = `https://${AUTH0_DOMAIN}_roles`
  
  // const metaDataNamespace = `https://${AUTH0_DOMAIN}_metadata`
  const { permissions } = authResult.idTokenPayload[namespace]
  const roles = authResult.idTokenPayload[rolesNamespace]

  // const metaData = authResult.idTokenPayload[metaDataNamespace]

  const identity = {
    accessToken,
    expiresIn,
    idToken,
    state,
    tokenType
  }

  const profile = {
    name,
    nickname,
    picture,
    permissions,
    roles
  }
  const user = {
    identity,
    profile
  }
  return user
}


export const setSession = (authResult: any, user: any) => {
  // Set the time that the access token will expire at
  const expiresAt = JSON.stringify(
    authResult.expiresIn * 1000 + new Date().getTime()
  )
  localforage.setItem('expires_at', expiresAt)
  localforage.setItem('user', user)
}

export const getAccessToken = async () => {
  const accessToken: any = await localforage.getItem('access_token')
  if (!accessToken) {
    return ''
  }
  return accessToken.toString();
}

export const getProfile = async (cb: any, Auth: AuthType) => {
  const accessToken = await getAccessToken()
  Auth.client.userInfo(accessToken, (err, profile) => {
    cb(err, profile)
  })
}

export const logout = () => {
  // Clear access token and ID token from local storage
  localforage.removeItem('user')
  localforage.removeItem('expires_at')
  // navigate to the home route
  history.replace('/')
}

export const isAuthenticated = async () => {
  // Check whether the current time is past the
  // access token's expiry time
  const expiresString: any = await localforage.getItem('expires_at')
  if (!expiresString) {
    return false
  }
  const expiresAt = JSON.parse(expiresString.toString())
  return new Date().getTime() < expiresAt
}
