import { Constraint } from './interfaces';

const setConstraintsFromProject = (project: any, state: any): Constraint[] => {
  const constraints: Constraint[] = state.constraints;

  project.resources.map(
    (res: { name: string; unit: string }, index: number) => {
      constraints[index].name = res.name;
      constraints[index].unit = res.unit;
    }
  );
  return constraints;
};

export { setConstraintsFromProject };
