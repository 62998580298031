export const createRect = (
  snapElement:any,
  startOffset:any,
  width:any,
  height:any,
  fill:any,
  strokeWidth:any
) => {
  const rect = snapElement.rect(startOffset, 2, width, height)

  rect.attr({
    fill,
    stroke: '#fff',
    strokeWidth
  })
  return rect
}
export const createLine = (snapElement: any) => {
  const circle = snapElement.circle(100, 100, 100)

  circle.attr({
    fill: '#000',
    stroke: '#fff',
    strokeWidth: 1
  })
}
