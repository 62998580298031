import * as React from "react"

import { Type } from "../mobx/BaselineStore"
import { snapTools } from "./SnapTools/snapTools"
import Project from "../mobx/Project"

export interface Props {
  projectArray: Project[]
  difference: Project[]
  periodSettings: any[]
  displayProject?: string
  typeColors: Type[]
  currentPeriod: number
  oldPeriod: number
  openProjectDialog(): void
  setSelectedProject: (id: string | undefined) => void
}

class GanttChart extends React.Component<Props, any> {
  public myState: any
  constructor(props: Props) {
    super(props)
    this.state = {
      componentID: snapTools.createUniqueID(),
      displayLoading: true
    }

    this.myState = {
      componentID: "snapContainer",
      thisSnap: null,
      selectedDot: null,
      selectedDotClicked: null,
      displayProject: null,
      maxSize: 700,
      padding: 70,
      timePeriodSize: 60,
      projectBars: [],
      periodBars:[],
      periodSettings: [],
      animationHandler: null,
      frameRate: 60,
      animationTime: 0.3,
      animationObject: {
        currentFrame: 0,
        totalFrames: 18
      },
      projectArray: [],
      currentPeriod:-1,
      lastCurrentPeriod: -1
    }
  }

  public componentDidMount() {
    if (this.myState.thisSnap == null) {
      this.setupSVG()
      this.updateDetails()

      // setup animation Times
      this.myState.animationObject.totalFrames =
        this.myState.frameRate * this.myState.animationTime
    }
  }

  private setupSVG() {
    const { projectArray, periodSettings, typeColors, currentPeriod } = this.props
    this.setState({ displayLoading: false, lastCurrentPeriod : currentPeriod })

    this.myState.thisSnap = snapTools.createSnap(this.state.componentID)

    const barSize = this.myState.maxSize / projectArray.length

    const timePeriods = this.props.periodSettings.length

    const timePeriodSize = this.myState.maxSize / timePeriods
    this.myState.timePeriodSize = timePeriodSize

    // create timePeriod Bars
    for (let i = 0; i < timePeriods; i++) {
      const rect = this.myState.thisSnap.snap.rect(
        this.myState.padding + timePeriodSize * i,
        this.myState.padding,
        timePeriodSize,
        barSize * projectArray.length
      )
      rect.attr(
        "fill",
        "rgb(" + (i % 2) * 70 + "," + (i % 2) * 70 + "," + (i % 2) * 70 + ")"
      )
      rect.attr("stroke", "#fff")
      rect.attr("stroke-width", "2px")
      rect.attr("opacity", "0.05")

      const thisLabel = this.myState.thisSnap.snap.text(
        0,
        0,
        periodSettings[i].name
      )
      thisLabel.attr("fill", "#333")
      thisLabel.attr("style", "font-size:12px;transform:rotate(30deg);")

      
      if(i === currentPeriod){

        rect.attr("stroke-width", "6px")
        thisLabel.attr("fill", "#ff0000")
        rect.attr( "fill", "#ff0000")
        rect.attr("opacity", "0.1")
      }

      const textHold = this.myState.thisSnap.snap.g()
      textHold.append(thisLabel)
      textHold.attr(
        "style",
        "transform:translate(" +
        (this.myState.padding + timePeriodSize * i + 6) +
        "px," +
        (barSize * projectArray.length + this.myState.padding + 14) +
        "px);"
      )
      
      this.myState.periodBars.push({code:i, rect:rect,text:thisLabel})
    }

    const deadPattern = snapTools.createPattern(
      "diagonalLines",
      this.myState.thisSnap.snap
    )

    // setup all projects
    for (let i = 0; i < projectArray.length; i++) {
      const rect = this.myState.thisSnap.snap.rect(
        0,
        this.myState.padding + barSize * i,
        this.myState.maxSize,
        barSize
      )
      const rectDead = this.myState.thisSnap.snap.rect(
        0,
        this.myState.padding + barSize * i,
        this.myState.maxSize,
        barSize
      )
      const rectOutline = this.myState.thisSnap.snap.rect(
        0,
        this.myState.padding + barSize * i,
        this.myState.maxSize,
        barSize
      )

      rectDead.attr("fill", deadPattern)
      rectDead.attr("stroke-width", "0px")

      rectOutline.attr("fill", "none")
      rectOutline.attr("stroke", "#fff")
      rectOutline.attr("stroke-width", "2px")

      const TypeIndex = typeColors.findIndex(
        (item: any) => item.id === projectArray[i].typeId
      )

      rect.attr({
        fill: "#" + typeColors[TypeIndex].color,
        stroke: "#fff",
        style: "pointer-events:auto;",
        cursor: "pointer"
      })

      rect.attr("stroke-width", "2px")
      rect.attr("stroke-width", "0px")

      const rectBackground = this.myState.thisSnap.snap.g()
      const rectHold = this.myState.thisSnap.snap.g()

      rectBackground.append(rect)
      rectBackground.append(rectDead)

      rectHold.append(rectBackground)
      rectHold.append(rectOutline)

      rectHold.attr({
        style: "pointer-events:auto;"
      })

      rect.attr("width", timePeriodSize * projectArray[i].duration)
      rectDead.attr("width", timePeriodSize * projectArray[i].duration)

      rectHold.attr(
        "style",
        "transform:translateX(" +
        this.calulateBarPosition(projectArray[i].startPeriod) +
        "px)"
      )

      const barObject = {
        backShape: rect,
        deadShape: rectDead,
        shape: rectHold,
        backgroundGroup: rectBackground,
        highlightShape: rectOutline,
        code: projectArray[i].code,
        id: projectArray[i].id,
        project_id: projectArray[i].code,
        startPosition: this.calulateBarPosition(projectArray[i].startPeriod),
        startOpacity: 1,
        startDashGap: 0,
        startWidth: timePeriodSize * projectArray[i].duration
      }

      this.myState.projectBars.push(barObject)

      snapTools.applyMouseOver(barObject, this.myState.thisSnap)
      snapTools.applyMouseOut(barObject, this.myState.thisSnap)

      // append the text
      const thisLabel = this.myState.thisSnap.snap.text(
        this.myState.padding - 5,
        this.myState.padding + barSize * i + barSize / 2 + 5,
        projectArray[i].code
      )
      thisLabel.attr("fill", "#333")
      thisLabel.attr("style", "font-size:12px;")
      thisLabel.attr("text-anchor", "end")
    }
  }

  private calulateBarPosition(startPeriod: number) {
    return (
      this.myState.padding + this.myState.timePeriodSize * (startPeriod - 1)
    )
  }

  // // can be as many as needed to animate relevant SVG parts
  // private animateSVG() {
  //   const totalFrames = this.myState.animationObject.totalFrames

  //   if (this.myState.animationObject.currentFrame >= totalFrames) {
  //     console.log('gantt animation cleared')
  //     clearInterval(this.myState.animationHandler)
  //     this.myState.animationHandler = null
  //     this.myState.animationObject.currentFrame = 0
  //     this.myState.animationObject.frames = []
  //   }

  //   this.myState.animationObject.currentFrame++
  //   const currentFrame = this.myState.animationObject.currentFrame

  //   // animate the opacity, the outlines, the placement and the size
  //   // just play the animation frames
  //   const arrayLength = this.myState.projectBars.length

  //   const currentFrameSnapshot = this.myState.animationObject.frames[
  //     currentFrame
  //   ]

  //   for (let i = 0; i < arrayLength; i++) {
  //     this.myState.projectBars[i].shape.attr(
  //       'style',
  //       'transform:translateX(' + currentFrameSnapshot[i].position + 'px)'
  //     )
  //     this.myState.projectBars[i].startPosition =
  //       currentFrameSnapshot[i].position + 0

  //     this.myState.projectBars[i].highlightShape.attr(
  //       'stroke-dasharray',
  //       '5,' + currentFrameSnapshot[i].dashGap
  //     )
  //     this.myState.projectBars[i].startDashGap =
  //       currentFrameSnapshot[i].dashGap + 0

  //     this.myState.projectBars[i].backgroundGroup.attr(
  //       'opacity',
  //       currentFrameSnapshot[i].opacity
  //     )
  //     this.myState.projectBars[i].startOpacity = currentFrameSnapshot[i].opacity

  //     this.myState.projectBars[i].deadShape.attr(
  //       'opacity',
  //       (1 - currentFrameSnapshot[i].opacity) / 6 * 10
  //     )

  //     this.myState.projectBars[i].highlightShape.attr(
  //       'width',
  //       currentFrameSnapshot[i].width
  //     )
  //     for (
  //       let j = 0;
  //       j < this.myState.projectBars[i].backgroundGroup.children().length;
  //       j++
  //     ) {
  //       this.myState.projectBars[i].backgroundGroup
  //         .children()
  //         [j].attr('width', currentFrameSnapshot[i].width)
  //     }
  //   }
  // }

  private updateDetails() {
    const { projectArray, difference, currentPeriod } = this.props
    const dashGap = 2
    const timePeriodSize =
      this.myState.maxSize / this.props.periodSettings.length
    this.myState.timePeriodSize = timePeriodSize

    for (let i = 0; i < this.myState.projectBars.length; i++) {
      this.myState.projectBars[i].shape.attr(
        "style",
        "transform:translateX(" +
        this.calulateBarPosition(projectArray[i].startPeriod) +
        "px)"
      )
      this.myState.projectBars[i].backShape.attr("stroke", "white")
      this.myState.projectBars[i].highlightShape.attr("stroke", "white")
      this.myState.projectBars[i].highlightShape.attr(
        "width",
        timePeriodSize * projectArray[i].duration
      )
      this.myState.projectBars[i].highlightShape.attr("stroke-dasharray", "0")

      for (
        let j = 0;
        j < this.myState.projectBars[i].backgroundGroup.children().length;
        j++
      ) {
        this.myState.projectBars[i].backgroundGroup
          .children()
        [j].attr("width", timePeriodSize * projectArray[i].duration)
      }

      // assuming projectbars.lenght == projectArray.lenght
      if (projectArray[i].alive) {
        this.myState.projectBars[i].deadShape.attr("opacity", 0.0)

        this.myState.projectBars[i].backgroundGroup.attr("opacity", 1.0)
      } else {
        this.myState.projectBars[i].backgroundGroup.attr("opacity", 0.4)
        this.myState.projectBars[i].deadShape.attr("opacity", 1.0)
      }

      for (let d = 0; d < difference.length; d++) {
        if (this.myState.projectBars[i].id === difference[d].id) {
          this.myState.projectBars[i].highlightShape.attr(
            "stroke-dasharray",
            "5," + dashGap
          )
        }
      }
    }
  }

  public componentDidUpdate() {
    const { displayProject, projectArray, currentPeriod } = this.props
    // if (this.myState.thisSnap == null || this.myState.lastCurrentPeriod !== currentPeriod ) {
    if (this.myState.thisSnap == null ) {
      this.setupSVG()
    }

    if (projectArray !== this.myState.projectArray) {
      this.myState.projectArray = projectArray
      this.updateDetails()
      // if (this.myState.animationHandler == null) {
      //   this.myState.animationHandler = setInterval(
      //     () => this.animateSVG(),
      //     1000 / this.myState.frameRate
      //   )
      // }
    }

    if (this.myState.displayProject !== displayProject) {
      const previousProjectSelected = this.myState.displayProject
      this.myState.displayProject = displayProject
      this.myState.projectBars.forEach((projectBar: any) => {
        if (projectBar.code === this.myState.displayProject) {
          projectBar.backShape.attr("stroke", "red")
          projectBar.highlightShape.attr("stroke", "red")
          this.myState.thisSnap.snap.append(projectBar.shape)
        }
        if (projectBar.code === previousProjectSelected) {
          projectBar.backShape.attr("stroke", "white")
          projectBar.highlightShape.attr("stroke", "white")
        }
      })
    }

    if(this.myState.currentPeriod !== currentPeriod){
      const previousCurrentPeriod = this.myState.currentPeriod
      this.myState.currentPeriod = currentPeriod
      this.myState.periodBars.forEach((periodBar:any) => {
        if(periodBar.code === currentPeriod){
        periodBar.rect.attr("stroke-width", "6px")
        periodBar.text.attr("fill", "#ff0000")
        periodBar.rect.attr( "fill", "#ff0000")
        periodBar.rect.attr("opacity", "0.1")
        }
        if(periodBar.code === previousCurrentPeriod){
          periodBar.rect.attr("stroke-width", "2px")
          periodBar.text.attr("fill", "#333")
          periodBar.rect.attr( "fill", "rgb(" + (periodBar.code % 2) * 70 + "," + (periodBar.code % 2) * 70 + "," + (periodBar.code % 2) * 70 + ")")
          periodBar.rect.attr("opacity", "0.05")
        }
      });
    }
  }

  public componentWillUnmount() {
    this.myState.thisSnap = null
    const el = document.getElementById(this.state.componentID)
    if (el !== null) {
      el.innerHTML = ""
    }
    if (this.myState.animationHandler != null) {
      clearInterval(this.myState.animationHandler)
    }
  }

  private searchSnap = () => {
    // search snap is used to detect mouse events in the snap
    if (typeof this.myState.thisSnap.customProperties !== "undefined") {
      // snap will send changed values to the snaps custom properties
      if (
        typeof this.myState.thisSnap.customProperties.selectedProject !==
        "undefined"
      ) {
        this.props.setSelectedProject(
          this.myState.thisSnap.customProperties.selectedProject
        )
      } else {
        this.props.setSelectedProject(undefined)
      }
    }
  }

  private clearSelection = () => {
    // if mouse is completely outside the snap object clear any relevant things here
    if (typeof this.myState.thisSnap.customProperties !== "undefined") {
      if (
        typeof this.myState.thisSnap.customProperties.selectedProject !==
        "undefined"
      ) {
        this.props.setSelectedProject(
          this.myState.thisSnap.customProperties.selectedProject
        )
      } else {
        this.props.setSelectedProject(undefined)
      }
    }
  }

  public render(): JSX.Element {
    const returnItems = (
      <div
        style={{
          pointerEvents: "none",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: "0px",
          left: "0px",
          zIndex: 200,
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div>
          <div>
            <svg
              id={this.state.componentID}
              style={{
                width: this.myState.maxSize + this.myState.padding * 2,
                height: this.myState.maxSize + this.myState.padding * 2
              }}
              onClick={this.props.openProjectDialog}
              onMouseMove={this.searchSnap}
              onMouseOut={this.clearSelection}
            />
            <div
              style={{ color: "#333", textAlign: "center", fontSize: "120%" }}
            >
              Time Period
            </div>
          </div>
        </div>
      </div>
    )

    return returnItems
  }
}

export default GanttChart
