import * as React from 'react'
import { observer } from 'mobx-react'
import { Type } from '../../mobx/BaselineStore'

export interface Props {
  types: Type[]
}

@observer
class ProjectType extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
  }

  public render(): JSX.Element {
    return (
      <div style={{ padding: '10px', backgroundColor: 'none' }}>
        <div style={{ paddingBottom: '2px', fontSize: '110%' }}>
          PROJECT TYPE
        </div>
        <div id="typeKeyHolder" style={styles.typeKeyHolder}>
          {this.props.types.map((x: Type) => {
            return (
              <div
                id={`typeKey${x.id}`}
                key={x.id}
                style={styles.typeItemContainer}
              >
                <div
                  style={{
                    ...styles.typeItem,
                    backgroundColor: `#${x.color}`
                  }}
                />
                <div>{x.name.toUpperCase()}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export const styles: { [key: string]: React.CSSProperties } = {
  typeKeyHolder: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  typeItemContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '80%',
    marginBottom: '3px',
    marginRight: '20px'
  },
  typeItem: {
    width: '30px',
    height: '30px',
    borderRadius: '50px',
    border: 'solid 1px white',
    marginRight: '4px',
    flexShrink: 0
  }
}

export default ProjectType
