const styles: { [key: string]: React.CSSProperties } = {
  rightContent: {
    flex: "1",
    display: "flex"
  },
  headerContainer: {
    width: "100%",
    position: "relative",
    flex: "0 0 auto",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between"
  },

  capLevel1: {
    marginTop: "7px",
    paddingTop: "5px",
    border: "black solid thin",
    borderRadius: 5,
    backgroundColor: "white",
    color: "black",
    display: "inline-block",
    width: "48%",
    flexGrow: 1,
    textAlign: "center",
    alignItems: "center"
  },
  capLevel2: {
    margin: 7,
    border: "black solid thin",
    borderRadius: 5,
    backgroundColor: "lightyellow",
    display: "inline-block",
    float: "left",
    fontSize: 12,
    padding: 10,
    color: "black",
    flexGrow: 1,
    position: "relative",
    alignItems: "center"
  },
  capLevel1Inner: {
    display: "flex",
    justifyContent: "space-around",
    margin: 5,
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "space-around",
    flexGrow: 1,
    alignItems: "center",
    textAlign: "center"
  },
  tabActive: {
    backgroundImage: "linear-Gradient(rgb(100, 181, 246,0.6) , #ececec)",
    color: "#000",
    marginRight: 5,
    marginTop: 4,
    fontSize: "14px",
    border: "none",
    padding: "8px 20px",
    borderRadius: "15px 15px 0px 0px"
  },
  tabInactive: {
    backgroundColor: "#fffdfd",
    marginRight: 5,
    marginTop: 4,
    fontSize: "14px",
    border: "none",
    padding: "8px 20px",
    borderRadius: "15px 15px 0px 0px"
  },
  capabilityNameOver: {
    color: "black",
    textAlign: "center",
    position: "absolute",
    width: "100%",
    top: "50%",
    zIndex: 300,
    transform: "translate(-50%, -50%)",
    left: "50%"
  },
  KeyHolder: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap"
  },
  KeyItemContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "80%",
    marginBottom: "3px",
    marginRight: "20px",
    textTransform: "capitalize"
  },
  KeyItem: {
    width: "30px",
    height: "30px",
    borderRadius: "50px",
    border: "solid 1px #ccc",
    marginRight: "4px",
    flexShrink: 0,
    textAlign: "center",
    lineHeight: "200%"
  },
  SelectField: {
    marginBottom: "30px"
  }
}
export default styles
