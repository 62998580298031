import * as React from "react"
import { observer, inject } from "mobx-react"
import UiStore, { CapabilityMode, MaturityMode } from "../../mobx/UiStore"
import ScenariosStore from "../../mobx/ScenariosStore"
import ConfigStore from "../../mobx/ConfigStore"

import styles from "./styles"

import SelectField from "material-ui/SelectField"
import MenuItem from "material-ui/MenuItem"
import Paper from "material-ui/Paper"
import CheckBox from "material-ui/Checkbox"
import Toggle from "material-ui/Toggle"
import Subheader from "material-ui/Subheader"
import { toJS } from "mobx"
import Contributions from "./RightSideBarSubItems/Contributions"

export interface Props {}

export interface InjectedProps extends Props {
  uiStore: UiStore
  scenariosStore: ScenariosStore
  configStore: ConfigStore
}

export interface State {}

const modes: CapabilityMode[] = ["heatmap", "maturity", "importanceLevel"]

@inject(({ store }) => {
  return {
    configStore: store.configStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore
  }
})
@observer
class CapRightSideBar extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  private handleCapabilityMapExistingValue = (event: any, checked: boolean) => {
    const { uiStore } = this.injected
    uiStore.capabilityMapResultant = checked
    if (checked === false) {
      uiStore.capabilityMapValues = true
    }
  }

  private handleShowValue = (event: any, checked: boolean) => {
    const { uiStore } = this.injected
    uiStore.capabilityMapValues = checked
  }

  private handleMaturityTarget = (event: any, checked: boolean) => {
    const { uiStore } = this.injected
    uiStore.capabilityMapValues = false
    uiStore.capabilityMapResultant = false
    uiStore.CapabilityMapMaturiyMode = "maturityTarget"
  }
  private handleMaturityPortfolio = (event: any, checked: boolean) => {
    this.injected.uiStore.CapabilityMapMaturiyMode = "maturityPortfolio"
    this.injected.uiStore.capabilityMapResultant = true
  }
  private onChangeMode = (event: any, index: any, value: any) => {
    this.injected.uiStore.CapabilityMapMode = value
  }

  public render(): JSX.Element {
    const { configStore, uiStore } = this.injected

    const capMode = uiStore.CapabilityMapMode
    const MaturityMode = uiStore.CapabilityMapMaturiyMode
    const mode = capMode === "maturity" ? MaturityMode : capMode

    const modeSelection = (
      <SelectField
        floatingLabelText="View mode"
        value={capMode}
        onChange={this.onChangeMode}
        autoWidth={true}
        style={styles.SelectField}
      >
        {modes.map((m, index) => (
          <MenuItem key={`viewmode - ${index}`} value={m} primaryText={m} />
        ))}
      </SelectField>
    )
    let SideBar = <div />
    let maturityStyle = "Resultant maturity"
    if (!uiStore.capabilityMapResultant) {
      maturityStyle = "Portfolio only Maturity"
    }

    switch (mode) {
      case "heatmap":
        SideBar = (
          <div>
            <Subheader> Settings </Subheader>
            <Toggle
              label="Show Values"
              toggled={uiStore.capabilityMapValues}
              onToggle={this.handleShowValue}
            />
          </div>
        )
        break
      case "maturityPortfolio":
      case "maturityTarget":
        SideBar = (
          <div>
            <Subheader> Settings </Subheader>
            <Toggle
              label="Show Values"
              toggled={uiStore.capabilityMapValues}
              onToggle={this.handleShowValue}
              disabled={
                MaturityMode !== "maturityPortfolio" ||
                !uiStore.capabilityMapResultant
              }
            />
            <Toggle
              label={maturityStyle}
              toggled={uiStore.capabilityMapResultant}
              onToggle={this.handleCapabilityMapExistingValue}
              disabled={MaturityMode !== "maturityPortfolio"}
            />
            <Subheader> Maturity Mode </Subheader>
            <CheckBox
              label="Maturity Target"
              checked={MaturityMode === "maturityTarget"}
              onCheck={this.handleMaturityTarget}
            />
            <CheckBox
              label="Maturity Portfolio"
              checked={MaturityMode === "maturityPortfolio"}
              onCheck={this.handleMaturityPortfolio}
            />
          </div>
        )
        break

      case "importanceLevel":
        SideBar = (
          <div>
            <Subheader> Settings </Subheader>
            <Toggle
              label="Show Values"
              toggled={uiStore.capabilityMapValues}
              onToggle={this.handleShowValue}
            />
          </div>
        )
        break

      default:
        break
    }
    return (
      <Paper zDepth={2} style={{ width: "260px", marginLeft: "12px" }}>
        <div
          style={{
            display: "flex",
            height: "95%",
            flexDirection: "column",
            margin: "15px"
          }}
        >
          {modeSelection}
          {SideBar}
          <Contributions />
        </div>
      </Paper>
    )
  }
}

export default CapRightSideBar
