import Project from "../mobx/Project"

export const compareToProjects = (solutionArray: any, projects: Project[]) => {
  const diffrence: Array<{ code: string; alive: boolean }> = []
  projects.forEach((project) => {
    let inSol: boolean = false
    solutionArray.forEach((solution: { project: string }) => {
      if (project.code === solution.project) {
        inSol = true
      }
    })
    if (!inSol && project.alive) {
      diffrence.push({ code: project.code, alive: false })
    }
    if (inSol && !project.alive) {
      diffrence.push({ code: project.code, alive: true })
    }
  })
  return diffrence
}

export const CompareDiffrence = (base: any, comparison: any) => {
  const diffrence: Array<{ code: string; alive: boolean }> = []
  calulcateDiffrence(base, comparison, diffrence, false)
  calulcateDiffrence(comparison, base, diffrence, true)
  return diffrence
}

function calulcateDiffrence(
  base: any,
  comparison: any,
  diffrence: Array<{ code: string; alive: boolean }>,
  flip: boolean = false
) {
  base.forEach((diff: { code: any; alive: any }) => {
    let include: boolean = true
    comparison.forEach((comp: { code: any }) => {
      if (comp.code === diff.code) {
        include = false
      }
    })
    if (include === true) {
      if (flip) {
        diffrence.push({ code: diff.code, alive: !diff.alive })
      } else {
        diffrence.push(diff)
      }
    }
  })
}
