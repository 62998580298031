import * as React from "react"

import { snapTools } from "../SnapTools/snapTools"
import { projectUsage } from "../../utils/portfolio/projectUsage"
import { NumberBeautify } from "../../utils/NumberBeautify"
import Project from "../../mobx/Project"

export interface Props {
  projectArray: Project[]
  resource: string
  resourceIndex: string
  selectedProject: string | undefined
  compareTo: string
  showHoverbox: (content: any, title?: any) => void
  hideHoverbox(): void
  changeHoverboxPosition: (x: number, y: number) => void
}

class ResourceBar extends React.Component<Props, any> {
  public myState: any
  constructor(props: Props) {
    super(props)
    this.state = {
      componentID: snapTools.createUniqueID(),
    }
    this.myState = {
      selectedDot: this.props.selectedProject,
      selectedDotClicked: "",
      componentID: "snapContainer",
      resource: this.props.resource,
      thisSnap: null,
      lastJob: null,
      optimisedObject: null,
      maxSize: 400,
      padding: 20,

      outerPath: {},
      baseline: 0,
      allProjects: 0,
      allProjectsBar: null,
      allProjectsText: null,
      baselineMarker: null,
      currentBar: null,
      highlightBar: null,
      animationHandler: null,
      highlightsText: null,
      highlightHandler: null,
      frameRate: 60,
      animationTime: 0.3,
      barSizes: {
        allProjects: {
          currentSize: 0,
          oldSize: 0,
        },
        currentProjects: {
          currentSize: 0,
          oldSize: 0,
        },
        baselineMarker: {
          currentSize: 0,
          oldSize: 0,
        },
        highlight: {
          currentSize: 0,
          oldSize: 0,
        },
      },
      animationTicks: {
        changeScenario: 0,
        highlightProject: 0,
      },
      currentSize: 0,
      oldSize: 0,
      allProjectsAmount: null,
      compareAmount: null,
      greenValue: 0,
      redValue: 0,
      blackValue: 0,
    }
  }

  public componentWillMount() {
    this.myState.componentID = snapTools.createUniqueID()
  }

  private removeTextById = (textId: string) => {
    const snapChildren = this.myState.thisSnap.snap.children()
    snapChildren.forEach((snapElement: any) => {
      if (snapElement.attr("textId") === textId) {
        snapElement.remove()
      }
    })
  }

  private createAllProjectsText = () => {
    const backWidth = this.myState.maxSize // all projects
    const currentWidth =
      (this.myState.maxSize / this.myState.allProjectsAmount) *
      this.myState.currentAmount // current projects

    if (this.myState.blackValue > this.myState.greenValue) {
      const allProjectContent = NumberBeautify.numberFormat(
        parseFloat(this.myState.blackValue)
      )
      const allProjectsText = this.myState.thisSnap.snap.text(
        currentWidth + (backWidth - currentWidth) / 2,
        16,
        allProjectContent
      )
      allProjectsText.attr({ "font-size": 14, fill: "#FFF" })
      allProjectsText.attr({ textId: "allProjects" })
    }
  }

  private createCurrentBarText = () => {
    const currentWidth =
      (this.myState.maxSize / this.myState.allProjectsAmount) *
      this.myState.currentAmount // current projects

    const currentBarContent = NumberBeautify.numberFormat(
      parseFloat(this.myState.greenValue)
    )
    const currentBarText = this.myState.thisSnap.snap.text(
      currentWidth / 2,
      16,
      currentBarContent
    )
    currentBarText.attr({ "font-size": 14, fill: "#333" })
    currentBarText.attr({ textId: "currentBar" })
  }
  public componentDidMount() {
    if (this.myState.thisSnap == null) {
      this.myState.thisSnap = snapTools.createSnap(this.state.componentID)
    }
    this.calculateNewBarSizes()

    const backWidth = this.myState.maxSize // all projects

    const currentWidth =
      (this.myState.maxSize / this.myState.allProjectsAmount) *
      this.myState.currentAmount // current projects
    const baselinePosition =
      (this.myState.maxSize / this.myState.allProjectsAmount) *
      this.myState.baselineAmount // the baseline position

    this.myState.oldSize = this.myState.currentSize

    if (this.myState.allProjectsBar === null) {
      this.myState.allProjectsBar = snapTools.createRect(
        this.myState.thisSnap.snap,
        this.myState.padding,
        backWidth,
        20,
        "#333",
        0
      )
      this.myState.allProjectsBar.attr("strokeWidth", "1px")

      this.createAllProjectsText()
      this.myState.currentBar = snapTools.createRect(
        this.myState.thisSnap.snap,
        this.myState.padding,
        currentWidth,
        20,
        "rgb(142, 179, 24)",
        0
      )
      this.createCurrentBarText()

      this.myState.baselineMarker = snapTools.createRect(
        this.myState.thisSnap.snap,
        this.myState.padding,
        2,
        20,
        "rgb(255, 0, 0)",
        0
      )

      this.myState.baselineMarker.attr(
        "x",
        baselinePosition + this.myState.padding - 1
      )

      this.myState.highlightBar = snapTools.createRect(
        this.myState.thisSnap.snap,
        this.myState.padding,
        0,
        20,
        "rgb(255,0,0)",
        0
      )

      this.myState.currentSize = currentWidth
      this.myState.oldSize = currentWidth
    }
  }

  public componentDidUpdate() {
    this.calculateNewBarSizes()
    if (this.myState.thisSnap == null) {
      this.myState.thisSnap = snapTools.createSnap(this.state.componentID)
    }

    this.myState.oldSize = this.myState.currentSize

    if (this.myState.animationHandler == null) {
      this.myState.animationHandler = setInterval(
        () => this.animateBars(),
        1000 / this.myState.frameRate
      )
    }

    this.removeTextById("allProjects")
    this.createAllProjectsText()
    this.removeTextById("currentBar")
    this.createCurrentBarText()

    this.myState.animationTicks.changeScenario = 0

    if (
      this.myState.selectedDotClicked !== this.props.selectedProject ||
      this.myState.selectedDot !== this.props.selectedProject
    ) {
      this.myState.selectedDotClicked = this.props.selectedProject
      this.myState.selectedDot = this.props.selectedProject

      this.myState.barSizes.highlight.oldSize = this.myState.barSizes.highlight.currentSize

      if (this.myState.highlightHandler == null) {
        this.myState.highlightHandler = setInterval(
          () => this.animateHighlighted(),
          1000 / this.myState.frameRate
        )
      }
      this.myState.animationTicks.highlightProject = 0
    }
  }

  private calculateNewBarSizes() {
    const { projectArray, selectedProject, resourceIndex } = this.props

    const portfolioUsage = projectUsage(projectArray, resourceIndex)
    this.myState.currentAmount = portfolioUsage.totalValue
    this.myState.baselineAmount = portfolioUsage.totalValue
    this.myState.allProjectsAmount = projectUsage(
      projectArray,
      resourceIndex,
      true
    ).totalValue

    let projectAmount = 0
    if (selectedProject) {
      for (let i = 0; i < portfolioUsage.projectArray.length; i++) {
        if (portfolioUsage.projectArray[i].id === selectedProject) {
          projectAmount = portfolioUsage.projectArray[i].amount
        }
      }
    }

    this.myState.redValue = projectAmount
    this.myState.greenValue = this.myState.currentAmount
    this.myState.blackValue = this.myState.allProjectsAmount
  }

  private animateBars() {
    const { allProjectsAmount, currentAmount, baselineAmount } = this.myState

    const timeToComplete = this.myState.animationTime // seconds
    const frameRate = this.myState.frameRate
    const timeByFrames = timeToComplete * frameRate

    const backWidth = this.myState.maxSize // all projects
    const currentWidth =
      (this.myState.maxSize / allProjectsAmount) * currentAmount // current projects
    const baselinePosition =
      (this.myState.maxSize / allProjectsAmount) * baselineAmount // the baseline position

    let amount = 0
    let goalAmount = currentWidth
    let moveAmount = Math.abs(this.myState.oldSize - goalAmount) / timeByFrames

    if (Math.abs(this.myState.currentSize - goalAmount) >= moveAmount) {
      if (this.myState.currentSize - goalAmount >= 0) {
        amount = this.myState.currentSize - moveAmount
      } else {
        amount = this.myState.currentSize + moveAmount
      }
    } else {
      amount = goalAmount
    }

    this.myState.currentSize = amount

    this.myState.allProjectsBar.attr("width", backWidth)
    this.myState.currentBar.attr("width", amount)
    this.myState.baselineMarker.attr(
      "x",
      baselinePosition + this.myState.padding
    )

    // handle tracking of the animationTime
    this.myState.animationTicks.changeScenario += 1
    if (
      this.myState.animationTicks.changeScenario >
      this.myState.frameRate * this.myState.animationTime
    ) {
      if (this.myState.animationHandler != null) {
        this.myState.animationTicks.changeScenario = 0
        clearInterval(this.myState.animationHandler)
        this.myState.animationHandler = null
      }
    }
  }

  private animateHighlighted() {
    const timeToComplete = this.myState.animationTime // seconds
    const frameRate = this.myState.frameRate
    const timeByFrames = timeToComplete * frameRate

    let highlightBarWidth =
      (this.myState.maxSize / this.myState.allProjectsAmount) *
      this.myState.redValue //current projects

    let amount = 0
    let goalAmount = highlightBarWidth
    let moveAmount =
      Math.abs(this.myState.barSizes.highlight.oldSize - goalAmount) /
      timeByFrames

    if (
      Math.abs(this.myState.barSizes.highlight.currentSize - goalAmount) >=
      moveAmount
    ) {
      if (this.myState.barSizes.highlight.currentSize - goalAmount >= 0) {
        amount = this.myState.barSizes.highlight.currentSize - moveAmount
      } else {
        amount = this.myState.barSizes.highlight.currentSize + moveAmount
      }
    } else {
      amount = goalAmount
    }

    this.myState.barSizes.highlight.currentSize = amount

    this.myState.highlightBar.attr("width", amount)
    if (this.myState.highlightsText !== null) {
      this.myState.highlightsText.remove()
      this.myState.highlightsText = null
    }
    if (this.myState.redValue > 0) {
      this.myState.highlightContent = NumberBeautify.numberFormat(
        parseFloat(this.myState.redValue)
      )
      this.myState.highlightsText = this.myState.thisSnap.snap.text(
        22,
        16,
        this.myState.highlightContent
      )
      this.myState.highlightsText.attr({ "font-size": 14, fill: "#333" })
    }

    this.myState.animationTicks.highlightProject += 1
    if (
      this.myState.animationTicks.highlightProject >
      this.myState.frameRate * this.myState.animationTime
    ) {
      if (this.myState.highlightHandler != null) {
        this.myState.animationTicks.highlightProject = 0
        clearInterval(this.myState.highlightHandler)
        this.myState.highlightHandler = null
      }
    }
  }

  public componentWillUnmount() {
    this.myState.thisSnap = null
    const el = document.getElementById(this.state.componentID)
    if (el != null) {
      el.innerHTML = ""
    }
    if (this.myState.animationHandler != null) {
      clearInterval(this.myState.animationHandler)
    }
    if (this.myState.highlightHandler != null) {
      clearInterval(this.myState.highlightHandler)
    }
  }

  public render(): JSX.Element {
    const returnItems = (
      <div>
        <svg
          id={this.state.componentID}
          style={{
            width: this.myState.maxSize + this.myState.padding * 2,
            height: 20 + 4,
          }}
        />
      </div>
    )

    return returnItems
  }
}

export default ResourceBar
