// @ts-ignore
import Snap from 'snapsvg-cjs';
// const fill = 'rgb(153, 155, 144)';
const fill = '#778089'

export const createBackCircle = (
  id: string,
  diameter: number,
  padding: number
) => {
  const s = Snap(id)
  const circle = s.circle(
    diameter / 2 + padding,
    diameter / 2 + padding,
    diameter / 2
  )

  circle.attr({
    fill,
    stroke: '#ddd',
    strokeWidth: 3
  })
}

export const markerSize = 12

export const createMarker = (id: string) => {
  const s = Snap(id)
  const arrow = s
    .polygon([
      0,
      markerSize * 1.25,
      markerSize,
      markerSize * 1.25,
      markerSize / 2,
      0,
      0,
      markerSize * 1.25
    ])
    .attr({ fill: '#000' })
    .transform('r90')
  const marker = arrow.marker(
    0,
    0,
    markerSize * 1.25,
    markerSize * 1.25,
    0,
    markerSize * 1.25 / 2
  )
  return marker
}
export const connectCircles = (
  id: string,
  boxOne: any,
  boxTwo: any,
  marker: any
) => {
  const s = Snap(id)
  const line = s.line(boxOne.cx, boxOne.cy, boxTwo.cx, boxTwo.cy)

  line.attr({
    stroke: '#000',
    strokeWidth: 1,
    'stroke-dasharray': 4,
    markerEnd: marker
  })
  return line
}
