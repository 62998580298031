import * as React from "react"

import { observer } from "mobx-react"

import ResourceDial from "./ResourceDial"
import Project from "../../mobx/Project"

interface Props {
  dialChoice: any[]
  onPressConstraint: (x: any) => void
  selectedProject: string | undefined
  showHoverbox: (content: any, title?: any) => void
  hideHoverbox(): void
  changeHoverboxPosition: (x: number, y: number) => void
  projectArray: Project[]
  periodArray: any[]
  startPeriod: number
  currentPeriod: number
}

const ResourceDialList = observer((props: Props) => {
  const {
    dialChoice,
    onPressConstraint,
    showHoverbox,
    hideHoverbox,
    changeHoverboxPosition,
    selectedProject,
    projectArray,
    periodArray,
    currentPeriod,
    startPeriod
  } = props

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around"
      }}
    >
      {dialChoice.map((x: any) => {
        const onPressConstraintFn = () => {
          onPressConstraint(x)
        }
        return (
          <div key={`dialGroup${x.id}-resource`}>
            <div key={`ResourceDial${x.id}`}>
              <ResourceDial
                size={120}
                resource={`column${x.id}`}
                maxLimit={parseInt(x.value)}
                resourceIndex={x.name}
                selectedProject={selectedProject}
                projectArray={projectArray}
                showHoverbox={showHoverbox}
                currentPeriod={currentPeriod}
                periods={periodArray}
                changeHoverboxPosition={changeHoverboxPosition}
                hideHoverbox={hideHoverbox}
                endDisplayIndex={periodArray.length}
                beginDisplayIndex={startPeriod}
              />
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  maxWidth: "140px",
                  cursor: "pointer"
                }}
                onClick={onPressConstraintFn}
              >
                {x.name}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
})

export { ResourceDialList }
