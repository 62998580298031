import { clone, merge, find, propEq } from "ramda"
import {
  linkStrategiesToInitiatives,
  setNamesToItems,
  createCapabilityMap,
  mergeElementsToProjects,
  createProjectCapabilitiesMap
} from "../processCSV"
import { Capability } from "./interfaces"

const mapCapabilitiesToDatabase = (capabilityMap: any) => {
  for (let i = 0; i < capabilityMap.length; i++) {
    capabilityMap[i] = mapCapabilityToDatabase(capabilityMap[i])
    for (let i1 = 0; i1 < capabilityMap[i].children.length; i1++) {
      capabilityMap[i].children[i1] = mapCapabilityToDatabase(
        capabilityMap[i].children[i1]
      )
      for (
        let i2 = 0;
        i2 < capabilityMap[i].children[i1].children.length;
        i2++
      ) {
        capabilityMap[i].children[i1].children[i2] = mapCapabilityToDatabase(
          capabilityMap[i].children[i1].children[i2]
        )
      }
    }
  }
  return capabilityMap
}

const mapCapabilityToDatabase = (capability: any) => {
  const newRow: Capability = {
    children: capability.children || [],
    id: capability.ID,
    name: capability.name,
    level: 0
  }
  if (capability.SubType === "Level 0") {
    newRow.level = 0
  }
  if (capability.SubType === "Level 1") {
    newRow.level = 1
  }
  if (capability.SubType === "Level 2") {
    newRow.level = 2
  }
  if (capability["Current Maturity"]) {
    newRow.matureCurrent = capability["Current Maturity"]
  }
  if (capability["Target Maturity"]) {
    newRow.matureTarget = capability["Target Maturity"]
  }
  return newRow
}

const parseRelations = ({ data, state }: { data: any[]; state: any }) => {
  if (!state.capabilities || !state.elementsLoaded) {
    return
  }

  const linkedIntitiatives = linkStrategiesToInitiatives(
    data,
    state.strategies,
    state.initiatives
  )
  const initiatives = setNamesToItems(state.elements, linkedIntitiatives)
  let capabilityMap = createCapabilityMap(data, state.capabilities)
  capabilityMap = mapCapabilitiesToDatabase(capabilityMap)

  const updatedProjects = mergeElementsToProjects(
    clone(state.projects),
    linkedIntitiatives,
    clone(state.prerequisites),
    data
  )

  const projectCapabilitiesMap = createProjectCapabilitiesMap(
    data,
    state.projects,
    state.contributions,
    state.capabilities
  )
  if (!initiatives || !projectCapabilitiesMap || !capabilityMap) {
    return
  }
  const newProjects = updatedProjects.map((p: { ID: any }) => {
    const project = find(propEq("dotId", p.ID))(projectCapabilitiesMap)
    // @ts-ignore
    p = merge(p, project)
    return p
  })

  return {
    projects: newProjects,
    initiatives,
    capabilityMap,
    projectCapabilitiesMap,
    relationshipLoaded: true
  }
}

export { parseRelations }
