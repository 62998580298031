import * as React from "react"
import { observer, inject } from "mobx-react"
import { withRouter, RouteComponentProps } from "react-router-dom"

import UiStore from "../mobx/UiStore"
import PermissionStore from "../mobx/PermissionStore"
import ScenariosStore from "../mobx/ScenariosStore"
import BaselineStore from "../mobx/BaselineStore"
import ConfigStore from "../mobx/ConfigStore"

import Dialog from "material-ui/Dialog"
import IconButton from "material-ui/IconButton"
import ClearIcon from "material-ui/svg-icons/content/clear"
import TextField from "material-ui/TextField"
import FlatButton from "material-ui/FlatButton"
import { saveScenario } from "../utils/saveData"

export interface State { }

export interface Props extends RouteComponentProps<{}> { }

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  configStore: ConfigStore
}

@inject(({ store }) => {
  return {
    configStore: store.configStore,
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore
  }
})
@observer
class SaveScenarioDialog extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
  }

  private onCloseClick = () => {
    // TODO replace with set method
    this.injected.uiStore.showSaveScenarioPopup = false
  }

  private handleChange = (event: any) => {
    const { name, value } = event.target
    const scenarioData = this.injected.scenariosStore.working
    if (name === "name") {
      scenarioData.name = value
    }
    if (name === "description") {
      scenarioData.description = value
    }
  }

  private saveAsScenario = async () => {
    this.injected.scenariosStore.optimised.name = this.injected.scenariosStore.working.name
    this.injected.scenariosStore.optimised.description = this.injected.scenariosStore.working.description
    try {
      await saveScenario(
        this.injected.scenariosStore,
        this.injected.permissionStore.getToken()
      )
      this.injected.scenariosStore.updateBaseline()
    } catch (error) {
      // later do somthing if it fails
      console.log(error)
    }
    this.injected.uiStore.showSaveScenarioPopup = false
  }

  private saveAsNewScenario = async () => {
    this.injected.scenariosStore.id = -1
    try {
      await saveScenario(
        this.injected.scenariosStore,
        this.injected.permissionStore.getToken()
      )
      this.props.history.push("/dashboard")
    } catch (error) {
      // later do somthing if it fails
    }
    this.injected.uiStore.showSaveScenarioPopup = false
  }

  public render(): JSX.Element {
    let errorText = ""
    const { scenariosStore } = this.injected
    if (scenariosStore.editedFromOptimised === true) {
      errorText =
        "Changed from Last Optimised, only the last optimised version will be saved"
    }

    const title = (
      <div style={styles.titleHeader}>
        <h3 style={styles.titleHeaderContent}>
          Save Scenario As
          <IconButton
            onClick={this.onCloseClick}
            style={styles.closeButton}
            iconStyle={styles.closeButtonIcon}
          >
            <ClearIcon />
          </IconButton>
        </h3>
      </div>
    )
    return (
      <Dialog title={title} open={this.injected.uiStore.showSaveScenarioPopup}>
        <div style={styles.errorMessage}>{errorText}</div>
        <TextField
          floatingLabelText={"Scenario Name"}
          name="name"
          value={scenariosStore.working.name}
          onChange={this.handleChange}
        />
        <TextField
          floatingLabelText={"Scenario Description"}
          name="description"
          value={scenariosStore.working.description}
          onChange={this.handleChange}
        />
        <FlatButton
          onClick={this.saveAsScenario}
          label="Save"
          primary={true}
          disabled={
            scenariosStore.editedFromOptimised ||
            scenariosStore.baseline.isBaseline
          }
        />
        <FlatButton
          onClick={this.saveAsNewScenario}
          label="Save As New"
          primary={true}
          disabled={scenariosStore.editedFromOptimised}
        />
        <FlatButton onClick={this.onCloseClick} label="Cancel" />
      </Dialog>
    )
  }
}

export const styles: { [key: string]: React.CSSProperties } = {
  closeButton: {
    padding: 0
  },
  closeButtonIcon: {
    width: 36,
    height: 36,
    color: "white"
  },
  errorMessage: {
    color: "red",
    fontWeight: "bold"
  },
  titleHeader: {
    backgroundColor: "#5859BC",
    color: "white",
    padding: 0,
    flex: 1,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  titleHeaderContent: {
    backgroundColor: "#5859BC",
    fontSize: "1em",
    color: "white",
    padding: "0.75rem",
    margin: 0,
    flex: 1,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  }
}

export default withRouter(SaveScenarioDialog)
