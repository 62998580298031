import * as React from "react"
import * as R from "ramda"
import { observer, inject } from "mobx-react"
import Paper from "material-ui/Paper"

import UiStore from "../mobx/UiStore"
import PermissionStore from "../mobx/PermissionStore"
import ScenariosStore from "../mobx/ScenariosStore"

import ScenarioControlPanel from "./ScenarioControlPanel"
import { ProjectListItem } from "./ProjectListItem"
import Description from "./Description"
import BaselineStore from "../mobx/BaselineStore"
import { getSolutions, ScenarioToOptimise } from "../utils/ProjectSolution"
import ConfigStore from "../mobx/ConfigStore"

export interface Props {
  reloadData: () => {}
  open: boolean
}

export interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  configStore: ConfigStore
}

export interface State {
  showExlcudedProjects: boolean
}

const styles: { [key: string]: React.CSSProperties } = {
  wrapper: {
    flex: "0 1 100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  container: {
    flex: "0 1 70%",
    overflowY: "auto",
    overflowX: "hidden",
    // zIndex: 100,
    padding: "20px",
    marginBottom: "10px",
    paddingTop: "0px"
  },
  activeDots: {
    direction: "ltr"
  }
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore,
    configStore: store.configStore
  }
})
@observer
class LeftSideBar extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      showExlcudedProjects: false
    }
  }

  private onPressProject = (project: any) => {
    const { scenariosStore } = this.injected
    if (scenariosStore.selectedProjectCode !== project.code) {
      scenariosStore.setSelectedProject(project.code)
    } else {
      scenariosStore.setSelectedProject(undefined)
    }
  }
  private onPressProjectEdit = (project: any) => {
    const { uiStore, scenariosStore } = this.injected

    uiStore.showProjectPropertiesPopup = true

    if (scenariosStore.selectedProjectCode !== project.code) {
      scenariosStore.setSelectedProject(project.code)
    }
  }

  private changeShowExcluded = () => {
    if (this.state.showExlcudedProjects) {
      this.setState({ showExlcudedProjects: false })
    } else {
      this.setState({ showExlcudedProjects: true })
    }
  }

  private clickHighlight = (x: any) => {
    this.injected.scenariosStore.setHighlightedProject(x.code)
  }

  private clickEndHighlight = (x: any) => {
    this.injected.scenariosStore.setHighlightedProject(undefined)
  }

  private reloadData = () => {
    this.props.reloadData()
    this.forceUpdate()
  }

  public resetScenario = () => {
    this.injected.scenariosStore.reset()
  }

  
  public onOptimisePressed = () => {
    // TODO display some graphic while running
    this.optimiseScenario();
  };

  public optimiseScenario = async () =>{
      const { permissionStore, scenariosStore, baselineStore, configStore } = this.injected;
      try {
        let solutionArray: any[] = [];
  
        const periods = baselineStore.periods.length;
        const authToken = permissionStore.getToken();
        const canOptimise = permissionStore.canOptimise()
      if(!canOptimise){
        return
      }
  
        const solutionArrayItem = await ScenarioToOptimise(
          scenariosStore.working,
          authToken,
          periods,
          configStore.forceRefresh
        );
        solutionArray.push(solutionArrayItem);
        
  
        solutionArray = await getSolutions(solutionArray, authToken);
        solutionArray = solutionArray.map(item => {
          const solution = item.status === 'error' ? 'infeasable' : item.solution;
          return {
            jobId: item.jobId,
            status: item.status,
            solution
          };
        });
        scenariosStore.setOptimised(solutionArray);
      } catch (error) {
        console.log("Error while optimizing")
        console.log(error)
      }

  }

  public render(): JSX.Element {
    const { scenariosStore } = this.injected
    let projects: any = []
    if (scenariosStore.loaded) {
      projects = scenariosStore.working.projects
    } else {
      return <div />
    }

    projects = R.sortBy(R.prop("code"))(projects)

    return (
      <Paper zDepth={2}>
        <div style={styles.wrapper}>
          <ScenarioControlPanel
            showExcluded={this.state.showExlcudedProjects}
            reloadData={this.reloadData}
            resetScenario={this.resetScenario}
            changeShowExcluded={this.changeShowExcluded}
            optimiseScenario={this.onOptimisePressed}
          />
          <div id="activeDotsOut" style={styles.container}>
            <div id="activeDots" style={styles.activeDots}>
              {projects.map((project: any, index: number) => {
                const clickHighlightFn = () => this.clickHighlight(project)
                const clickEndHighlightFn = () =>
                  this.clickEndHighlight(project)
                const onPressProjectFn = () => this.onPressProject(project)
                const onPressEditFn = () => this.onPressProjectEdit(project)
                // TODO compare to OldDotData lost, think this is previous scenario?
                // projects[index].alive !== oldDotData[index].updated
                // ? 'italic'
                // : 'normal'
                const newFontStyle = "normal"

                return (
                  <ProjectListItem
                    key={`${project.dot_id} - ${index}`}
                    project={project}
                    showExcluded={this.state.showExlcudedProjects}
                    newFontStyle={newFontStyle}
                    mouseEnter={clickHighlightFn}
                    mouseLeave={clickEndHighlightFn}
                    onPressProject={onPressProjectFn}
                    onPressEdit={onPressEditFn}
                  />
                )
              })}
            </div>
          </div>
          <Description description={scenariosStore.working.description} />
        </div>
      </Paper>
    )
  }
}

export default LeftSideBar
