import { mergeAttributesToProjects } from "../processCSV"
import * as R from "ramda"

const parseAttributes = ({ data, state }: { data: any[]; state: any }) => {
  // pass data and existing projects though to combinder
  const updatedProjects = mergeAttributesToProjects(
    R.clone(state.projects),
    R.clone(state.constraints),
    data
  )

  return { projects: updatedProjects }
}

export { parseAttributes }
