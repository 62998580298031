import * as React from "react"
import { observer } from "mobx-react"
import ResourceSplitDial from "./ResourceSplitDial"
import { Project } from "../../mobx/Project"

interface Props {
  dialChoice: any[]
  selectedProject: string | undefined
  projectArray: Project[]
  setSelectedProject: (id: string | undefined) => void
  clearSelection(): void
  showHoverbox: (content: any, title?: any) => void
  hideHoverbox(): void
  changeHoverboxPosition: (x: number, y: number) => void
}

const ResourceSplitDialList = observer((props: Props) => {
  const {
    dialChoice,
    showHoverbox,
    hideHoverbox,
    changeHoverboxPosition,
    selectedProject,
    setSelectedProject,
    clearSelection,
    projectArray
  } = props

  return (
    <div style={styles.resourceSplitDialContainer}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around"
        }}
      >
        {dialChoice.map((x: any) => {
          return (
            <div key={`dialGroup${x.id}-resourcesplit`}>
              <div key={`ResourceSplitDial${x.id}`}>
                <ResourceSplitDial
                  resource={"column" + x.id}
                  resourceIndex={x.name}
                  selectedProject={selectedProject}
                  projectArray={projectArray}
                  showHoverbox={showHoverbox}
                  changeHoverboxPosition={changeHoverboxPosition}
                  hideHoverbox={hideHoverbox}
                  setSelectedProject={setSelectedProject}
                  clearSelection={clearSelection}
                />
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    maxWidth: "140px"
                  }}
                >
                  {x.name}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
})

const styles: { [index: string]: React.CSSProperties } = {
  resourceSplitDialContainer: {
    backgroundColor: "none",
    padding: "10px",
    paddingBottom: "30px",
    paddingTop: "20px"
  }
}

export { ResourceSplitDialList }
