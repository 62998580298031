

export interface printSetup {
    title: string
    subHeading: string
    logoURL: string,
    printContents: any
    pageHeader?: any
}

/**
 * 
 * @param information 
 */
export function exportToPrint (information:printSetup){
        
    let printContents:any, popupWin: any;
    let date = new Date()

    let printElements =document.getElementById('print-section')
    printContents = printElements != null ? printElements.innerHTML : "";

    popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
  <html>
    <head>
      <title>Print tab</title>
      <style>
      .center {
        text-align: center;
      }
      img {
        display:block;
        margin-left: auto;
        margin-right: auto;
      }
      div.header{
          position: running(pageHeader);
      }
      div.footer{
        position:running(pageFooter);
      }
      .flex-container {
        display: flex;
        align-items: stretch;
      }
      .flex-container > div {
        margin: 10px;
        padding: 20px;
        font-size: 8px;
        color: grey; 
      }
      .flex-item-left {
        flex: 50%;
        text-align: left;
      }
      
      .flex-item-right {
        flex: 50%;
        text-align: right;
      }

      @page {
        size: A4;
        margin: 11mm 17mm 17mm 17mm;
      }
      @media print {
        body{
            width: 21cm;
            height: 29.7cm;
            margin: 11mm 17mm 17mm 17mm; 
            /* change the margins as you want them to be. */
        } 
        footer{
          position:fixed;
          bottom: 0
        }

      }
      </style>
    </head>
<body onload="window.print();window.close()">
<img src=${information.logoURL}>
<h1 class="center">${information.title} </h1>
<h3>${information.subHeading}</h3>
${information.printContents}

<footer>
      <div class="flex-container">
      <div class="flex-item-left">
        Powered by Acuitas Evidence Guided Optimization
      </div>
        <div class="flex-item-right">
          ${date.toDateString()}
        </div>
       

      </div>

</footer>
</body>
  </html>`
    );
    popupWin.document.close();
}