import * as React from 'react';
export const styles: { [key: string]: React.CSSProperties } = {
  header: {
    backgroundColor: '#fff',
    padding: '0',
    flex: '0 0 50px',
    zIndex: 999,
    borderRadius: 0
  },
  button: {
    color: '#000'
  },
  link: {
    textDecoration: 'none'
  }
};
