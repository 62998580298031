import * as React from "react"
import { observer, inject } from "mobx-react"
import * as R from "ramda"
import { toJS } from "mobx"
import { isUndefined } from "util"

import UiStore from "../../mobx/UiStore"
import ScenariosStore from "../../mobx/ScenariosStore"
import BaselineStore, {
  CapabilityMap as CapabilityMapInterface,
  MaturityLevel
} from "../../mobx/BaselineStore"
import ConfigStore from "../../mobx/ConfigStore"
import CapabilityStore from "../../mobx/CapabilitiesStore"
import CapRightSideBar from "../../components/CapabilityMap/CapRightSideBar"

import styles from "./styles"
import { colorDropDown } from "../../config"
import mapValueToColor from "../../utils/capabilityMap/mapValueToColor"
import Level2 from "../../components/CapabilityMap/Level2"
import mapMultiplierToColor from "../../utils/capabilityMap/mapMultiplierToColor"

interface Props {
  opened: boolean
  heatMap: boolean
}

interface InjectedProps extends Props {
  baselineStore: BaselineStore
  scenariosStore: ScenariosStore
  uiStore: UiStore
  configStore: ConfigStore
  capabilityStore: CapabilityStore
}

export interface State {
  // currentId?: string | undefined
  capabilityMapsExisting: boolean
}

@inject(({ store }) => {
  return {
    configStore: store.configStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore,
    capabilityStore: store.capabilityStore
  }
})
@observer
export class CapabilityMap extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      capabilityMapsExisting: true
    }
  }

  private mapValueToColorHandler = (id: any, style: any) => {
    const { scenariosStore, configStore } = this.injected
    let selectedProjectCapabilities = []
    // if we have a store and a selected project get capabilities
    if (scenariosStore && scenariosStore.highlightedProject) {
      selectedProjectCapabilities =
        scenariosStore.highlightedProject.capabilities
    }

    const listOfProjectsWithCapability = scenariosStore.optimised.projects.filter(
      (x: any) => this.filterProject(id, x)
    )
    const heatMap = configStore.heatMap

    const boxStyle = mapValueToColor(
      id,
      style,
      selectedProjectCapabilities,
      heatMap,
      colorDropDown,
      listOfProjectsWithCapability
    )
    return boxStyle
  }

  private filterProject = (
    id: any,
    x: {
      alive: any
      capabilities: { findIndex: (arg0: (cap: any) => boolean) => number }
    }
  ) => {
    return (
      x.alive &&
      x.capabilities.findIndex((cap: { id: any }) => cap.id === id) !== -1
    )
  }

  private calculateMaturityPortolioStyle = (
    capability: CapabilityMapInterface,
    maturityMap: MaturityLevel[]
  ): any => {
    const { uiStore } = this.injected
    const { optimised } = this.injected.scenariosStore

    let boxStyle = {
      position: "absolute",
      width: "50%",
      top: 0,
      left: 0,
      height: "100%"
    }

    const listOfProjectsWithCapability = optimised.projects.filter((x: any) =>
      this.filterProject(capability.code, x)
    )
    let currentColor = maturityMap[0].color
    let currentValue = 0

    const matureCurrent =
      capability.MaturityCurrent && capability.MaturityCurrent.value
    if (
      uiStore.capabilityMapResultant &&
      matureCurrent !== null &&
      matureCurrent !== undefined
    ) {
      const index = maturityMap.findIndex(
        (i: any) => i.level.toString() === matureCurrent.toString()
      )
      if (index !== -1) {
        currentValue = maturityMap[index].value
      }
    }

    for (let i = 0; i < listOfProjectsWithCapability.length; i++) {
      const caps = listOfProjectsWithCapability[i].capabilities
      const contribution = caps[
        caps.findIndex((cap: { id: string }) => cap.id === capability.code)
      ].contribution.toString()
      currentValue += parseInt(contribution, 10)
    }

    for (let i = 0; i < maturityMap.length; i++) {
      if (currentValue >= maturityMap[i].value) {
        currentColor = maturityMap[i].color
      }
    }

    boxStyle = R.merge(boxStyle, {
      backgroundColor: currentColor,
      maturity: currentValue
    })
    return boxStyle
  }

  private setCurrentId = (id: string) => {
    this.injected.uiStore.capabilityMapId = id
  }

  private renderLegend = () => {
    const {
      configStore,
      baselineStore,
      uiStore,
      capabilityStore
    } = this.injected
    const mode = uiStore.CapabilityMapMode
    let value = ""
    switch (mode) {
      case "heatmap":
        return (
          <div style={{ padding: "10px", backgroundColor: "none" }}>
            <div style={{ paddingBottom: "2px", fontSize: "110%" }}>
              {"Heat Map values".toUpperCase()}
            </div>
            <div style={styles.KeyHolder}>
              {configStore.heatMap.map((item, index) => {
                if (uiStore.capabilityMapValues) {
                  value = "" + item.value
                }
                return (
                  <div key={index} style={styles.KeyItemContainer}>
                    <div
                      style={{
                        ...styles.KeyItem,
                        backgroundColor: item.color
                      }}
                    >
                      {value}
                    </div>
                    {item.label}
                  </div>
                )
              })}
            </div>
          </div>
        )
        break

      case "maturity":
        const maturityMap = toJS(baselineStore.maturityMap)
        console.log(maturityMap)
        console.log(baselineStore)
        console.log(configStore)
        return (
          <div style={{ padding: "10px" }}>
            <div style={{ paddingBottom: "2px", fontSize: "110%" }}>
              {"Maturity Levels".toUpperCase()}
            </div>
            <div style={styles.KeyHolder}>
              {maturityMap.map(
                (item: {
                  value: string
                  level: any
                  color: string | undefined
                  title: React.ReactNode
                }) => {
                  if (uiStore.capabilityMapValues) {
                    value = "" + item.value
                  }
                  return (
                    <div
                      key={`${mode}-${item.level}-Key`}
                      style={styles.KeyItemContainer}
                    >
                      <div
                        style={{
                          ...styles.KeyItem,
                          backgroundColor: item.color
                        }}
                      >
                        {value}
                      </div>
                      {item.title}
                    </div>
                  )
                }
              )}
            </div>
          </div>
        )
        break

      case "importanceLevel":
        return (
          <div style={{ padding: "10px", backgroundColor: "none" }}>
            <div style={{ paddingBottom: "2px", fontSize: "110%" }}>
              {"Importance Levels ".toUpperCase()}
            </div>
            <div style={styles.KeyHolder}>
              {capabilityStore.multiplierLevelsList.map((item, index) => {
                if (uiStore.capabilityMapValues) value = "" + item.multiplier

                return (
                  <div key={index} style={styles.KeyItemContainer}>
                    <div
                      style={{
                        ...styles.KeyItem,
                        backgroundColor: item.color
                      }}
                    >
                      {value}
                    </div>
                    {item.name}
                  </div>
                )
              })}
            </div>
          </div>
        )
        break

      default:
        return null
        break
    }
    // else
  }

  private capabilityClickedOn = (id: any) => {
    this.injected.uiStore.capabilityMapClicked = id
  }

  public render(): JSX.Element {
    const { baselineStore, uiStore, capabilityStore } = this.injected
    const capabilityMap = toJS(baselineStore.capabilityMap)
    const maturityMap = toJS(baselineStore.maturityMap)

    // find mode
    const capMode = uiStore.CapabilityMapMode
    const MaturityMode = uiStore.CapabilityMapMaturiyMode
    // if maturity mode use sub modes
    const mode = capMode === "maturity" ? MaturityMode : capMode
    const currentId = uiStore.capabilityMapId
    // here to force render update
    const Maturiy = uiStore.capabilityMapResultant
    const capMulti = capabilityStore.capabilityMultipliersList

    const capabilityTabs = (
      <div style={{ width: "100%" }}>
        {capabilityMap.map((level0, index) => {
          const onTabClick = () => {
            this.setCurrentId(level0.code)
          }
          return (
            <button
              key={level0.id}
              style={
                (!currentId && index === 0) || currentId === level0.code
                  ? styles.tabActive
                  : styles.tabInactive
              }
              onClick={onTabClick}
            >
              {level0.name}
            </button>
          )
        })}
      </div>
    )

    const capDivDisplay = (
      <div
        style={{
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "flex-start",
          display: "flex",
          flex: 1
        }}
      >
        {capabilityMap.map((level0, index) => {
          if ((!currentId && index === 0) || currentId === level0.code) {
            return (
              <div key={level0.code}>
                {level0.children &&
                  level0.children.map((level1: any, index1: any) => {
                    let level1style = toJS(styles.capLevel1)
                    if (mode === "heatmap") {
                      level1style = this.mapValueToColorHandler(
                        level1.id,
                        styles.capLevel1
                      )
                    }
                    if (
                      mode === "importanceLevel" &&
                      level1.children.length === 0
                    ) {
                      level1style.backgroundColor = mapMultiplierToColor(
                        level1.code,
                        capabilityStore
                      )
                    } else {
                      level1style.backgroundColor = "white"
                    }
                    const value =
                      uiStore.capabilityMapValues === true &&
                        !isUndefined((level1style as any).expenditure)
                        ? "\n : " + (level1style as any).expenditure
                        : ""

                    return (
                      <div
                        key={level1.code}
                        // style={R.merge(
                        //   level1style,
                        //   index1 % 2 === 0
                        //     ? {
                        //       float: "left",
                        //       clear: "left",
                        //       marginLeft: "1%"
                        //     }
                        //     : {
                        //       float: "right",
                        //       clear: "right"
                        //     }
                        // )}
                        onClick={() => this.capabilityClickedOn(level1.code)}
                      >
                        {level1.name} {value}
                        <div style={styles.capLevel1Inner}>
                          {level1.children &&
                            level1.children.map((level2: any) => (
                              <Level2
                                key={`${level2.code}`}
                                mode={mode}
                                capability={level2}
                                mapValueToColor={this.mapValueToColorHandler}
                                calculateMaturityPortolioStyle={
                                  this.calculateMaturityPortolioStyle
                                }
                                maturityMap={maturityMap}
                                displayValue={uiStore.capabilityMapValues}
                                clickedOn={this.capabilityClickedOn}
                              />
                            ))}
                        </div>
                      </div>
                    )
                  })}
              </div>
            )
          } else {
            return <div key={index} />
          }
        })}
      </div>
    )

    return (
      <div style={styles.rightContent}>
        <div
          style={{
            width: "70%",
            position: "relative",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column"
          }}
        >
          {capabilityTabs}
          {capDivDisplay}
          <div
            style={{
              height: "120px",
              display: "flex",
              justifyContent: "space-around",
              width: "100%"
            }}
          >
            {this.renderLegend()}
          </div>
        </div>
        <CapRightSideBar />
      </div>
    )
  }
}

export default CapabilityMap
