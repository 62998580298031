const styles: { [key: string]: React.CSSProperties } = {
  rightContent: {
    flex: '0 0 100%',
    display: 'flex',
    flexDirection: 'column'
  },
  headerContainer: {
    width: '100%',
    position: 'relative',
    backgroundColor: 'rgb(160, 162, 151)',
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between'
  },
  SelectField: {
    width: '100%'
  },
  capLevel1: {
    marginTop: 5,
    border: 'black solid thin',
    borderRadius: 5,
    backgroundColor: 'white',
    color: 'black',
    display: 'inline-block',
    width: '48%',
    flexGrow: 1,
    textAlign: 'center',
    alignItems: 'center'
  },
  capLevel2: {
    margin: 5,
    border: 'black solid thin',
    borderRadius: 5,
    backgroundColor: 'lightyellow',
    display: 'inline-block',
    float: 'left',
    fontSize: 12,
    padding: 10,
    color: 'black',
    flexGrow: 1,
    position: 'relative',
    alignItems: 'center'
  },
  capLevel1Inner: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 5,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    flexGrow: 1,
    alignItems: 'center',
    textAlign: 'center'
  },
  tabActive: {
    backgroundColor: '#777',
    border: '1px solid #bbb',
    marginRight: 5
  },
  tabInactive: {
    backgroundColor: '#aaa',
    border: '1px solid #bbb',
    marginRight: 5
  },
  capabilityNameOver: {
    color: 'black',
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    top: '50%',
    zIndex: 300,
    transform: 'translate(-50%, -50%)',
    left: '50%'
  }
}
export default styles
