export const applyMouseOver = (snapElement: any, mainSnap: any) => {
  snapElement.shape.mouseover(() => {
    mainSnap.customProperties.selectedProject = snapElement.project_id
  })
}

export const applyMouseMove = (snapElement: any, mainSnap: any) => {
  snapElement.shape.mousemove(() => {
    mainSnap.customProperties.selectedProject = snapElement.project_id
  })
}
export const applyMouseOut = (snapElement: any, mainSnap: any) => {
  snapElement.shape.mouseout(() => {
    delete mainSnap.customProperties.selectedProject
  })
}

export const applyMouseOver2 = (snapElement: any, mainSnap: any) => {
  snapElement.path.mouseover(() => {
    mainSnap.customProperties.ringID = snapElement.id
  })
}

export const applyMouseOut2 = (snapElement: any, mainSnap: any) => {
  snapElement.path.mouseout(() => {
    delete mainSnap.customProperties.ringID
  })
}
