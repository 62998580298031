import * as React from 'react'
import { observer } from 'mobx-react'

export interface Props {
  inUse: boolean
  color?: string
}

@observer
class LoadingSymbol extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props)
  }

  public render(): JSX.Element {
    const returnItems = (
      <div>
        <div>{this.props.children}</div>
      </div>
    )

    return returnItems
  }
}

export default LoadingSymbol
