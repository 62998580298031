import PermissionStore from "./PermissionStore"
import UiStore from "./UiStore"
import ScenariosStore from "./ScenariosStore"
import BaselineStore from "./BaselineStore"
import ConfigStore from "./ConfigStore"
import CapabilityStore from "./CapabilitiesStore"

class RootStore {
  public permissionStore: PermissionStore
  public uiStore: UiStore
  public scenariosStore: ScenariosStore
  public baselineStore: BaselineStore
  public configStore: ConfigStore
  public capabilityStore: CapabilityStore

  constructor() {
    this.permissionStore = new PermissionStore(this)
    this.uiStore = new UiStore(this)
    this.scenariosStore = new ScenariosStore(this)
    this.baselineStore = new BaselineStore(this)
    this.configStore = new ConfigStore(this)
    this.capabilityStore = new CapabilityStore(this)
  }
}

export default RootStore
