import * as React from "react"
import Papa from "papaparse"
import * as R from "ramda"
import { observer, inject } from "mobx-react"

import Dialog from "material-ui/Dialog"
import IconButton from "material-ui/IconButton"
import ClearIcon from "material-ui/svg-icons/content/clear"
import FlatButton from "material-ui/FlatButton"
import TextField from "material-ui/TextField"
import Save from "material-ui/svg-icons/content/save"

import UiStore from "../../mobx/UiStore"
import PermissionStore from "../../mobx/PermissionStore"
import DataImportProjectsTable from "../../components/DataImportProjectsTable"
import { inputFiles } from "../../config"
import {
  parseProjectTypes,
  parseResources,
  parseTimePeriods,
  parseProperties,
  parseProjects,
  parseElements,
  parseRelations,
  parseAttributes,
  parseCapabilityLevels
} from "../../utils/dataimport"
import {
  ProjectType,
  Constraint,
  TimePeriod,
  Initiative,
  Strategy,
  MaturityLevel
} from "../../utils/dataimport/interfaces"

import { styles } from "./styles"

const fileNameToHandler: { [key: string]: any } = {
  ProjectTypes: parseProjectTypes,
  Resources: parseResources,
  Properties: parseProperties,
  Relations: parseRelations,
  Elements: parseElements,
  TimePeriods: parseTimePeriods,
  Projects: parseProjects,
  CapabilityLevels: parseCapabilityLevels,
  Attributes: parseAttributes
}

export interface State {
  capabilities: any
  strategies: Strategy[]
  initiatives: Initiative[]
  elements: any[]
  capabilityMap: any
  propertiesLoaded: boolean
  elementsLoaded: boolean
  relationshipLoaded: boolean
  projectCapabilitiesMap: any
  contributions: any
  projects: any
  projectTypes: ProjectType[]
  timePeriods: TimePeriod[]
  constraints: Constraint[]
  currentStep: number
  saveAsOpen: boolean
  name: string
  description: string
  maturityLevels: MaturityLevel[]
  importanceLevels: MaturityLevel[]
  currentTimePeriod: any
}

interface InjectedProps extends Props {
  uiStore: UiStore
  permissionStore: PermissionStore
}

export interface Props { }

@inject(({ store }) => {
  return {
    uiStore: store.uiStore,
    permissionStore: store.permissionStore
  }
})
@observer
class DataImport extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  private defaultState = {
    capabilities: undefined,
    strategies: [],
    initiatives: [],
    timePeriods: [],
    elements: [],
    projectTypes: [],
    capabilityMap: undefined,
    propertiesLoaded: false,
    elementsLoaded: false,
    relationshipLoaded: false,
    projectCapabilitiesMap: undefined,
    contributions: undefined,
    projects: undefined,
    constraints: [],
    currentStep: inputFiles[0].step,
    saveAsOpen: false,
    name: "New Baseline",
    description: "",
    maturityLevels: [],
    importanceLevels: [],
    currentTimePeriod: undefined
  }

  constructor(props: Props) {
    super(props)
    this.state = this.defaultState
  }

  /**
   *
   */
  private handleFileUpload = (event: any) => {
    const file = event.target.files[0]
    // config for papaparse, goto https://www.papaparse.com/docs#config for info on what each one does
    const config: any = {
      delimiter: "", // auto-detect
      newline: "", // auto-detect
      quoteChar: '"',
      header: true,
      dynamicTyping: false,
      preview: 0,
      encoding: "",
      worker: false,
      comments: false,
      step: undefined,
      complete: undefined,
      error: undefined,
      download: false,
      skipEmptyLines: true,
      chunk: undefined,
      fastMode: undefined,
      beforeFirstChunk: undefined,
      withCredentials: undefined
    }
    // pass the file to its correct importer via call back function and Papa.parse
    const operation = fileNameToHandler[event.target.name]
    config.complete = this.parseStep(operation)
    Papa.parse(file, config)
  }

  /**
   * parse the current step in
   *
   */
  private parseStep = (operation: any) => (results: any) => {
    const input = { data: results.data, state: this.state }
    const newState = operation(input)
    if (newState) {
      this.setState({ ...newState }, this.nextStep)
    } else {
      this.errorStep()
    }
  }

  /**
   * sets the current step to completed sucessfully and advances the current step
   * TODO: update this to check if an old step was re-done, if so, don't advance (can currently cause crashes)
   */
  private nextStep = () => {
    const currentFile = R.find(R.propEq("step", this.state.currentStep))(
      inputFiles
    )
    if (currentFile != undefined) {
      // @ts-ignore
      currentFile.validated = true
      // @ts-ignore
      currentFile.error = false
    }

    const currentStep = this.state.currentStep + 1
    this.setState({ currentStep })
  }
private errorStep = () => {
    // @ts-ignore
    const currentFile = R.find(R.propEq("step", this.state.currentStep))(inputFiles)
    if(currentFile != undefined){
      // @ts-ignore
      currentFile.error = true
      // @ts-ignore
      currentFile.validated = false
    }
    this.forceUpdate()
  }

  private saveBaseline = async (input: {
    projects: any[]
    initiatives: Initiative[]
    capabilityMap: any
    projectTypes: ProjectType[]
    timePeriods: TimePeriod[]
    strategies: Strategy[]
    constraints: Constraint[]
    maturityLevels: MaturityLevel[]
    importanceLevels: MaturityLevel[]
    currentTimePeriod: any
  }): Promise<any> => {
    const { permissionStore } = this.injected
    const body = {
      scenario: {
        name: this.state.name,
        description: this.state.description,
        isBaseline: true,
        capabilityMap: JSON.stringify({
          capabilities: input.capabilityMap
        }),
        currentPeriodIndex: input.currentTimePeriod
      },
      constraints: input.constraints,
      strategies: input.strategies.map(s => {
        R.dissoc("id", s)
        return s
      }),
      timePeriods: input.timePeriods.map(t => {
        R.dissoc("id", t)
        return t
      }),
      projectTypes: input.projectTypes,
      initiatives: input.initiatives.map(i => {
        const dbInitiative = {
          id: i.ID,
          init_name: i.name,
          init_strategy: i.strategy
        }
        return dbInitiative
      }),
      projects: input.projects.map(p => R.dissoc("id", p)),
      maturityLevels: input.maturityLevels,
      importanceLevels: input.importanceLevels
    }
    const token = permissionStore.getToken()
    await fetch(`${process.env.REACT_APP_API_URL}/data/import/baseline`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      body: JSON.stringify(body)
    })
  }
  private openSaveBaselineAs = () => {
    this.setState({ saveAsOpen: true })
  }
  private closeSaveBaselineAs = () => {
    this.setState({ saveAsOpen: false })
  }

  private handleChange = (event: any) => {
    if (event.target.id === "baselineName") {
      this.setState({ name: event.target.value })
    }
    if (event.target.id === "baselineDescription") {
      this.setState({ description: event.target.value })
    }
  }

  private saveBaselineFn = async () => {
    await this.saveBaseline({ ...this.state })
    this.closeSaveBaselineAs()
  }

  public render(): JSX.Element {
    const { permissionStore } = this.injected
    if (!permissionStore.showDataImportView) {
      return <div />
    }
    const numberOfSteps = R.last(inputFiles).step
    const title = (
      <div style={styles.titleHeader}>
        <h3 style={styles.titleHeaderContent}>
          Save As
          <IconButton
            onClick={this.closeSaveBaselineAs}
            style={styles.closeButton}
            iconStyle={styles.closeButtonIcon}
          >
            <ClearIcon />
          </IconButton>
        </h3>
      </div>
    )
    const actions = [
      <FlatButton
        key="cancel"
        label="Cancel"
        primary={true}
        onClick={this.closeSaveBaselineAs}
      />,
      <FlatButton
        key="save"
        label="Save"
        primary={true}
        onClick={this.saveBaselineFn}
      />
    ]

    const allValidated = inputFiles.filter(x => !x.validated).length === 0

    return (
      <div style={styles.rightContent}>
        <h3>Data Import</h3>
        <div style={styles.container}>
          {inputFiles.map(inputFile => {
            if (inputFile.step <= this.state.currentStep) {
              return (
                <div key={`input-${inputFile.step}`} style={styles.inputBox}>
                  <label>
                    Step {inputFile.step}/{numberOfSteps}: {inputFile.label}
                  </label>
                  <input
                    name={inputFile.name}
                    type="file"
                    onChange={this.handleFileUpload}
                  />
                  {inputFile.validated && <span> Validated</span>}
                  {inputFile.error && <span> Error in input file</span>}
                </div>
              )
            } else {
              return <div key={`input-${inputFile.step}`} />
            }
          })}

          <div style={styles.inputBox}>
            <FlatButton
              icon={<Save />}
              onClick={this.openSaveBaselineAs}
              disabled={!allValidated}
              primary={true}
            >
              Save Baseline
            </FlatButton>
          </div>
          <div style={styles.inputBox}>
            <DataImportProjectsTable projects={this.state.projects} />
          </div>
          <Dialog
            open={this.state.saveAsOpen}
            title={title}
            actions={actions}
            modal={false}
            onRequestClose={this.closeSaveBaselineAs}
          >
            <TextField
              floatingLabelText={"Name"}
              id="baselineName"
              floatingLabelFixed={true}
              value={this.state.name}
              onChange={this.handleChange}
              multiLine={true}
            />
            <TextField
              floatingLabelText={"Description"}
              id="baselineDescription"
              hintText={"Please enter a Description"}
              value={this.state.description}
              floatingLabelFixed={true}
              multiLine={true}
              onChange={this.handleChange}
            />
          </Dialog>
        </div>
      </div>
    )
  }
}

export default DataImport
