import * as React from "react"
import { toJS } from "mobx"
import * as ReactDOM from "react-dom"

import { calculateRingPositions } from "../utils/radarUtils"
import { getCircleCatagory } from "../utils/radar/circleSize"
import { snapTools } from "./SnapTools/snapTools"
import {
  createBackCircle,
  connectCircles,
  createMarker,
  markerSize
} from "../utils/radar/createBackCircle"
import Project, { Mandatory } from "../mobx/Project"
import { Strategy, Type } from "../mobx/BaselineStore"
import { ProjectCircle } from "../mobx/ConfigStore"
import { projectAttributeTotal } from "../mobx/Scenario"
import { ifElse } from "ramda"

let domElement: any = null

export interface Props {
  strategyArray: Strategy[]
  projectArray: Project[]
  periodArray: any[]
  currentPeriod: number
  oldPeriod: number
  difference: Project[]
  Catagories: ProjectCircle[]
  typeColors: Type[]
  getSizeFrom: string
  selectedProject: string | undefined
  highlightedProject: string | undefined
  setSelectedProject: (id: string | undefined) => void
  openProjectDialog(): void
  clearSelection(): void
  showHoverbox: (content: any, title?: any) => void
  hideHoverbox(): void
  changeHoverboxPosition: (x: number, y: number) => void
}

class RadarGraph extends React.Component<Props, any> {
  private myState: any

  constructor(props: Props) {
    super(props)

    this.state = {
      componentID: snapTools.createUniqueID(),
      ringPositions: []
    }
    this.myState = {
      componentID: "snapContainer",
      projectArray: [],
      maxSize: 700,
      marker: null,
      padding: 10,
      projectCircles: [],
      ringPositions: [],
      rings:[],
      strategyLength: this.props.strategyArray.length,
      selectedDot: "",
      selectedDotClicked: "",
      animationHandler: null,
      frameRate: 60,
      animationTick: 0
    }
  }

  public thisSnap: any = null

  public componentDidMount() {
    const thisDom = ReactDOM.findDOMNode(this) as any
    if (thisDom !== null) {
      domElement = thisDom.getElementsByTagName("svg")[0]
    }
    if (this.thisSnap === null) {
      this.radarSetup()
    }
  }

  public componentDidUpdate() {
    // check the props has loaded
    this.myState.strategyLength = this.props.strategyArray.length
    let optimisedChange = false

    if (this.props.projectArray !== this.myState.projectArray) {
      this.myState.projectArray = this.props.projectArray
      optimisedChange = true
    }

    if (this.thisSnap == null) {
      this.radarSetup()
    }

    if (optimisedChange) {
      // if a new project has been added
      let counter = 0
      const optimisedDots = this.myState.projectArray
      this.myState.projectCircles.forEach((projectCircle: any) => {
        optimisedDots.forEach((optimisedDot: Project) => {
          if (optimisedDot.code === projectCircle.project_id) {
            counter++
          }
        })
      })

      if (
        optimisedDots.length !== counter ||
        this.myState.projectCircles.length !== counter
      ) {
        this.radarRefresh()
      }

      this.myState.animationTick = 0

      this.radarUpdate()
      this.myState.projectCircles.forEach((projectCircle: any) => {
        projectCircle.oldAngle = projectCircle.currentAngle
        projectCircle.oldOffset = projectCircle.currentOffset
        projectCircle.oldRadius = projectCircle.currentRadius
      })

      if (
        this.myState.animationHandler === null &&
        this.myState.projectCircles.length > 0
      ) {
        this.myState.animationHandler = setInterval(
          () => this.animateCircles(),
          1000 / this.myState.frameRate
        )
      }
    }

    //#region update highlighting on selected and highlighted dots
    // this includes removing old selections / highlights
    if (this.myState.selectedDotClicked !== this.props.selectedProject) {
      const previousProjectSelectedClicked = this.myState.selectedDotClicked
      this.myState.selectedDotClicked = this.props.selectedProject
      this.myState.projectCircles.forEach((projectCircle: any) => {
        if (projectCircle.project_id === this.myState.selectedDotClicked) {
          projectCircle.circle.attr("stroke", "red")
          this.thisSnap.snap.append(projectCircle.circleGroup)
        }

        if (projectCircle.project_id === previousProjectSelectedClicked) {
          projectCircle.circle.attr({
            fill: projectCircle.fillColor
          })
          projectCircle.circle.attr("stroke", projectCircle.strokeColor)
        }
      })
    }

    if (this.myState.selectedDot !== this.props.highlightedProject) {
      const previousProjectSelected = this.myState.selectedDot
      this.myState.selectedDot = this.props.highlightedProject
      this.myState.projectCircles.forEach((projectCircle: any) => {
        if (projectCircle.project_id === this.myState.selectedDot) {
          projectCircle.circle.attr("stroke", "red")
          this.thisSnap.snap.append(projectCircle.circleGroup)
        }
        if (
          projectCircle.project_id === previousProjectSelected &&
          this.myState.selectedDotClicked !== projectCircle.project_id
        ) {
          projectCircle.circle.attr({
            fill: projectCircle.fillColor
          })
          projectCircle.circle.attr("stroke", projectCircle.strokeColor)
        }
      })
    }
    //#endregion
  }

  /**
   * type colours come from DB and use a simple id mapping to a list
   * @param projectType the project type to get the colour for
   */
  private getColorByProjectType = (projectType: number) => {
    const typeColors = this.props.typeColors
    const typeIndex = typeColors.findIndex(
      (item: Type) => item.id === projectType
    )
    return `#${typeColors[typeIndex].color}`
  }

  private createSnapPosition = (startingPeriod: string, periods: number) => {
    const angleTest = Math.random() * 360
    const snapPosition = snapTools.positionAtAngle(
      { x: 0, y: 0 },
      angleTest,
      (this.myState.maxSize / 2 / periods) * parseInt(startingPeriod, undefined)
    )
    return snapPosition
  }

  private createCircleCover = (calcRadius: any, deadPattern: string) => {
    const circleCover = snapTools.createCircle(
      this.thisSnap.snap,
      30,
      0,
      deadPattern
    )
    circleCover.attr("stroke", "none")
    circleCover.attr("pointer-events", "none")
    circleCover.attr("r", calcRadius + "px")
    return circleCover
  }

  private createCircleGroup(circle: any, circleCover: any, snapPosition: any) {
    const circleGroup = this.thisSnap.snap.group(circle, circleCover) // add to a group
    const center = {
      x: this.myState.maxSize / 2 + this.myState.padding,
      y: this.myState.maxSize / 2 + this.myState.padding
    }
    circleGroup.attr(
      "transform",
      "translate(" +
      (center.x + snapPosition.x) +
      "," +
      (center.y + snapPosition.y) +
      ")"
    )
    return circleGroup
  }
  private createRadarCircle = (calcRadius: any, deadPattern: any) => {
    const thisCircle = snapTools.createCircle(
      this.thisSnap.snap,
      30,
      0,
      "#00AA00"
    )

    thisCircle.attr("stroke-width", "2px")
    thisCircle.attr("pointerEvents", "auto")
    thisCircle.attr("cursor", "pointer")
    thisCircle.attr("r", calcRadius + "px")

    return thisCircle
  }

  /**
   *
   */
  private refreshCircles = (oldCircles?: any[]) => {
    const projectArray = this.props.projectArray
    const periodArray = this.props.periodArray

    const deadPattern: any = snapTools.createPattern(
      "diagonalLines",
      this.thisSnap.snap
    )
    projectArray.forEach((project: Project, index: number) => {
      const size = projectAttributeTotal(project, this.props.getSizeFrom)

      let calcRadius = getCircleCatagory(size, this.props.Catagories)

      let angleTest = 0
      let snapPosition = this.createSnapPosition(
        project.startPeriod.toString(),
        periodArray.length
      )

      const currentPosition = { ...snapPosition }

      const thisCircle = this.createRadarCircle(calcRadius, deadPattern)
      const circleCover = this.createCircleCover(calcRadius, deadPattern)
      const circleGroup = this.createCircleGroup(
        thisCircle,
        circleCover,
        snapPosition
      )

      const fillColor = this.getColorByProjectType(project.typeId)

      if (oldCircles && oldCircles[index]) {
        calcRadius = oldCircles[index].currentRadius
        snapPosition = oldCircles[index].currentOffset
        angleTest = oldCircles[index].angle
      }

      angleTest =
        (360 / this.myState.strategyLength) * project.strategyId +
        angleTest -
        90

      const circleObject = {
        code: project.code,
        prerequisites: project.prerequisites,
        circle: thisCircle,
        shape: thisCircle,
        deadCover: circleCover,
        circleGroup,
        fillColor,
        angle: angleTest,
        previousUpdate: true,
        project_id: project.code,
        currentAngle: angleTest,
        currentPosition,
        oldAngle: angleTest,
        currentOffset: snapPosition,
        oldOffset: snapPosition,
        oldRadius: calcRadius,
        currentRadius: calcRadius
      }

      this.myState.projectCircles.push(circleObject)
      snapTools.applyMouseOver(circleObject, this.thisSnap)
      snapTools.applyMouseOut(circleObject, this.thisSnap)
    })
  }

  private radarRefresh() {
    const oldCircles = JSON.parse(JSON.stringify(this.myState.projectCircles))

    // delete old circles
    this.myState.projectCircles.forEach((projectCircle: any) => {
      projectCircle.circle.remove()
      projectCircle.shape.remove()
      projectCircle.deadCover.remove()
      projectCircle.circleGroup.remove()
    })

    this.myState.projectCircles = []
    this.refreshCircles(oldCircles)
  }

  private radarSetup() {
    this.thisSnap = snapTools.createSnap(this.state.componentID)

    if (!this.myState.marker) {
      this.myState.marker = createMarker(`#${this.state.componentID}`)
    }
    createBackCircle(
      `#${this.state.componentID}`,
      this.myState.maxSize,
      this.myState.padding
    )

    const graphCenter = this.myState.maxSize / 2 + this.myState.padding

    const minOpacity = 0.0
    const maxOpacity = 0.5

    const opacityIncrease =
      (maxOpacity - minOpacity) / this.myState.strategyLength

    for (let i = 0; i < this.myState.strategyLength; i++) {
      const thisPath = snapTools.pieSectorPath(
        { x: graphCenter, y: graphCenter },
        0,
        this.myState.maxSize / 2,
        (360 / this.myState.strategyLength) * i - 90,
        360 / this.myState.strategyLength
      )

      // 'rgba(120, 159, 152,' + (minOpacity + opacityIncrease * i) + ')',
      const strategyPie = snapTools.drawPath(
        this.thisSnap.snap,
        thisPath,
        1,
        "rgba(119, 128, 137," + (minOpacity + opacityIncrease * i) + ")",
        "#fff"
      )

      const snapRef = this.thisSnap

      const strategyArray = this.props.strategyArray

      strategyPie.mouseover(() => {
        snapRef.customProperties.strategy = strategyArray[i].name

        snapRef.customProperties.angle =
          (360 / strategyArray.length) * i + 360 / strategyArray.length / 2

        strategyPie.attr({ strokeWidth: "3px" })
        strategyPie.attr({ stroke: "white" })
      })

      strategyPie.mouseout(() => {
        strategyPie.attr({ strokeWidth: "1px" })
        snapRef.customProperties.strategy = undefined
      })
    }

    const timeslots = this.props.periodArray.length // the timeslot amount plus 1 for the correct alignment

    const ringPositions = calculateRingPositions(
      timeslots,
      this.myState.maxSize
    )
    this.setState({ ringPositions })
    this.myState.ringPositions = ringPositions

    for (let i = 0; i < timeslots; i++) {
            let thisCircle: any = null

      const circleOffset = ringPositions[i]

      thisCircle = snapTools.createCircle(
        this.thisSnap.snap,
        circleOffset,
        this.myState.padding,
        "#333"
      )
      thisCircle.attr("stroke", this.ringColour(i))

      // if (i % 2 === 0) {
      //   thisCircle.attr("stroke", "rgba(255,255,255,0.4)")
      // } else {
      //   thisCircle.attr("stroke", "rgba(255,255,255,0.1)")
      // }     
      //  if(i == this.props.currentPeriod){
      //   thisCircle.attr("stroke", "red")
      // }
      thisCircle.attr("strokeWidth", "1px")
      thisCircle.attr("pointerEvents", "none")

      thisCircle.attr("fill", "none")
      const padding = this.myState.maxSize / 2 + this.myState.padding
      thisCircle.attr("cx", padding)
      thisCircle.attr("cy", padding)
      this.myState.rings.push(thisCircle)
    }

    this.refreshCircles()
  }

  private ringColour = (index:number) =>{
    if(index === this.props.currentPeriod){
      if(this.props.currentPeriod === this.props.oldPeriod)
      // not moved
        return "DarkRed"
      else{
        //moved
        return "Red"
      }
    }else if(index === this.props.oldPeriod){
      //moved, highlight old
      return "IndianRed"
    }else if (index % 2 === 0) {
      return "rgba(255,255,255,0.4)"
    } else {
      return "rgba(255,255,255,0.1)"
    }
  }

  private radarUpdate() {
    const { projectArray, strategyArray, difference, periodArray } = this.props
    // I'll be honest, not sure about this bit yet....
    const ringPositions: any[] = []

    for (let i = 0; i < this.myState.strategyLength; i++) {
      const projectFill: any[] = []
      for (let j = 0; j < periodArray.length + 1; j++) {
        projectFill.push([])
      }
      ringPositions.push(projectFill)
    }
    //update current Period
    for (let ring = 0; ring < this.myState.rings.length; ring++) {
      this.myState.rings[ring].attr("stroke", this.ringColour(ring))
      
      this.myState.rings[ring].attr("stroke-dasharray", "0")
      //dash a changed period
      if(ring === this.props.currentPeriod && this.props.currentPeriod != this.props.oldPeriod) {
        this.myState.rings[ring].attr("stroke-dasharray", "5")
        
      }
    }
    //update projects
    projectArray.forEach((project: Project) => {
      const TypeIndex = strategyArray.findIndex(
        (item: Strategy) => item.id === project.strategyId
      )

      if (TypeIndex !== -1) {
        ringPositions[TypeIndex][project.startPeriod].push(project.code)
      } else {
        alert(
          "There is a project without an appropritate strategy: " + project.code
        )
        console.warn(
          "There is a project without an appropritate strategy: " + project.code
        )
        ringPositions[0][project.startPeriod].push(project.code)
      }
    })

    for (let i = 0; i < this.myState.projectCircles.length; i++) {
      const typeHexColor = this.getColorByProjectType(projectArray[i].typeId)
      if (projectArray[i].alive) {
        this.myState.projectCircles[i].fillColor = typeHexColor
        this.myState.projectCircles[i].strokeColor = "rgba(255, 255,255, 1)"
        this.myState.projectCircles[i].circleGroup.attr("opacity", 1.0)
        this.myState.projectCircles[i].deadCover.attr("opacity", 0.0)
      } else {
        const rgbString = this.hexToRgb(typeHexColor.substr(1))
        this.myState.projectCircles[i].fillColor = `rgba(${rgbString}, 0.4)`
        this.myState.projectCircles[i].strokeColor = "rgba(255, 255,255, 0.4)"

        this.myState.projectCircles[i].circleGroup.attr("opacity", 1.0)
        this.myState.projectCircles[i].deadCover.attr("opacity", 0.4)
      }
      this.myState.projectCircles[i].circle.attr(
        "stroke",
        this.myState.projectCircles[i].strokeColor
      )
      this.myState.projectCircles[i].circle.attr(
        "fill",
        this.myState.projectCircles[i].fillColor
      )
    }
    if (difference.length) {
      difference.forEach(project => {
        this.myState.projectCircles.forEach((projectCircle: any) => {
          if (projectCircle.project_id === project.code) {
            projectCircle.circle.attr("stroke-dasharray", "5,5")
            projectCircle.strokeColor = "rgba(255, 255,255, 1)"
            projectCircle.circle.attr("stroke", projectCircle.strokeColor)
            this.thisSnap.snap.append(projectCircle.circleGroup)
          }
        })
      })
    }
  }

  private hexToRgb = (hex: any) => {
    const hexString = hex.replace("#", "")
    const r = parseInt(hexString.substring(0, 2), 16)
    const g = parseInt(hexString.substring(2, 4), 16)
    const b = parseInt(hexString.substring(4, 6), 16)
    return r + "," + g + "," + b
  }

  private refreshPrerequisites = () => {
    const { projectArray, highlightedProject } = this.props
    this.myState.projectCircles = this.myState.projectCircles.map(
      (circleObject: {
        code: string
        lines: never[]
        prerequisites: {
          length: any
          forEach: {
            (arg0: (prereq: any) => void): void
            (arg0: (prereq: any) => void): void
          }
        }
      }) => {
        const project = projectArray.find(
          proj => proj.code === circleObject.code
        )
        if (circleObject.lines) {
          circleObject.lines.forEach((line: { remove: () => void }) =>
            line.remove()
          )
        }
        circleObject.lines = []
        // Draw dashed arrows for selecteed project's prerequisites
        if (
          project &&
          project.code === highlightedProject &&
          circleObject.prerequisites.length
        ) {
          circleObject.prerequisites.forEach((prereq: any) => {
            const prereqObject = this.myState.projectCircles.find(
              (object: { code: any }) => object.code === prereq.name
            )
            const prereqProject = projectArray.find(
              proj => proj.code === prereqObject.code
            )
            if (prereqObject && prereqProject) {
              this.drawDashedConnection(circleObject, prereqObject)
            }
          })
        }
        // Draw dashed arrows when selected project is prerequisite
        if (
          project &&
          project.code !== highlightedProject &&
          circleObject.prerequisites &&
          circleObject.prerequisites.length
        ) {
          circleObject.prerequisites.forEach((prereq: any) => {
            const prereqObject = this.myState.projectCircles.find(
              (object: { code: any }) => object.code === prereq.name
            )
            const prereqProject = projectArray.find(
              proj => proj.code === prereqObject.code
            )
            if (
              prereqObject &&
              prereqProject &&
              prereqProject.code === highlightedProject
            ) {
              this.drawDashedConnection(circleObject, prereqObject)
            }
          })
        }
        return circleObject
      }
    )
  }

  private drawDashedConnection = (circleObject: any, prereqObject: any) => {
    const radiusOne = circleObject.currentRadius + markerSize
    const radiusTwo = prereqObject.currentRadius

    const boxOne = {
      cx: circleObject.currentPosition.x,
      cy: circleObject.currentPosition.y
    }

    const boxTwo = {
      cx: prereqObject.currentPosition.x,
      cy: prereqObject.currentPosition.y
    }

    const distanceBetweenCentres = Math.sqrt(
      Math.pow(boxTwo.cx - boxOne.cx, 2) + Math.pow(boxTwo.cy - boxOne.cy, 2)
    )

    const edgeOneRatio = radiusOne / (distanceBetweenCentres - radiusOne)
    const edgeTwoRatio = radiusTwo / (distanceBetweenCentres - radiusTwo)

    const boxOneAdjusted = {
      cx: (boxOne.cx + boxTwo.cx * edgeOneRatio) / (1 + edgeOneRatio),
      cy: (boxOne.cy + boxTwo.cy * edgeOneRatio) / (1 + edgeOneRatio)
    }

    const boxTwoAdjusted = {
      cx: (boxTwo.cx + boxOne.cx * edgeTwoRatio) / (1 + edgeTwoRatio),
      cy: (boxTwo.cy + boxOne.cy * edgeTwoRatio) / (1 + edgeTwoRatio)
    }

    const line = connectCircles(
      `#${this.state.componentID}`,
      boxTwoAdjusted,
      boxOneAdjusted,
      this.myState.marker
    )
    circleObject.lines.push(line)
  }

  private animateCircles() {
    if (this.myState.animationTick > 24) {
      if (this.myState.animationHandler != null) {
        this.myState.animationTick = 0
        clearInterval(this.myState.animationHandler)
        this.myState.animationHandler = null
        this.refreshPrerequisites()
        return
      }
    }

    this.myState.animationTick += 1

    const projectArray = this.props.projectArray
    const periodArray = this.props.periodArray

    if (typeof projectArray === "undefined") {
      return
    }

    const timeToComplete = 0.3 // seconds
    const frameRate = this.myState.frameRate
    const timeByFrames = timeToComplete * frameRate

    const ringPositions: any[] = []

    const strategyArray = this.props.strategyArray

    for (let i = 0; i < this.myState.strategyLength; i++) {
      const projectFill: any[] = []
      for (let j = 0; j < periodArray.length + 1; j++) {
        // add one to store 0 in
        projectFill.push([])
      }

      ringPositions.push(projectFill)
    }
    projectArray.forEach((project: Project) => {
      const TypeIndex = strategyArray.findIndex(
        (item: Strategy) => item.id === project.strategyId
      )
      if (project.mandatory !== Mandatory.Excluded) {
        if (TypeIndex !== -1) {
          ringPositions[TypeIndex][project.startPeriod].push(project.code)
        } else {
          ringPositions[0][project.startPeriod].push(project.code)
        }
      } else {
        if (TypeIndex !== -1) {
          ringPositions[TypeIndex][0].push(project.code)
        } else {
          ringPositions[0][0].push(project.code)
        }
      }
    })
    // console.log(ringPositions)

    projectArray.forEach((project: Project) => {
      let thisProjectCircle: any = null
      const projectCirclesJS = toJS(this.myState.projectCircles)

      for (let j = 0; j < projectCirclesJS.length; j++) {
        if (projectCirclesJS[j].project_id === project.code) {
          thisProjectCircle = this.myState.projectCircles[j]
          break
        }
      }

      const TypeIndex = strategyArray.findIndex(
        (item: Strategy) => item.id === project.strategyId
      )
      let angleTest = thisProjectCircle.angle

      if (project.mandatory === Mandatory.Excluded) {
        thisProjectCircle.circle.attr("visibility", "hidden")
        thisProjectCircle.deadCover.attr("visibility", "hidden")
      } else {
        thisProjectCircle.circle.attr("visibility", "visible")
        thisProjectCircle.deadCover.attr("visibility", "visible")
      }

      let positionRatio = angleTest

      const positionGroup: any =
        TypeIndex !== -1
          ? ringPositions[TypeIndex][project.startPeriod]
          : ringPositions[0][project.startPeriod]

      let positionIndex = 1

      for (let j = 0; j < positionGroup.length; j++) {
        if (thisProjectCircle.project_id === positionGroup[j]) {
          positionIndex = j
        }
      }

      positionRatio =
        (360 / this.myState.strategyLength / (positionGroup.length + 1)) *
        (positionIndex + 1)
      positionRatio =
        (360 / this.myState.strategyLength) * TypeIndex + positionRatio

      angleTest = positionRatio

      const size = projectAttributeTotal(project, this.props.getSizeFrom)
      const goalRadius = getCircleCatagory(size, this.props.Catagories)

      let amount = goalRadius
      const increaseAmount =
        Math.abs(thisProjectCircle.oldRadius - goalRadius) / timeByFrames

      if (
        Math.abs(thisProjectCircle.currentRadius - goalRadius) >= increaseAmount
      ) {
        amount =
          thisProjectCircle.currentRadius - goalRadius >= 0
            ? thisProjectCircle.currentRadius - increaseAmount
            : thisProjectCircle.currentRadius + increaseAmount
      }

      thisProjectCircle.currentRadius = amount

      thisProjectCircle.circle.attr("r", amount + "px")
      thisProjectCircle.deadCover.attr("r", amount + "px")

      let goalAmount = positionRatio
      amount = goalAmount
      let moveAmount =
        Math.abs(thisProjectCircle.oldAngle - goalAmount) / timeByFrames

      if (Math.abs(thisProjectCircle.currentAngle - goalAmount) >= moveAmount) {
        amount =
          thisProjectCircle.currentAngle - goalAmount >= 0
            ? thisProjectCircle.currentAngle - moveAmount
            : thisProjectCircle.currentAngle + moveAmount
      }

      thisProjectCircle.currentAngle = amount

      angleTest = amount

      let ringGoal = 0

      if (project.startPeriod !== 0) {
        ringGoal = this.myState.ringPositions[project.startPeriod - 1] / 2
      }

      goalAmount = ringGoal
      amount = goalAmount
      moveAmount =
        Math.abs(thisProjectCircle.oldOffset - goalAmount) / timeByFrames

      if (
        Math.abs(thisProjectCircle.currentOffset - goalAmount) >= moveAmount
      ) {
        amount =
          thisProjectCircle.currentOffset - goalAmount >= 0
            ? thisProjectCircle.currentOffset - moveAmount
            : thisProjectCircle.currentOffset + moveAmount
      }

      thisProjectCircle.currentOffset = amount

      ringGoal = amount

      const snapPosition = snapTools.positionAtAngle(
        { x: 0, y: 0 },
        angleTest,
        ringGoal
      )

      const center = {
        x: this.myState.maxSize / 2 + this.myState.padding,
        y: this.myState.maxSize / 2 + this.myState.padding
      }

      thisProjectCircle.circle.attr("cx", center.x + snapPosition.x)
      thisProjectCircle.circle.attr("cy", center.y + snapPosition.y)

      thisProjectCircle.circle.attr("cx", 0)
      thisProjectCircle.circle.attr("cy", 0)

      thisProjectCircle.circleGroup.attr(
        "transform",
        "translate(" +
        (center.x + snapPosition.x) +
        "," +
        (center.y + snapPosition.y) +
        ")"
      )
      thisProjectCircle.currentPosition = {
        x: center.x + snapPosition.x,
        y: center.y + snapPosition.y
      }
      thisProjectCircle.circleGroup.children().forEach((circle: any) => {
        circle.attr("cx", 0)
        circle.attr("cy", 0)
      })
    })
  }

  private searchSnap = () => {
    if (this.thisSnap != null) {
      if (typeof this.thisSnap.customProperties !== "undefined") {
        if (
          typeof this.thisSnap.customProperties.selectedProject !== "undefined"
        ) {
          this.props.setSelectedProject(
            this.thisSnap.customProperties.selectedProject
          )
        } else {
          this.props.setSelectedProject(undefined)
        }

        if (typeof this.thisSnap.customProperties.strategy !== "undefined") {
          this.props.showHoverbox(this.thisSnap.customProperties.strategy)

          if (domElement != null) {
            const thisPosition = domElement.getBoundingClientRect()

            const centerCircle = this.myState.maxSize / 2 + this.myState.padding

            const dividingPosition = snapTools.positionAtAngle(
              { x: centerCircle, y: centerCircle },
              this.thisSnap.customProperties.angle,
              this.myState.maxSize / 2 + 60
            )
            const positionX =
              thisPosition.left + dividingPosition.x + centerCircle
            const positionY =
              thisPosition.top + dividingPosition.y + centerCircle
            this.props.changeHoverboxPosition(positionX, positionY)
          }
        } else {
          this.props.hideHoverbox()
        }
      }
    }
  }

  public render(): JSX.Element {
    return (
      <div
        style={{
          pointerEvents: "none",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: "0px",
          left: "0px",
          zIndex: 90,
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <svg
          id={this.state.componentID}
          style={{
            pointerEvents: "auto",
            width: this.myState.maxSize + this.myState.padding * 2,
            height: this.myState.maxSize + this.myState.padding * 2
          }}
          onClick={this.props.openProjectDialog}
          onMouseMove={this.searchSnap}
          onMouseOut={this.props.clearSelection}
        />
      </div>
    )
  }
}

export default RadarGraph
