import { toJS, computed, observable } from "mobx"

/**
 * used to store the per-period values for an attribute
 * each attribute is one type, with a array of values to store values
 */
export class attribute {
    @observable name: string = ""
    /**
     * this should be sorted by period, [0] should be period 1
     */
    @observable values: Array<number> = observable([0])
    constructor(name: string) {
        this.name = name
        this.values = observable([0])
    }

    /**
     * if a attribute is using simplified then per-period settings should be disabled
     */
    @observable simplified: boolean = false

    public total = () => {
        let total = 0
        this.values.forEach(value => {
            total += value
        });
        return total
    }

    /**
     * will set the value of a period, it will also lengthen the values array to make sure the value it store in the correct period
     * all new in between values are set to 0
     * @param period the period to store the value in (number - 0 indexed)
     * @param value the value to store (number)
     */
    public setPeriod(period: number, value: number) {
        if (this.values === undefined) {
            this.values = observable.array()
        }
        // make sure we are long enough to store
        if (this.values.length < period) {
            for (let diff = 0; diff < this.values.length - period; diff++) {
                this.values.push(0)
            }
        }
        //store data
        if (this.simplified) { // set for all periods
            this.values.fill(value, 0, this.values.length)
        } else { // just set for the given period
            this.values[period] = value
        }
    }

    /**
     * will update the list of values to be the correct length
     * any new values created will use the fill value provided
     * @param length the new number of values
     * @param fill what any new values with be filled with, default 0
     */
    public updateLength(length: number, fill = 0) {
        console.log("Update Length : "+ length)
        let difference = length - this.values.length
        let newValues = this.values
        if (difference > 0) {
            // grow
            for (let i = 0; i <= difference; i++) {
                newValues.push(fill)
            }
        } else if (difference < 0) {
            //shrink
            newValues= this.values.slice(0, length)
        }
        this.values= newValues
        return this.values

    }

}

export default attribute