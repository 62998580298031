import CapabilityStore, {
  CapabilityMultiplier,
  MultiplierLevel
} from "../../mobx/CapabilitiesStore"
import { isUndefined } from "util"

// need to import capabilitiesStore and ID
const mapMultiplierToColor = (code: any, capabilities: CapabilityStore) => {
  let colour = "white"
  //get mutliplier level for ID from capabilities store
  let cap: CapabilityMultiplier | undefined = capabilities.capabilityMultiplier(
    code
  )
  //get colour for mutliplier
  if (!isUndefined(cap)) {
    let multiplier:
      | MultiplierLevel
      | undefined = capabilities.getMultiplierLevel(cap.multiId)
    if (multiplier) {
      colour = multiplier.color
    }
  }
  //return colour
  return colour
}

export default mapMultiplierToColor
