import * as R from "ramda"
import { blue300 } from "material-ui/styles/colors"
import { HeatMap } from "../../mobx/ConfigStore"
import forEach from "ramda/es/forEach"
import attribute from "../../mobx/Attribute"

/**
 * not sure what this does?
 * @param project project to examin
 * @param id ID of of capability to compare
 */
const projectCapabilityExpenditure = (project: any, id: any) => {
  const projectCapabilities = project.capabilities
  // TODO what is this doing?
  const totalContribution = R.sum(
    projectCapabilities.map((x: any) => x.contribution)
  )
  const currentContribution =
    projectCapabilities.filter((x: any) => x.id === id)[0].contribution || 0

  let expenditure = 0
  let expenditureAttr = project.attributes.find((attr: attribute) => attr.name.toLowerCase() === "expenditure")
  if (expenditureAttr) {
    expenditureAttr.values.forEach((value: any) =>
      expenditure += value
    )
  }
  return (expenditure * currentContribution) / totalContribution
}

const mapValueToColor = (
  id: any,
  style: any,
  selectedProjectCapabilities: any,
  heatMap: any,
  colorDropDown: any,
  listOfProjectsWithCapability: any
) => {
  let backgroundColour = ""
  let boxStyle = style

  const totalExpenditure = R.sum(
    listOfProjectsWithCapability.map((x: any) =>
      projectCapabilityExpenditure(x, id)
    )
  )
  boxStyle = R.merge(style, {
    expenditure: Math.floor(totalExpenditure)
  })

  /**
   * TODO -- move this out of mapValueToColor into higher level
   * to make sure consitiant across all capabilityMaps
   */

  //if selected
  if (R.find(R.propEq("id", id))(selectedProjectCapabilities)) {
    boxStyle = R.merge(boxStyle, {
      backgroundColor: blue300,
      color: "#000"
    })
    return boxStyle
  }

  for (let i = 0; i < heatMap.length; i++) {
    if (totalExpenditure > heatMap[i].value) {
      backgroundColour = heatMap[i].color
    }
  }
  if (backgroundColour !== "") {
    const colorIndex = colorDropDown.findIndex(
      (c: any) => c.color === backgroundColour
    )
    const textColour =
      colorIndex !== -1 ? colorDropDown[colorIndex].text : "#000"

    boxStyle = R.merge(boxStyle, {
      backgroundColor: backgroundColour,
      color: textColour
    })
  }

  return boxStyle
}

/**
 * Returns the colour associated to the value of the given capability on the given heat map
 *
 * @param id Capability ID to use
 * @param listOfProjectsWithCapability list of all projects that containt that
 * @param heatMap
 */
const mapValueToColorSimple = (
  id: any,
  listOfProjectsWithCapability: any,
  heatMap: HeatMap[]
): string => {
  let backgroundColour = ""
  const totalExpenditure = R.sum(
    listOfProjectsWithCapability.map((x: any) =>
      projectCapabilityExpenditure(x, id)
    )
  )

  for (let i = 0; i < heatMap.length; i++) {
    if (totalExpenditure > heatMap[i].value) {
      backgroundColour = heatMap[i].color
    }
  }

  return backgroundColour
}

export default mapValueToColor
