import * as React from "react"
import * as R from "ramda"
import { observer, inject } from "mobx-react"
import { observable, toJS, ObservableMap } from "mobx"

import UiStore from "../mobx/UiStore"
import PermissionStore from "../mobx/PermissionStore"
import ScenariosStore from "../mobx/ScenariosStore"
import BaselineStore from "../mobx/BaselineStore"
import ConfigStore from "../mobx/ConfigStore"
import Project, {
  ProjectProps,
  Mandatory,
  Prerequisites,
  status,
} from "../mobx/Project"
import { nextPossibleProjectState, projectStatusToString, updateStatus } from "../utils/projectState"
import { toNumber } from "lodash"

import Dialog from "material-ui/Dialog"
import IconButton from "material-ui/IconButton"
import ClearIcon from "material-ui/svg-icons/content/clear"
import TextField from "material-ui/TextField"
import SelectField from "material-ui/SelectField"
import MenuItem from "material-ui/MenuItem"
import FlatButton from "material-ui/FlatButton"
import Chip from "material-ui/Chip"
import Add from "material-ui/svg-icons/content/add"
import Avatar from "material-ui/Avatar"
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table"

import { isUndefined } from "util"
import attribute from "../mobx/Attribute"
import { updateAttributeLength } from "../utils/mobxUtils"
import { throws } from "assert"

export interface Props { }

export interface Capability {
  id: string
  contribution: number
}

export interface State {
  selectedProjectProperty: string | undefined
  optimisingDisplay: string
  currentPreReq: string
  currentPreReqType: Prerequisites
  currentCapability: Capability
  currentStartPeriod: number | null
  currentStatus: status
  showAddPrerequisites: boolean
  showAddStartPeriod: boolean
  showAddCapability: boolean
  capabilityButton: string
  errorText: string
}

const projectCodeError: string = "Please use a unique Project Code"
const capabilityCountError: string = "Project requires at least one Capability"

const styles: { [key: string]: React.CSSProperties } = {
  closeButton: {
    padding: 0,
  },
  closeButtonIcon: {
    width: 36,
    height: 36,
    color: "white",
  },
  errorMessage: {
    color: "red",
    fontWeight: "bold",
  },
  titleHeader: {
    backgroundColor: "#5859BC",
    color: "white",
    padding: 0,
    flex: 1,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleHeaderContent: {
    backgroundColor: "#5859BC",
    fontSize: "1em",
    color: "white",
    padding: "0.75rem",
    margin: 0,
    flex: 1,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleStyle: { fontSize: "110%", paddingBottom: "10px" },
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
}

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  configStore: ConfigStore
}

@inject(({ store }) => {
  return {
    configStore: store.configStore,
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore,
  }
})
@observer
class AddProjectPopup extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  @observable private Project: Project
  private defaultProject: ProjectProps

  constructor(props: Props) {
    super(props)
    this.state = {
      selectedProjectProperty: undefined,
      showAddPrerequisites: false,
      showAddStartPeriod: false,
      showAddCapability: false,
      capabilityButton: "Add",
      currentPreReq: "",
      currentPreReqType: Prerequisites.before,
      currentStartPeriod: 0,
      currentCapability: {
        id: "",
        contribution: 0,
      },
      currentStatus:status.candidate,
      optimisingDisplay: "none",
      errorText: "",
    }
    this.defaultProject = {
      initiativeId: this.injected.baselineStore.initiatives[0].id,
      strategyId: this.injected.baselineStore.initiatives[0].StrategyId,
      typeId: this.injected.baselineStore.types[0].id,
      scenarioId: this.injected.scenariosStore.id,
      alive: false,
      inFlight: false,
      status: status.candidate,
      benefits: new attribute("benefits"),
      code: "",
      name: "New Project",
      duration: 1,
      edited: true,
      mandatory: Mandatory.nonMandatory,
      id: undefined,
      prerequisites: [],
      capabilities: [],
      // attributes: [],
      attributes: new Array<attribute>(),
      PhaseId: undefined,
      startPeriod: 1,
      startPeriods: [],
      fromDB: false,
      strategicIndex: 0,
    }
    this.Project = new Project(this.defaultProject)
    

    this.handleChange = this.handleChange.bind(this)
  }

  private onCloseClick = () => {
    this.injected.uiStore.showAddProjectPopup = false
    // on close
    this.Project = new Project(this.defaultProject)
    this.setState({ errorText: "" })
  }

  public addProjectToFrontEnd = () => {
    const newProject = toJS(this.Project)
    if (newProject !== undefined) {
      const existing = this.injected.scenariosStore.working.projects.findIndex(
        (project: ProjectProps) => project.code === newProject.code
      )
      const capCount = this.Project.capabilities.length
      let errorText = ""

      if (capCount <= 0) {
        errorText += capabilityCountError
      }
      if (existing !== -1) {
        errorText += " " + projectCodeError
      }
      if (capCount > 0 && existing === -1) {
        this.injected.scenariosStore.addProject(newProject)

        this.onCloseClick()
        return
      }
      this.setState({ errorText })
    }
  }

  public componentDidMount() {
    this.Project = new Project(this.defaultProject)
  }

  public ChangeCapability = (returnedData: Capability[]) => {
    this.Project.capabilities = returnedData
    this.forceUpdate()
  }
  public ChangePrerequisites = (returnedData: string[]) => {
    // TODO enable this properly
    // this.Project.prerequisites = returnedData
    this.forceUpdate()
  }
  private handleChange(event: any) {
    const name = event.target.name
    const value = event.target.value
      ; (this.Project as any)[name] = value
  }
  private durationChange = (event:any) =>{
    let newValue = parseInt(event.target.value, 10)
    if(newValue === undefined || newValue === null || newValue < 1){
      newValue = 1
    }
    this.Project.duration = newValue
    for(let i = 0; i < this.Project.attributes.length; i++){
      this.Project.attributes[i].values= updateAttributeLength(this.Project.attributes[i].values,newValue)
    }
  }

  private codeCheck = (event: any) => {
    const existing = this.injected.scenariosStore.working.projects.findIndex(
      (project: ProjectProps) => project.code === this.Project.code
    )
    if (existing !== -1) {
      this.setState({ errorText: projectCodeError })
    } else {
      this.setState({ errorText: "" })
    }
  }

  private openPrerequisites = (event: any) => {
    this.setState({ showAddPrerequisites: true })
  }
  private openStartPeriod = (event: any) => {
    this.setState({ showAddStartPeriod: true })
  }
  private preReqAdd = (event: any) => {
    if (
      !this.Project.prerequisites.find(
        (preReq) => preReq.name === this.state.currentPreReq
      )
    ) {
      this.Project.prerequisites.push({
        name: this.state.currentPreReq,
        type: this.state.currentPreReqType, //TODO make this an option
      })
    }
    this.preReqClose(event)
  }
  private startPeriodAdd = (event: any) => {
    if (
      !this.Project.startPeriods.find(
        (startPeriod: number) => startPeriod === this.state.currentStartPeriod
      ) &&
      this.state.currentStartPeriod !== null
    ) {
      this.Project.startPeriods.push(this.state.currentStartPeriod)
    }
    this.startPeriodClose(event)
  }

  private startPeriodClose = (event: any) => {
    this.setState({ showAddStartPeriod: false, currentStartPeriod: null })
  }
  private preReqClose = (event: any) => {
    this.setState({ showAddPrerequisites: false, currentPreReq: "" })
  }
  private removePreRequisite = (index: number) => {
    this.Project.prerequisites.splice(index, 1)
  }
  private removeStartPeriod = (index: number) => {
    this.Project.startPeriods.splice(index, 1)
  }

 /**
   * handles the changing of constraints via textfield
   * largely copied from projectSettingsTab
   * @param key the constraints name
   * @param period the period which is being changed
   * @param event the change event from the textfield
   */
  private constraintChange = async (
    key: any,
    period: number,
    event: { target: { value: string } }
  ) => {
    if (this.Project) {
      const attrIndex = this.Project.attributes.findIndex(
        (attribute: attribute) => attribute.name === key
      )
      if(attrIndex !== undefined){
      // prevent NaN values
      let newValue = parseFloat(event.target.value) || 0
      this.Project.attributes[attrIndex].values[period] = newValue
      }
    }
  }

  private onProjectTypeChange = (event: any, index: any, value: number) => {
    this.Project.typeId = value
  }
  private onMandatoryChange = (event: any, index: any, value: Mandatory) => {
    this.Project.mandatory = value
  }
  private onPrerequisitesChange = (event: any, index: any, value: string) => {
    this.setState({ currentPreReq: value })
  }

  private onStatusChange = (event: any, index: any, value: string) => {
    updateStatus(this.Project,  parseInt(value))
  }
  /**
   * sets the benefits for a project based on a textbox event
   * note this is a simplified version currently
   * @param event the textbox event
   */
  private onBenefitChange = (event: any) => {
    this.Project.benefits.setPeriod(0,parseFloat(event.target.value))
  }

  private onPrerequisitesTypeChange = (
    event: any,
    index: any,
    value: Prerequisites
  ) => {
    this.setState({ currentPreReqType: value })
  }
  private onStartPeriodChange = (
    event: any,
    index: any,
    value: number | null
  ) => {
    this.setState({ currentStartPeriod: value })
  }
  private onSelectCapability = (event: any, index: any, value: string) => {
    this.setCapaiblity(value)
  }
  private setCapaiblity = (capability: string) => {
    const currentCapability = this.state.currentCapability
    let capabilityButton = "Add"
    currentCapability.id = capability

    const capabilityFind = this.Project.capabilities.find(
      (cap: Capability) => cap.id === capability
    )
    if (!isUndefined(capabilityFind)) {
      capabilityButton = "Update"
      currentCapability.contribution = capabilityFind.contribution
    }

    this.setState({ currentCapability, capabilityButton })
  }
  private capaiblityClick = (capability: string) => {
    this.setCapaiblity(capability)
    this.openCapability("")
  }

  private onCapabilityValueChange = (event: any) => {
    const currentCapability = this.state.currentCapability
    currentCapability.contribution = event.target.value
    this.setState({ currentCapability })
  }

  private capabilityAdd = (event: any) => {
    const index = this.Project.capabilities.findIndex(
      (cap: Capability) => cap.id === this.state.currentCapability.id
    )
    if (index === -1) {
      this.Project.capabilities.push(this.state.currentCapability)
    } else {
      this.Project.capabilities[index] = this.state.currentCapability
    }
    this.injected.scenariosStore.calculateProjectStrategicIndex(this.Project)
    this.capabilityClose(event)
  }

  private removeCapability = (index: number) => {
    this.Project.prerequisites.splice(index, 1)
  }

  private capabilityClose = (event: any) => {
    const currentCapability = {
      id: "",
      contribution: 0,
    }
    this.setState({
      showAddCapability: false,
      currentCapability,
      capabilityButton: "Add",
    })
  }
  private openCapability = (event: any) => {
    this.setState({ showAddCapability: true })
  }

  private oninitChange = (event: any, index: any, value: number) => {
    this.Project.initiativeId = value
    this.Project.strategyId = this.injected.baselineStore.getInitiativeFromId(
      value
    ).StrategyId
  }
  // private onStrategyChange = (event, index, value) => {
  //   this.Project.strategyId = value
  // }

  private childrenToArray = (cycleObject: any) => {
    const capabilitiesFlattened: any[] = []

    for (let i = 0; i < cycleObject.length; i++) {
      capabilitiesFlattened.push(cycleObject[i])
      if (typeof cycleObject[i].children !== "undefined") {
        capabilitiesFlattened.push.apply(
          capabilitiesFlattened,
          this.childrenToArray(cycleObject[i].children)
        )
      }
    }
    return capabilitiesFlattened
  }

  public componentWillUpdate() {
    if (
      this.Project.typeId == null &&
      this.injected.baselineStore.types.length > 0
    ) {
      this.Project.typeId = this.injected.baselineStore.types[0].id
      this.Project.initiativeId = this.injected.baselineStore.initiatives[0].id
      this.Project.strategyId = this.injected.baselineStore.getInitiativeFromId(
        this.Project.initiativeId
      ).StrategyId
    }
  }

  public render(): JSX.Element {
    if (
      this.injected.baselineStore == null ||
      this.injected.scenariosStore.loaded === false
    ) {
      return <div />
    }

    if(this.Project.attributes.length < 1 && this.injected.baselineStore.loaded){
      //setup Benefits
      let newBenefit = new attribute(this.injected.scenariosStore.baseline.projects[0].benefits.name)
      for (let d = 0; d < 99; d++) { // using 99 here, should be done more dynamically in the future
        newBenefit.setPeriod(d, 0)
      }
      newBenefit.simplified = true; // this is while we are using a single benefit for all periods
      //setup Attributes
      this.injected.scenariosStore.baseline.constraints.map((constraint, constraintIndex) => {
        this.Project.attributes.push(new attribute(constraint.name))
        this.Project.attributes[constraintIndex].values = [0]
      })
    }

    // setup status lists
    const currentStatus = this.Project.status
    const statusList = []
    // status that you can set it too
    statusList.push(status.candidate)
    statusList.push(status.excluded)
    statusList.push(status.in_flight)
    statusList.push(status.suspended)
    statusList.push(status.completed)


    const StrategicIndex = this.Project.strategicIndex

    if (this.Project) {
      const title = (
        <div style={styles.titleHeader}>
          <h3 style={styles.titleHeaderContent}>
            Add Project
            <IconButton
              onClick={this.onCloseClick}
              style={styles.closeButton}
              iconStyle={styles.closeButtonIcon}
            >
              <ClearIcon />
            </IconButton>
          </h3>
        </div>
      )
      const startPeriodsActions = [
        <FlatButton
          key="startPeriodsCancel"
          label="Cancel"
          primary={true}
          onClick={this.startPeriodClose}
        />,
        <FlatButton
          key="startPeriodsAdd"
          label="Add"
          primary={true}
          onClick={this.startPeriodAdd}
        />,
      ]
      const preReqActions = [
        <FlatButton
          key="preReqCancel"
          label="Cancel"
          primary={true}
          onClick={this.preReqClose}
        />,
        <FlatButton
          key="preReqAdd"
          label="Add"
          primary={true}
          onClick={this.preReqAdd}
        />,
      ]
      const capabilityActions = [
        <FlatButton
          key="capabilityActionCancel"
          label="Cancel"
          primary={true}
          onClick={this.capabilityClose}
        />,
        <FlatButton
          key="capabilityActionAdd"
          label={this.state.capabilityButton}
          primary={true}
          onClick={this.capabilityAdd}
        />,
      ]

      let capabilityArray = this.injected.baselineStore.capabilities

      const simpleArray: any[] = []
      for (let i = 0; i < capabilityArray.length; i++) {
        simpleArray.push({
          id: '{"id":"' + capabilityArray[i].code + '","contribution":"0"}',
          name: capabilityArray[i].name,
        })
      }
      capabilityArray = simpleArray
      // use these values to check against changes
      return (
        <Dialog
          title={title}
          open={this.injected.uiStore.showAddProjectPopup}
          autoScrollBodyContent={true}
        >
          <div
            style={{
              paddingBottom: "5px",
              position: "relative",
              width: "700px",
            }}
          >
            <div
              style={{
                margin: "2.5px",
                marginBottom: "5px",
                padding: "5px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div style={styles.errorMessage}>{this.state.errorText}</div>
                <TextField
                  floatingLabelText={"Project Code"}
                  name="code"
                  value={this.Project.code}
                  onChange={this.handleChange}
                  underlineShow={false}
                  onBlur={this.codeCheck}
                />
                <TextField
                  floatingLabelText={"Name"}
                  name="name"
                  value={this.Project.name}
                  onChange={this.handleChange}
                  underlineShow={false}
                />
                <TextField
                  floatingLabelText={"Duration"}
                  name="duration"
                  value={this.Project.duration}
                  onChange={this.durationChange}
                  underlineShow={false}
                />

                <SelectField
                  floatingLabelText="Project Type"
                  onChange={this.onProjectTypeChange}
                  value={this.Project.typeId}
                >
                  {this.injected.baselineStore.types.map((type, typeIndex) => {
                    return (
                      <MenuItem
                        key={"type " + typeIndex}
                        value={type.id}
                        primaryText={type.name}
                      />
                    )
                  })}
                </SelectField>
                <SelectField
                  floatingLabelText="Mandatory"
                  onChange={this.onMandatoryChange}
                  value={this.Project.mandatory}
                >
                  <MenuItem
                    value={Mandatory.nonMandatory}
                    primaryText={"Non Mandatory"}
                  />
                  <MenuItem
                    value={Mandatory.Mandatory}
                    primaryText={"Mandatory"}
                  />
                  <MenuItem
                    value={Mandatory.Excluded}
                    primaryText={"Excluded"}
                  />
                </SelectField>
                <SelectField
              floatingLabelText="Status"
              value={this.Project.status}
              onChange={this.onStatusChange}
              autoWidth={true}
            >
              {statusList.map((stat: status, index: number) => (
                <MenuItem
                  key={index}
                  value={toNumber(stat)}
                  primaryText={projectStatusToString(stat)}
                />
              ))}

            </SelectField>
              </div>
            </div>
            <div
              style={{
                margin: "2.5px",
                marginBottom: "5px",
                padding: "5px",
                height: "100px",
              }}
            >
              <div style={styles.titleStyle}>Start Periods</div>
              <div style={styles.wrapper}>
                {this.Project.startPeriods.map((id, index) => {
                  const removeStartPeriodFn = () =>
                    this.removeStartPeriod(index)
                  return (
                    <Chip key={id} onRequestDelete={removeStartPeriodFn}>
                      {
                        this.injected.baselineStore.periods.find(
                          (p: any) => p.index === id
                        ).name
                      }
                    </Chip>
                  )
                })}
                <Chip onClick={this.openStartPeriod}>
                  <Avatar icon={<Add />} />
                  Add New
                </Chip>
              </div>
            </div>
            <div
              style={{
                margin: "2.5px",
                marginBottom: "5px",
                padding: "5px",
                height: "100px",
              }}
            >
              <div style={styles.titleStyle}>Prerequisites</div>
              <div style={styles.wrapper}>
                {this.Project.prerequisites.map((id, index) => {
                  const removePreRequisiteFn = () =>
                    this.removePreRequisite(index)
                  return (
                    <Chip key={id.name} onRequestDelete={removePreRequisiteFn}>
                      {id.name} : {id.type}
                    </Chip>
                  )
                })}
                <Chip onClick={this.openPrerequisites}>
                  <Avatar icon={<Add />} />
                  Add New
                </Chip>
              </div>
            </div>
            <div style={styles.titleStyle}>Project Resources</div>
            <div>
            {this.injected.scenariosStore.baseline.constraints.map((constraint, constraintIndex) => {
              let total = 0
              return (
                <div key={constraint.name + "_table"}>
                  <div style={styles.row}> {constraint.name}</div>
                  <Table>
                    <TableHeader
                      adjustForCheckbox={false}
                      displaySelectAll={false}
                    >
                      <TableRow>
                        {new Array(this.Project.duration)
                          .fill(0)
                          .map((d, index) => {
                            return (
                              <TableHeaderColumn
                                style={styles.tableColumn}
                                key={constraint.name + "_Period_" + (index + 1)}
                              >
                                Period {index + 1}
                              </TableHeaderColumn>
                            )
                          })}
                        <TableHeaderColumn style={styles.tableColumn}>
                          Total
                        </TableHeaderColumn>
                      </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                      <TableRow>
                        {this.Project.attributes[constraintIndex].values.map((value: number, subindex: number) => {
                          const onChange = (event: any) => {
                            this.constraintChange(constraint.name, subindex, event)
                          }
                          total += value
                          return (
                            <TableRowColumn
                              key={constraint.name + subindex}
                              style={styles.tableColumn}
                            >
                              <TextField
                                id={attribute.name + ":" + subindex}
                                value={value}
                                onChange={onChange}
                              />
                            </TableRowColumn>
                          )

                        }
                        )
                        }

                        <TableRowColumn
                          key={constraint.name + ".total"}
                          style={styles.tableColumn}
                        >
                          {total}
                        </TableRowColumn>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              )
            })}
          </div>
            <br />
            <div style={styles.titleStyle}>Project Benefit</div>
             <TextField
              underlineShow={false}
              floatingLabelText={"Benefit"}
              name="benefit"
              value={this.Project.benefits.values[0]}
              onChange={this.onBenefitChange}
            /> 
            <div
              style={{
                margin: "2.5px",
                marginBottom: "5px",
                padding: "5px",
              }}
            >
              <div
                style={{
                  height: "100px",
                }}
              >
                <div style={styles.titleStyle}> Capabilities</div> 
                <div style={styles.row}>Strategic Index: {StrategicIndex}</div>
                
                <div style={styles.wrapper}>
                  {this.Project.capabilities.map((cap, index) => {
                    const removeCapabilityFn = () =>
                      this.removeCapability(index)
                    const capabilityClickFn = () => this.capaiblityClick(cap.id)
                    return (
                      <Chip
                        key={cap.id}
                        onRequestDelete={removeCapabilityFn}
                        onClick={capabilityClickFn}
                      >
                        {this.injected.baselineStore.getCapabilityFromCode(
                          cap.id
                        ).name +
                          " : " +
                          cap.contribution}
                      </Chip>
                    )
                  })}
                  <Chip onClick={this.openCapability}>
                    <Avatar icon={<Add />} />
                    Add New
                  </Chip>
                </div>
              </div>
            </div>
            <SelectField
              onChange={this.oninitChange}
              value={this.Project.initiativeId}
              floatingLabelText={"Initiative"}
              style={{ width: "100%" }}
            >
              {this.injected.baselineStore.initiatives.map((y, index2) => {
                return (
                  <MenuItem
                    key={"option" + index2}
                    value={y.id}
                    primaryText={y.name}
                  />
                )
              })}
            </SelectField>
            <div>
              {"Strategy: " +
                this.injected.baselineStore.getStrategyFromId(
                  this.Project.strategyId
                ).name}
            </div>
          </div>

          <FlatButton onClick={this.addProjectToFrontEnd}>Add</FlatButton>
          {/**
           * Add start Period dialog
           */}
          <Dialog
            open={this.state.showAddStartPeriod}
            title={"Select Start Period"}
            actions={startPeriodsActions}
          >
            <SelectField
              floatingLabelText="Periods"
              onChange={this.onStartPeriodChange}
              value={this.state.currentStartPeriod}
              style={{ width: "100%" }}
              maxHeight={200}
            >
              {this.injected.baselineStore.periods.map(
                (period, periodIndex) => {
                  return (
                    <MenuItem
                      key={periodIndex}
                      value={period.index}
                      primaryText={period.name}
                    />
                  )
                }
              )}
            </SelectField>
          </Dialog> 
          {/**
           * Add Prerequisite dialog
           */}
          <Dialog
            open={this.state.showAddPrerequisites}
            title={"Select Prerequisite"}
            actions={preReqActions}
          >
            <SelectField
              floatingLabelText="Project"
              onChange={this.onPrerequisitesChange}
              value={this.state.currentPreReq}
              style={{ width: "100%" }}
            >
              {this.injected.scenariosStore.working.projects.map(
                (project, projectIndex) => {
                  return (
                    <MenuItem
                      key={projectIndex}
                      value={project.code}
                      primaryText={project.code + " : " + project.name}
                    />
                  )
                }
              )}
            </SelectField>
            <SelectField
              floatingLabelText="Prerequistite Type"
              onChange={this.onPrerequisitesTypeChange}
              value={this.state.currentPreReqType}
              style={{ width: "100%" }}
            >
              {Object.keys(Prerequisites).map((key) => {
                return (
                  <MenuItem
                    key={key}
                    value={key.toString()}
                    primaryText={key}
                  />
                )
              })}
            </SelectField>
          </Dialog>
            {/**
           * Add Capability dialog
           */}
          <Dialog
            open={this.state.showAddCapability}
            title={"Select Capability"}
            actions={capabilityActions}
          >
            <SelectField
              floatingLabelText="Capability"
              onChange={this.onSelectCapability}
              value={this.state.currentCapability.id}
              style={{ width: "100%" }}
            >
              {this.injected.baselineStore.capabilities.map(
                (capability, capabilityIndex) => {
                  return (
                    <MenuItem
                      key={capability.id}
                      value={capability.code}
                      primaryText={capability.name}
                    />
                  )
                }
              )}
            </SelectField>
            <TextField
              underlineShow={false}
              floatingLabelText={"Contribution"}
              name="benefit"
              value={this.state.currentCapability.contribution}
              onChange={this.onCapabilityValueChange}
            />
          </Dialog>
        </Dialog>
      )
    } else {
      return <div />
    }
  }
}

export default AddProjectPopup
