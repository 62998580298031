import * as React from 'react';
import * as localforage from 'localforage';
import { Route, Router, Redirect, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import PermissionStore, { User } from './mobx/PermissionStore';

import Login from './pages/Login';
import LoginCallBack from './pages/LoginCallBack';
// import Logout from './pages/Logout'
import { initializeAuth, isAuthenticated } from './auth/auth';

import Scenarios from './pages/Scenarios';
import ScenarioID from './pages/ScenarioID';
import Dashboard from './pages/Dashboard';
// import ScenarioComparison from './pages/ScenarioComparison';
import Home from './pages/Home';

import Header from './components/Header';
import Menu from './components/Menu';
import history from './auth/history';

export interface Props {}
export interface State {
  loggedIn: boolean;
  mounted: boolean;
  configloaded: boolean;
}
interface InjectedProps extends Props {
  permissionStore: PermissionStore;
}

const Auth = initializeAuth();

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore
  };
})
@observer
export class Routes extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps;
  }
  private isTokenReceived = false;

  constructor(props: Props) {
    super(props);
    this.state = { loggedIn: false, mounted: false, configloaded: false };
  }

  public async componentDidMount() {
    const { permissionStore } = this.injected;
    if (!permissionStore.loggedIn) {
      const loggedIn = await isAuthenticated();
      permissionStore.setLoggedIn(loggedIn);
    }
    if (permissionStore.loggedIn) {
      const user = (await localforage.getItem('user')) as User;
      permissionStore.setUser(user);
      permissionStore.setPermissions(user.profile.permissions);
      // await this.loadConfig()
    }
    this.setState({
      loggedIn: permissionStore.loggedIn,
      mounted: true
    });
  }
  private tokenParsed = () => {
    this.isTokenReceived = true;
  };

  private renderCallBack = (props: any) => {
    return (
      <LoginCallBack
        {...props}
        tokenParsed={this.tokenParsed}
        isTokenReceived={this.isTokenReceived}
        Auth={Auth}
      />
    );
  };

  private renderHome = () => {
    return <Home />;
  };

  private renderAuthenticated = (Component: JSX.Element) => {
    const { permissionStore } = this.injected;
    if (!permissionStore.loggedIn || !permissionStore.user) {
      return <Redirect to="/login" />;
    }

    return Component;
  };

  private renderDashboard = () => this.renderAuthenticated(<Dashboard />);

  // private renderScenarioComparison = props => {
  //   console.log(props);
  //   return (
  //     <ScenarioComparison
  //       {...props}
  //       PermissionStore={this.props.PermissionStore} />
  //   )
  // }

  public render(): JSX.Element {
    if (!this.state.mounted) {
      return <div />;
    }

    const AppRoutes = (): JSX.Element => {
      return (
        <Router history={history}>
          <div style={styles.mainWrapper}>
            <Header />
            <Menu />
            <Switch>
              <Route exact={true} path="/" render={this.renderHome} />
              <Route exact={true} path="/login" component={Login} />
              <Route
                exact={false}
                path="/callback"
                render={this.renderCallBack}
              />
              <Route path="/scenarios/:id" component={ScenarioID} />
              <Route exact path="/dashboard" render={this.renderDashboard} />
            </Switch>
          </div>
        </Router>
      );
    };
    return <AppRoutes />;
  }
}

const styles: { [index: string]: React.CSSProperties } = {
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: '#ececec'
  }
};

export default Routes;
