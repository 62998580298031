export const pieSectorPath = (
  centre: { x: number; y: number },
  rIn: any,
  rOut: any,
  startDeg: any,
  delta: any
) => {
  const startOut = {
    x: centre.x + rOut * Math.cos(Math.PI * startDeg / 180),
    y: centre.y + rOut * Math.sin(Math.PI * startDeg / 180)
  }

  const endOut = {
    x: centre.x + rOut * Math.cos(Math.PI * (startDeg + delta) / 180),
    y: centre.y + rOut * Math.sin(Math.PI * (startDeg + delta) / 180)
  }

  const startIn = {
    x: centre.x + rIn * Math.cos(Math.PI * (startDeg + delta) / 180),
    y: centre.y + rIn * Math.sin(Math.PI * (startDeg + delta) / 180)
  }

  const endIn = {
    x: centre.x + rIn * Math.cos(Math.PI * startDeg / 180),
    y: centre.y + rIn * Math.sin(Math.PI * startDeg / 180)
  }

  const largeArc = delta > 180 ? 1 : 0

  let path =
    'M' +
    startOut.x +
    ',' +
    startOut.y +
    ' A' +
    rOut +
    ',' +
    rOut +
    ' 0 ' +
    largeArc +
    ',1 ' +
    endOut.x +
    ',' +
    endOut.y +
    ' L' +
    startIn.x +
    ',' +
    startIn.y +
    ' A' +
    rIn +
    ',' +
    rIn +
    ' 0 ' +
    largeArc +
    ',0 ' +
    endIn.x +
    ',' +
    endIn.y +
    ' L' +
    startOut.x +
    ',' +
    startOut.y +
    ' Z'

  if (isNaN(startOut.x) || isNaN(startOut.y)) {
    path = 'M0,0Z'
  }

  if (isNaN(endOut.x) || isNaN(endOut.y)) {
    path = 'M0,0Z'
  }

  return path
}
