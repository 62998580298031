import * as React from "react"
import { observer, inject } from "mobx-react"

import { toJS } from "mobx"

import UiStore from "../mobx/UiStore"
import PermissionStore from "../mobx/PermissionStore"
import ScenariosStore from "../mobx/ScenariosStore"
import BaselineStore from "../mobx/BaselineStore"
import ConfigStore from "../mobx/ConfigStore"

import ResourceDial from "../components/RightSideComponents/ResourceDial"
import SelectField from "material-ui/SelectField"
import MenuItem from "material-ui/MenuItem"
import { NumberBeautify } from "../utils/NumberBeautify"
import FlatButton from "material-ui/FlatButton"
import TextField from "material-ui/TextField"
import Toggle from "material-ui/Toggle"
import { black } from "material-ui/styles/colors"
import { usageInTimeslot } from "../utils/mobxUtils"
import { PeriodSelector } from "../components/inputOptions/PeriodSelector"

export interface Props {}

export interface State {
  selectedProjectProperty?: string | undefined
  constraintLimit: string
  maxMode: boolean
  valueCoversRange: boolean
}

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  configStore: ConfigStore
}

@inject(({ store }) => {
  return {
    configStore: store.configStore,
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore
  }
})
@observer
export class ConstraintTab extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedProjectProperty: undefined,
      constraintLimit: "0",
      maxMode: true,
      valueCoversRange: false
    }
  }

  public componentDidMount() {
    const { baselineStore } = this.injected
    if (baselineStore.selectedConstraint === null) {
      baselineStore.setSelectedConstraint(baselineStore.constraints[0].id)
    }

    this.resetConstraint()
  }

  private constraintChange = (event: any, index: any, value: string) => {
    // const constraint = this.props.scenarioStore.working.constraints.find(
    //   checkConstraint => checkConstraint.id === this.props.ui.selectedConstraint
    // )
    // const mandatory = calulateMandatory(
    //   this.props.scenarioStore.working.projects,
    //   this.props.scenarioStore.working.constraints
    // )
    // const mandSingle = mandatory.find(c => c.name === constraint.name)
    const { baselineStore, uiStore } = this.injected
    const newConst = parseFloat(value)
    // if (mandSingle !== undefined && newConst < mandSingle.value) {
    //   newConst = mandSingle.value
    // }
    baselineStore.setSelectedConstraint(newConst)
    uiStore.selectedConstraint = newConst

    this.resetConstraint()
  }

  private resetConstraint = () => {
    const { scenariosStore, uiStore } = this.injected
    const constraint = scenariosStore.working.constraints.find(
      con => con.id === uiStore.selectedConstraint
    )

    this.setState({
      constraintLimit: constraint.value,
      maxMode: constraint.valueCoversRange === true,
      valueCoversRange: constraint.valueCoversRange
    })
  }

  private saveConstraint = () => {
    const { baselineStore, scenariosStore, uiStore } = this.injected
    const constraints = toJS(baselineStore.constraints)
    const TypeIndex = constraints.findIndex(
      item => item.id === baselineStore.selectedConstraint
    )

    const constraint = scenariosStore.working.constraints.find(
      con => con.id === uiStore.selectedConstraint
    )
    if (constraint !== undefined) {
      constraint.value = parseInt(this.state.constraintLimit, 0)
      constraint.valueCoversRange = this.state.valueCoversRange
    } else {
      console.error("Incorrect Costraint ID")
    }
    scenariosStore.edit()
  }

  private handleChange = (event: any) => {
    this.setState({ constraintLimit: event.target.value })
  }

  private valueCoversRangeChange = (event: any, isInputChecked: boolean) => {
    this.setState({ valueCoversRange: isInputChecked })
  }
  private showAllPeriodsChange = (event: any, isInputChecked: boolean) =>{
    this.injected.uiStore.showAllPeriods = isInputChecked
  }

  public render(): JSX.Element {
    if (this.injected.uiStore.selectedConstraint == null) {
      return <div />
    }

    const { uiStore, scenariosStore, baselineStore } = this.injected
    const periodArray = baselineStore.periods
    const { showHoverbox, hideHoverbox, changeHoverboxPosition, showAllPeriods } = uiStore
    const constraints = toJS(scenariosStore.working.constraints)

    const optimisedObject = toJS(scenariosStore.optimised)
    const projectArray = optimisedObject.projects

    const selectedProjectCode = toJS(scenariosStore.selectedProjectCode)
    const constraint = scenariosStore.working.constraints.find(
      checkConstraint => checkConstraint.id === uiStore.selectedConstraint
    )
    const projects = scenariosStore.working.projects
    const currentPeriod = scenariosStore.working.currentPeriod +1 // add one as its 1 indexed not 0 -- fix later
    let startPeriod = currentPeriod
    let constraintPeriodSelect = <div/>

    if (showAllPeriods){
      startPeriod = 1
    } else{
      // constraintPeriodSelect = <PeriodSelector({currentPeriod= 0})/>
      constraintPeriodSelect = <div/>
    } 


    let constraintTotal = 0
    let constraintMax = 0
    // TODO --- UPDATE TO scenarioStore Projects
    // TODO looks like usageInTimeslot is the same as in utils
    for (let i = 1; i <= periodArray.length; i++) {
      constraintTotal += usageInTimeslot(projects, i, constraint.name)
      constraintMax +=
        !this.state.valueCoversRange || i === 1
          ? parseInt(this.state.constraintLimit)
          : 0
    }



    return (
      <div
        style={{ paddingBottom: "5px", position: "relative", width: "600px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px"
          }}
        >
          <SelectField
            onChange={this.constraintChange}
            value={uiStore.selectedConstraint}
          >
            {scenariosStore.working.constraints.map((con, index) => {
              return (
                <MenuItem key={con.id} value={con.id} primaryText={con.name} />
              )
            })}
          </SelectField>

          {constraints.map(y => {
            if (uiStore.selectedConstraint === y.id) {
              return (
                <div id={"dialThis" + y.id} key={"dialThis" + y.id}>
                  <ResourceDial
                    size={400}
                    resource={"column" + y.id}
                    resourceIndex={y.name}
                    selectedProject={selectedProjectCode}
                    maxLimit={parseInt(y.value)}
                    projectArray={projectArray}
                    hideHoverbox={hideHoverbox}
                    showHoverbox={showHoverbox}
                    periods={periodArray}
                    changeHoverboxPosition={changeHoverboxPosition}
                    beginDisplayIndex={startPeriod}
                    endDisplayIndex={periodArray.length}
                    currentPeriod={currentPeriod}
                  />
                </div>
              )
            } else {
              return
            }
          })}
          <div style={{ marginTop: "10px" }}>
            {NumberBeautify.numberFormat(constraintTotal) +
              " out of " +
              NumberBeautify.numberFormat(constraintMax)}
          </div>
        </div>

        <TextField
          floatingLabelText="Constraint Limit"
          value={this.state.constraintLimit}
          onChange={this.handleChange}
          floatingLabelStyle={{ color: black }}
        />
        <Toggle
          label={"Value Covers Range"}
          toggled={this.state.valueCoversRange}
          onToggle={this.valueCoversRangeChange}
        />
        <Toggle
        label = {"Show all Periods"}
        toggled={showAllPeriods}
        onToggle={this.showAllPeriodsChange}
        />
        {constraintPeriodSelect}
        <FlatButton label="Undo" onClick={this.resetConstraint} />
        <FlatButton
          label="Update"
          primary={true}
          onClick={this.saveConstraint}
        />
      </div>
    )
  }
}
