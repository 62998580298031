import { observable } from "mobx"
import RootStore from "./RootStore"

// CapabilityMap
export type CapabilityMode = "heatmap" | "maturity" | "importanceLevel"
export type MaturityMode = "maturityTarget" | "maturityPortfolio"
export type DashboardSection =
  | "scenarios"
  | "dataimport"
  | "changebaseline"
  | "settings"
  | "comparison"
class UiStore {
  public rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public dashboardSectionSelected: DashboardSection = "scenarios"

  @observable public showProjectPropertiesPopup: boolean = false
  @observable public showScenarioSettingsDialog: boolean = false
  @observable public showAddProjectPopup: boolean = false
  @observable public showConstraintPopup: boolean = false
  @observable public showSensitivityAnalysisReport: boolean = false
  @observable public showSaveScenarioPopup: boolean = false
  @observable public showBaselineSelection: boolean = false
  @observable public showHoverBox: boolean = false
  @observable public showMenu: boolean = false
  @observable public configId: number = 1
  @observable public scenarioID: number = 0
  @observable public selectedConstraint: number = 1
  @observable public showAllPeriods: boolean = true
  @observable public constraintStartPeriod: number = 0
  @observable public RadarGraphGetSizeFrom: string = "Expenditure"
  @observable
  public RadarGraphDotCatagories: number[] = [
    100000,
    1000000,
    10000000,
    20000000
  ]

  @observable public CapabilityMapMode: CapabilityMode = "heatmap"
  @observable public CapabilityMapMaturiyMode: MaturityMode = "maturityTarget"
  @observable public capabilityMapValues: boolean = false
  @observable public capabilityMapResultant: boolean = false
  @observable public capabilityMapId: string = ""
  @observable public capabilityMapClicked: string = ""
  @observable public showProjectValue: boolean = false
  @observable public logoURL: string = ""

  public hoverBoxValue = { title: "", content: "", position: { x: 40, y: 40 } }
  public toggleMenu(): void {
    this.showMenu = !this.showMenu
  }

  public openShowSaveScenarioPopup = () => {
    this.showSaveScenarioPopup = true
  }
  public showHoverbox = (content: any, title: any = "") => {
    this.showHoverBox = true
    this.hoverBoxValue.content = content
    this.hoverBoxValue.title = title
  }
  public hideHoverbox = () => {
    this.showHoverBox = false
  }
  public changeHoverboxPosition = (x: number, y: number) => {
    this.hoverBoxValue.position.x = x
    this.hoverBoxValue.position.y = y
  }

  public setDashboardSectionSelected = (
    dashboardSectionSelected: DashboardSection
  ) => {
    this.dashboardSectionSelected = dashboardSectionSelected
  }
}

export default UiStore
