import Project, { Mandatory } from "../../mobx/Project"
import project from "ramda/es/project"

/**
 *finds the usage of a particular attribute across all periods
 *
 * @param projects list of projects to sum values from
 * @param resource attribute or benefit thats being counted
 * @param calculateForAllProjects controls if non-alive projects are counted, excluded projects are never counted
 */
export const projectUsage = (
  projects: Project[],
  resource: string,
  calculateForAllProjects = false
) => {
  let totalValue: number = 0

  const projectArray: any[] = []

  for (let i = 0; i < projects.length; i++) {
    let amount = 0
    if (
      (projects[i].alive || calculateForAllProjects) &&
      projects[i].mandatory !== Mandatory.Excluded
    ) {
      if (resource === projects[i].benefits.name || resource === "benefits") {
        amount = projects[i].getBenefit()
      } else {
        for (let a = 0; a < projects[i].duration; a++) {
          const attribute = projects[i].attributes.find(attr => attr.name === resource)
          amount += attribute ? attribute.values[a] || 0 : 0
        }
      }
    }
    totalValue += amount
    projectArray.push({
      project: projects[i].name,
      id: projects[i].code,
      amount,
    })
  }
  return { projectArray, totalValue }
}
