import * as React from 'react'

export interface Props {
  description: string
}

export class Description extends React.PureComponent<Props, {}> {
  constructor(props: Props) {
    super(props)
  }

  public render(): JSX.Element {
    const scenarioDecription = this.props.description || ''

    return (
      <div
        style={{
          overflowY: 'scroll',
          height: '200px',
          paddingLeft: '20px',
          paddingRight: '20px',
          flex: '0 1 200px',
          marginBottom: '20px',
          color: '#333',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center'
        }}
      >
        {scenarioDecription}
      </div>
    )
  }
}

export default Description
