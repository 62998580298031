export const pieDividingSegments = (center: any, segmentArray: any, scale: any) => {
  // calculate the path to outline varying pie segments even at irregular sizes
  // ask for the angle and the radius

  const pathArray: any[] = []

  for (let i = 0; i < segmentArray.length; i++) {
    const position = {
      x:
        segmentArray[i] *
        scale *
        Math.cos(Math.PI * (i * (360 / segmentArray.length) - 90) / 180),
      y:
        segmentArray[i] *
        scale *
        Math.sin(Math.PI * (i * (360 / segmentArray.length) - 90) / 180)
    }

    let path =
      'M' +
      center.x +
      ',' +
      center.y +
      ' L' +
      (position.x + center.x) +
      ',' +
      (position.y + center.y)

    if (!isFinite(position.x) || !isFinite(position.y)) {
      path = 'M0,0Z'
    }

    pathArray.push(path)
  }

  return pathArray
}
