import * as React from "react"
import { observer, inject } from "mobx-react"
import * as R from "ramda"

import { colourMap, colorDropDown } from "../../config"
import { MultiplierLevel } from "../../mobx/CapabilitiesStore"
import { BaselineSelection } from "../dashBoard/BaselineSelection"
import TextField from "material-ui/TextField"
import { saveConfig, saveLogo } from "../../utils/saveData"
// import { getLogos } from "../../utils/getData"

import ConfigStore from "../../mobx/ConfigStore"
import CapabilitiesStore from "../../mobx/CapabilitiesStore"
import PermissionStore from "../../mobx/PermissionStore"

import SelectField from "material-ui/SelectField"
import MenuItem from "material-ui/MenuItem"
import FlatButton from "material-ui/FlatButton"
import AddCircle from "material-ui/svg-icons/content/add-circle"
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table"
import IconButton from "material-ui/IconButton"

import CheckBox from "material-ui/Checkbox"
import Delete from "material-ui/svg-icons/action/delete"
import { LogoSelection } from "./logoSelection"

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  configStore: ConfigStore
  capabilityStore: CapabilitiesStore
}

export interface Props { }
export interface State {
}

@inject(({ store }) => {
  return {
    configStore: store.configStore,
    capabilityStore: store.capabilityStore,
    permissionStore: store.permissionStore,
  }
})
@observer
export class Settings extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }

  constructor(props: Props) {
    super(props)
    this.state ={
    }
  }

  private handleChange = (event: any, index: any, value: number) => {
    this.injected.configStore.maturityMap = value
    // this.setState({ maturityColourValue: value })
  }

  private handleHeatMapChange = (event: any, index: number) => {
    const { configStore } = this.injected
    let value = event.target.value
    if (event.target.name === "value") {
      value = parseInt(value)
    }
    switch (event.target.name) {
      case "label":
        configStore.heatMap[index].label = value
        break
      case "value":
        configStore.heatMap[index].value = value
        break
      case "color":
        configStore.heatMap[index].color = value
        break
      default:
        console.log("Uknown Case: " + event.target.name)
        break
    }
  }

  private heatMapOnSelectColorChange = (values: any, index: number) => {
    this.injected.configStore.heatMap[index].color = values
  }

  private heatMapDeleteRow = (index: number) => {
    const newHeatmap = R.remove(index, 1, this.injected.configStore.heatMap)
    this.injected.configStore.heatMap = newHeatmap
  }

  private heatMapAddTableRow = () => {
    const newHeatmap = R.append(
      {
        id: this.injected.configStore.heatMap.length,
        color: colorDropDown[0].color,
        value: 0,
        label: ""
      },
      this.injected.configStore.heatMap
    )

    this.injected.configStore.heatMap = newHeatmap
  }

  private handleSave = () => {
    this.saveConfig()
  }
  private saveConfig = async () => {
    const { configStore } = this.injected
    let configProps = {
      maturityMap: configStore.maturityMap,
      companyId: configStore.companyId,
      configId: configStore.configId,
      heatMap: configStore.heatMap,
      primaryLogo: configStore.primaryLogo,
      partnerLogo: configStore.partnerLogo
    }

    const config = {
      id: configStore.configId,
      companyID: configStore.companyId,
      config: JSON.stringify(configProps)
    }

    await saveConfig(config, this.injected.permissionStore.getToken())
  }

  
  private handleImportanceChange = (event: any, id: number) => {
    const { capabilityStore } = this.injected
    let value = event.target.value
    let multiplier = this.injected.capabilityStore.getMultiplierLevel(id)

    if (multiplier) {
      if (event.target.name === "multiplier") {
        value = parseInt(value)
      }
      switch (event.target.name) {
        case "name":
          multiplier.name = value
          break
        case "multiplier":
          multiplier.multiplier = value
          break
        default:
          console.log("Uknown Case: " + event.target.name)
          break
      }
      this.injected.capabilityStore.updateMultiplierLevel = multiplier
    }
  }

  private handleForceRefreshChange = (event: any, checked: boolean) => {
    this.injected.configStore.forceRefresh = checked
  }

  private ImportanceOnSelectColorChange = (values: any, id: number) => {
    let multiplier = this.injected.capabilityStore.getMultiplierLevel(id)
    if (multiplier) {
      multiplier.color = values
      this.injected.capabilityStore.updateMultiplierLevel = multiplier
    }
  }

  private ImportanceDeleteRow = (event: any, id: number) => {
    let multiplier = this.injected.capabilityStore.getMultiplierLevel(id)
    if (multiplier) {
      multiplier.deleted = true
      this.injected.capabilityStore.updateMultiplierLevel = multiplier
    }
  }

  private ImportanceAddTableRow = () => {
    // get new ID
    let LevelsList = this.injected.capabilityStore.multiplierLevelsList
    LevelsList.sort(function (a, b) {
      return a.id - b.id
    })
    let newID = LevelsList[LevelsList.length - 1].id + 1

    const newMultiplier: MultiplierLevel = {
      id: newID,
      color: "#ffffff",
      multiplier: 1,
      name: "new",
      overrided: false,
      deleted: false
    }

    this.injected.capabilityStore.updateMultiplierLevel = newMultiplier
  }

 

  public render(): JSX.Element {
    const { configStore, capabilityStore, permissionStore } = this.injected

    const saveButton =   <FlatButton
    key={"saveButton"}
    label="Save"
    primary={true}
    onClick={this.handleSave}
  />
    return (
      <div>
        <h3> Settings</h3>

        <h4>Force Refresh</h4>
        <div>When enabled this will force the recalculation of optimizations, overriding any existing optimizations stored in the DataBase</div>
        <CheckBox
        label="Force Refresh"
        checked = {configStore.forceRefresh}
        onCheck={this.handleForceRefreshChange}
        />

        <h4>Maturity Map Colours</h4>
        <SelectField
          value={configStore.maturityMap}
          onChange={this.handleChange}
          autoWidth={true}
          style={{ width: 300 }}
        >
          {colourMap.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={index}
                primaryText={item.map((subItem, subIndex) => {
                  return (
                    <svg width="40" height="35" key={subIndex}>
                      <rect
                        width="40"
                        height="35"
                        style={{ fill: subItem, strokeWidth: 0 }}
                      />
                    </svg>
                  )
                })}
                checked={index === configStore.maturityMap}
                insetChildren={true}
              />
            )
          })}
        </SelectField>
        {saveButton}
        <h4>HeatMap Settings</h4>
        {/*  */}
        <Table>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableHeaderColumn>Colour</TableHeaderColumn>
            <TableHeaderColumn>Value</TableHeaderColumn>
            <TableHeaderColumn>Label</TableHeaderColumn>
            <TableHeaderColumn>
              <FlatButton
                label="Add Row"
                onClick={this.heatMapAddTableRow}
                icon={<AddCircle />}
              />
            </TableHeaderColumn>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {configStore.heatMap.map((rowData, rowIndex) => {
              const deleteRowFn = () => {
                this.heatMapDeleteRow(rowIndex)
              }
              const onSelectColorChangeFn = (
                event: any,
                index: any,
                values: any
              ) => {
                this.heatMapOnSelectColorChange(values, rowIndex)
              }
              const handleHeatMapChangeFn = (event: any) => {
                this.handleHeatMapChange(event, rowIndex)
              }
              return (
                <TableRow key={rowIndex}>
                  <TableRowColumn>
                    <SelectField
                      value={rowData.color}
                      underlineStyle={{ display: "none" }}
                      onChange={onSelectColorChangeFn}
                      style={{
                        padding: "5px"
                      }}
                    >
                      {colorDropDown.map((color, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={color.color}
                            primaryText={
                              <svg
                                style={{ marginTop: 10 }}
                                width="30"
                                height="30"
                              >
                                <rect
                                  width="30"
                                  height="30"
                                  style={{
                                    fill: color.color,
                                    strokeWidth: 0
                                  }}
                                />
                              </svg>
                            }
                            checked={color.color === rowData.color}
                            insetChildren={true}
                          />
                        )
                      })}
                    </SelectField>
                  </TableRowColumn>
                  <TableRowColumn>
                    <TextField
                      underlineShow={false}
                      value={rowData.value}
                      name={"value"}
                      type="number"
                      onChange={handleHeatMapChangeFn}
                    />
                  </TableRowColumn>
                  <TableRowColumn>
                    <TextField
                      underlineShow={false}
                      value={rowData.label}
                      name={"label"}
                      onChange={handleHeatMapChangeFn}
                    />
                  </TableRowColumn>
                  <TableRowColumn>
                    <IconButton onClick={deleteRowFn}>
                      <Delete />
                    </IconButton>
                  </TableRowColumn>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {saveButton}
        <h4>Importance Level Settings</h4>
        {/*  */} 
        <Table>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableHeaderColumn>Colour</TableHeaderColumn>
            <TableHeaderColumn>Multiplier</TableHeaderColumn>
            <TableHeaderColumn>Name</TableHeaderColumn>
            <TableHeaderColumn>
              <FlatButton
                label="Add Row"
                onClick={this.ImportanceAddTableRow}
                icon={<AddCircle />}
              />
            </TableHeaderColumn>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {capabilityStore.multiplierLevelsList.map((rowData, rowIndex) => {
              const deleteRowFn = (event: any) => {
                this.ImportanceDeleteRow(event, rowData.id)
              }
              const onSelectColorChangeFn = (
                event: any,
                index: any,
                values: any
              ) => {
                this.ImportanceOnSelectColorChange(values, rowData.id)
              }
              const handleChangeFn = (event: any) => {
                this.handleImportanceChange(event, rowData.id)
              }
              return (
                <TableRow key={rowIndex}>
                  <TableRowColumn>
                    <SelectField
                      value={rowData.color}
                      underlineStyle={{ display: "none" }}
                      onChange={onSelectColorChangeFn}
                      style={{
                        padding: "5px"
                      }}
                    >
                      {colorDropDown.map((color, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={color.color}
                            primaryText={
                              <svg
                                style={{ marginTop: 10 }}
                                width="30"
                                height="30"
                              >
                                <rect
                                  width="30"
                                  height="30"
                                  style={{
                                    fill: color.color,
                                    strokeWidth: 0
                                  }}
                                />
                              </svg>
                            }
                            checked={color.color === rowData.color}
                            insetChildren={true}
                          />
                        )
                      })}
                    </SelectField>
                  </TableRowColumn>
                  <TableRowColumn>
                    <TextField
                      underlineShow={false}
                      value={rowData.multiplier}
                      name={"multiplier"}
                      type="number"
                      onChange={handleChangeFn}
                    />
                  </TableRowColumn>
                  <TableRowColumn>
                    <TextField
                      underlineShow={false}
                      value={rowData.name}
                      name={"name"}
                      onChange={handleChangeFn}
                    />
                  </TableRowColumn>
                  <TableRowColumn>
                    <IconButton onClick={deleteRowFn}>
                      <Delete />
                    </IconButton>
                  </TableRowColumn>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {saveButton}
        <LogoSelection/>
        {saveButton}
        <BaselineSelection />
      </div>
    )
  }
}

export default Settings
