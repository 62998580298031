import * as React from 'react'
import { initializeAuth, login } from '../auth/auth'

export class Login extends React.Component {
  private doAuth = () => {
    const auth = initializeAuth()
    login(auth)
  }

  public render() {
    this.doAuth()
    return null
  }
}

export default Login
