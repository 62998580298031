export const arcLinePath = (
  centre: { x: number; y: number },
  rOut:any,
  startDeg:any,
  delta:any
) => {
  let path = 'M0 0 Z'

  if (centre.x + rOut !== 0 && centre.y + rOut !== 0) {
    const startOut = {
      x: centre.x + rOut * Math.cos(Math.PI * startDeg / 180),
      y: centre.y + rOut * Math.sin(Math.PI * startDeg / 180)
    }

    const endOut = {
      x: centre.x + rOut * Math.cos(Math.PI * (startDeg + delta) / 180),
      y: centre.y + rOut * Math.sin(Math.PI * (startDeg + delta) / 180)
    }

    const largeArc = delta > 180 ? 1 : 0

    path =
      'M' +
      startOut.x +
      ',' +
      startOut.y +
      ' A' +
      rOut +
      ',' +
      rOut +
      ' 0 ' +
      largeArc +
      ',1 ' +
      endOut.x +
      ',' +
      endOut.y
  }

  return path
}
export const arcLinePath2 = (
  centre: { x: number; y: number },
  rOut:any,
  startDeg:any,
  delta:any,
  isItNew: boolean
) => {
  let path = 'M0 0 Z'

  let lineStart = 'M'

  if (!isItNew) {
    lineStart = 'L'
  }

  const startOut = {
    x: centre.x + rOut * Math.cos(Math.PI * startDeg / 180),
    y: centre.y + rOut * Math.sin(Math.PI * startDeg / 180)
  }

  const endOut = {
    x: centre.x + rOut * Math.cos(Math.PI * (startDeg + delta) / 180),
    y: centre.y + rOut * Math.sin(Math.PI * (startDeg + delta) / 180)
  }

  const largeArc = delta > 180 ? 1 : 0

  path =
    lineStart +
    startOut.x +
    ',' +
    startOut.y +
    ' A' +
    rOut +
    ',' +
    rOut +
    ' 0 ' +
    largeArc +
    ',1 ' +
    endOut.x +
    ',' +
    endOut.y

  if (!isFinite(startOut.x) || !isFinite(startOut.y)) {
    path = lineStart + 0 + ',' + 0
  }

  return path
}
