import * as React from 'react';

import { observer, inject } from 'mobx-react';

import UiStore from '../mobx/UiStore';
import PermissionStore from '../mobx/PermissionStore';
import ScenariosStore from '../mobx/ScenariosStore';
import BaselineStore from '../mobx/BaselineStore';
import ConfigStore from '../mobx/ConfigStore';

import { getSolutions, ScenarioToOptimise } from '../utils/ProjectSolution';

import { Tabs, Tab } from 'material-ui/Tabs';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import ClearIcon from 'material-ui/svg-icons/content/clear';
import { ScenarioSettingsTab } from './ScenarioSettingsTab';
import { EfficientFrontierTab } from './EfficientFrontierTab';
import { ConstraintTab } from './ConstraintTab';
import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';

export interface Props {}
export interface State {
  tab: string;
  optimizing: boolean;
  errorDialogOpen: boolean;
}

interface InjectedProps extends Props {
  permissionStore: PermissionStore;
  uiStore: UiStore;
  scenariosStore: ScenariosStore;
  baselineStore: BaselineStore;
  configStore: ConfigStore;
}

@inject(({ store }) => {
  return {
    configStore: store.configStore,
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore
  };
})
@observer
export class ScenarioSettingsDialog extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps;
  }
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: 'settings',
      optimizing: false,
      errorDialogOpen: false
    };
  }

  private onDialogCloseBtnClick = () => {
    this.injected.uiStore.showScenarioSettingsDialog = false;
  };
  private onTabActive = (tab: Tab) => {
    this.setState({ tab: tab.props.value });
  };
  public onOptimisePressed = () => {
    // TODO display some graphic while running
    this.setState({ optimizing: true });
    this.optimiseScenario();
  };

  // /**
  //  * I belive this limits time periods to 12? this means this probably needs to be removed
  //  * @param condata the data to be restricted
  //  */
  // private conRestrict(condata) {
  //   const parsedConstraints = condata.map(c => {
  //     const obj = {
  //       ...c,
  //       value: parseInt(c.value, 0)
  //     }
  //     return obj
  //   })
  //   return parsedConstraints
  // }

  private optimiseScenario = async () => {
    const { permissionStore, scenariosStore, baselineStore, configStore } = this.injected;
    try {
      let solutionArray: any[] = [];

      const periods = baselineStore.periods.length;
      const authToken = permissionStore.getToken();
      const canOptimise = permissionStore.canOptimise()
      
    if(!canOptimise){
      return
    }

      const solutionArrayItem = await ScenarioToOptimise(
        scenariosStore.working,
        authToken,
        periods,
        configStore.forceRefresh
      );
      solutionArray.push(solutionArrayItem);
      

      solutionArray = await getSolutions(solutionArray, authToken);
      solutionArray = solutionArray.map(item => {
        const solution = item.status === 'error' ? 'infeasable' : item.solution;
        return {
          jobId: item.jobId,
          status: item.status,
          solution
        };
      });
      scenariosStore.setOptimised(solutionArray);
    } catch (error) {
      this.setState({ errorDialogOpen: true });
      console.log("Error while optimizing")
      console.log(error)
    }
    this.setState({ optimizing: false });
  };

  private handleClose = () => {
    this.setState({ errorDialogOpen: false });
  };
  private tabActions = (tab: string): JSX.Element[] => {
    
    const canOptimise = this.injected.permissionStore.canOptimise()
    if (tab === 'settings' || tab === 'constraints') {
      return [
        <FlatButton
          key={'optimiseScenarioButton'}
          label="Optimise Scenario"
          primary={true}
          onClick={this.onOptimisePressed}
          disabled={!canOptimise}
        />
      ];
    }

    return [];
  };

  private tabContent = (tab: string): JSX.Element => {
    if (this.state.optimizing === true) {
      return (
        <div>
          <div>
            <CircularProgress size={50} />
          </div>
          <div>Please Wait...</div>
        </div>
      );
    } else if (tab === 'settings') {
      return <ScenarioSettingsTab />;
    } else {
      if (tab === 'constraints') {
        return <ConstraintTab />;
      } else {
        return <EfficientFrontierTab />;
        // return (
        //   <EfficientFrontierTab
        //     permissionStore={this.props.permissionStore}
        //     ScenarioStore={this.props.ScenarioStore}
        //     PeriodArray={this.props.periodArray}
        //   />
        // )
      }
    }
  };

  public render() {
    const { scenariosStore, uiStore } = this.injected;

    const selectedScenario = scenariosStore.working.name;

    const title = (
      <div className={'title'}>
        <div style={styles.titleHeader}>
          <h3 style={styles.titleHeaderContent}>
            Scenario Settings - {selectedScenario}
            <IconButton
              onClick={this.onDialogCloseBtnClick}
              style={styles.closeButton}
              iconStyle={styles.closeButtonIcon}
            >
              <ClearIcon />
            </IconButton>
          </h3>
        </div>
        <div style={styles.titleContent}>
          <Tabs
            tabItemContainerStyle={styles.tabItemContainer}
            value={this.state.tab}
            style={styles.titleSubHeader}
            inkBarStyle={styles.inkBarStyle}
          >
            <Tab
              value="settings"
              buttonStyle={styles.tab}
              label="Settings"
              onActive={this.onTabActive}
            />
            <Tab
              value="constraints"
              buttonStyle={styles.tab}
              label="Constraints"
              onActive={this.onTabActive}
            />
            <Tab
              value="efficient frontier"
              buttonStyle={styles.tab}
              label="Efficient Frontier"
              onActive={this.onTabActive}
            />
          </Tabs>
        </div>
      </div>
    );

    const actions = [
      <FlatButton
        key="settingsClose"
        label="Close"
        primary={true}
        onClick={this.handleClose}
      />
    ];

    return (
      <Dialog
        title={title}
        modal={false}
        autoDetectWindowHeight={false}
        open={uiStore.showScenarioSettingsDialog}
        autoScrollBodyContent={true}
        titleStyle={styles.title}
        bodyStyle={styles.dialogBody}
        actions={this.tabActions(this.state.tab)}
      >
        {this.tabContent(this.state.tab)}
        <Dialog
          title={'Error Optimizing'}
          open={this.state.errorDialogOpen}
          onRequestClose={this.handleClose}
          actions={actions}
        >
          There was an error while optimizing, Please try again
        </Dialog>
      </Dialog>
    );
  }
}

const styles: { [key: string]: React.CSSProperties } = {
  closeButton: {
    padding: 0
  },
  closeButtonIcon: {
    width: 36,
    height: 36,
    color: 'white'
  },
  dialogBody: {
    minHeight: '65vh',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    backgroundColor: '#FFF',
    color: 'white',
    padding: 0
  },
  titleHeader: {
    backgroundColor: '#5859BC',
    color: 'white',
    padding: 0,
    flex: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleContent: {
    flex: 1,
    width: '100%'
  },

  titleHeaderContent: {
    backgroundColor: '#5859BC',
    fontSize: '1em',
    color: 'white',
    padding: '0.75rem',
    margin: 0,
    flex: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleSubHeader: {
    backgroundColor: '#FFF',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    color: 'white',
    padding: 0,
    flex: 1,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
};
