export const createCircle = (
  snapElement: any,
  diameter: number,
  padding: number,
  fill: string
) => {
  const circle = snapElement.circle(
    diameter / 2 + padding,
    diameter / 2 + padding,
    diameter / 2
  )

  circle.attr({
    fill,
    stroke: '#ddd',
    strokeWidth: 1
  })

  return circle
}
