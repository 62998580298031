import * as R from 'ramda';
import { colourMap } from '../../config';
import { ProjectType } from './interfaces';

const parseProjectTypes = ({ data }: { data: any[] }) => {

    try {
      const parsedData = data.filter(row => row.type_id && row.type_name);
      if (parsedData.length !== data.length) {
        return;
      }
    
      const projectTypes: ProjectType[] = data.map(
        (row: { type_id: any; type_name: any }, index: number) => {
          const item = {
            id: row.type_id,
            name: row.type_name,
            color: R.drop(1, colourMap[index][2])
          };
    
          return item;
        }
      );
      return { projectTypes };
    } catch (error) {
      return
    }

 
};

export { parseProjectTypes };
