declare var window: any

export const createSnap = (targetElement: string) => {
  const snap = window.Snap('#' + targetElement)
  const snapObject = {
    snap,
    customProperties: {}
  }
  return snapObject
}
export const createUniqueID = () => {
  let text = ''
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 8; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return 'snapComponent-' + text
}
