import * as React from "react"
import { Link, withRouter, RouteComponentProps } from "react-router-dom"
import { observer, inject } from "mobx-react"

import Drawer from "material-ui/Drawer"
import { List, ListItem } from "material-ui/List"
import Divider from "material-ui/Divider"

import PermissionStore from "../../mobx/PermissionStore"
import UiStore from "../../mobx/UiStore"
import ScenariosStore from "../../mobx/ScenariosStore"

import { logout } from "../../auth/auth"
import { version } from "process"
import { grey100, green400, grey400 } from "material-ui/styles/colors"
import { userInfo } from "os"

interface RouteParams {
  id: string
}
interface Props extends RouteComponentProps<RouteParams> { }

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  scenariosStore: ScenariosStore
  uiStore: UiStore
}

const menuItems = [
  {
    linkTo: "dashboard",
    label: "Dashboard",
    permission: "showDataImportView"
  }
]

const scenarioMenuItems = [
  {
    linkTo: "radar",
    label: "Scenario Radar View"
  },
  {
    linkTo: "gantt",
    label: "Scenario Gantt View"
  },
  {
    linkTo: "capabilitymap",
    label: "Capability Map"
  }
]
const styles: { [key: string]: React.CSSProperties } = {
  drawer: {
    height: "calc(100% - 50px )",
    marginTop: 50
  },
  link: {
    textDecoration: "none"
  },
  version: {
    position: "absolute",
    bottom: 0,
    color: grey400,
    textTransform: "uppercase"

  }
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    scenariosStore: store.scenariosStore,
    uiStore: store.uiStore
  }
})
@observer
class Menu extends React.Component<Props, {}> {
  private get injected() {
    return this.props as InjectedProps
  }
  public render(): JSX.Element {
    const isScenario = this.props.location.pathname.includes("/scenarios/")
    const scenarioId = this.injected.scenariosStore.getScenarioId()
    const scenarioMenuItemsPath = `/scenarios/${scenarioId}/`
    const permissions = this.injected.permissionStore

    const { uiStore } = this.injected
    const handleToggle = () => uiStore.toggleMenu()
    const btlogout = () => {
      permissions.loggedIn = false
      permissions.user = null
      handleToggle()
      logout()
    }
    let welcome =<div/>
    if(permissions.user != null){
      let name = permissions.user.profile.name
      welcome = <List><div style={{paddingLeft: 16}}>Welcome {name.toString()} </div>
      <div style={{paddingLeft: 16,
    color: grey400, fontSize: 10}}>{permissions.user.profile.roles.toString()}</div>
       </List>
    }

    return (
      <Drawer
        containerStyle={styles.drawer}
        width={380}
        openSecondary={true}
        open={uiStore.showMenu}
        onRequestChange={handleToggle}
        docked={false}
      >
        {welcome}
        <Divider />
        
        
        <List>
          {menuItems.map((item, index) => {
            return (
              <Link
                key={index}
                style={styles.link}
                onClick={handleToggle}
                to={`/${item.linkTo}`}
              >
                <ListItem primaryText={item.label} />
              </Link>
            )
          })}
        </List>
        {isScenario && <Divider />}
        {isScenario && (
          <List>
            {scenarioMenuItems.map((item, index) => {
              return (
                <Link
                  key={index}
                  style={styles.link}
                  onClick={handleToggle}
                  to={`${scenarioMenuItemsPath}${item.linkTo}`}
                >
                  <ListItem primaryText={item.label} />
                </Link>
              )
            })}
          </List>
        )}
        <Divider />
        <List>
          <Link style={styles.link} onClick={btlogout} to="/">
            <ListItem primaryText="Logout" />
          </Link>
        </List>
        <div style={styles.version}>
          v 0.3.1
        </div>
      </Drawer>
    )
  }
}

export default withRouter(Menu)
