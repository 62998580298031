import * as React from "react"
import { observer, inject } from "mobx-react"
import {
  withRouter,
  Route,
  Redirect,
  RouteComponentProps
} from "react-router-dom"

import UiStore from "../mobx/UiStore"
import PermissionStore from "../mobx/PermissionStore"
import ScenariosStore from "../mobx/ScenariosStore"
import BaselineStore from "../mobx/BaselineStore"
import { ScenarioProps } from "../mobx/Scenario"
import CapabilityStore from "../mobx/CapabilitiesStore"

import { RadarView } from "./RadarView"
import { GanttView } from "./GanttView"
import CapabilityMap from "./CapabilityMap"
// import { SettingsView } from "./SettingsView"
import CircularProgress from "material-ui/CircularProgress"

import {
  getScenario,
  getGlobalData,
  getCapabilities,
  getMaturityLevels
} from "../utils/getData"
import ProjectSettingsDialog from "../dialogs/ProjectSettingsDialog"
import { ScenarioSettingsDialog } from "../dialogs/ScenarioSettingsDialog"
import AddProjectDialog from "../dialogs/AddProjectDialog"

import LeftSideBar from "../components/LeftSideBar"
import HoverBox from "../components/HoverBox"

import SaveScenarioDialog from "../dialogs/SaveScenarioDialog"
import { loadAndFormatBaseline, loadAndFormatScenario } from "../utils/loadData"

interface State {
  mounted: boolean
}

interface RouteParams {
  id: string
}
type Props = RouteComponentProps<RouteParams>

export interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  capabilityStore: CapabilityStore
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore,
    capabilityStore: store.capabilityStore
  }
})
@observer
export class ScenarioID extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      mounted: false
    }
  }

  public async componentDidMount() {
    const { uiStore } = this.injected
    const {
      match: {
        params: { id }
      }
    } = this.props
    const idInt = parseInt(id, 10)
    uiStore.scenarioID = idInt
    await this.loadData()
    this.setState({ mounted: true })
  }

  public loadData = async () => {
    const {
      permissionStore,
      scenariosStore,
      baselineStore,
      capabilityStore
    } = this.injected
    const token = permissionStore.getToken()
    //get and set scenario
    const scenario = await loadAndFormatScenario(this.props.match.params.id, token)
    scenariosStore.setScenario(scenario)
    //get and set baseline
    const baseline = await loadAndFormatBaseline(token)
    baselineStore.setBaseline(baseline)

    scenariosStore.reCalulateStrategicIndex("baseline")
    scenariosStore.reCalulateStrategicIndex("working")
  }

  public loadScenario = async () => {
    const {
      permissionStore,
      scenariosStore,
      capabilityStore,
      baselineStore
    } = this.injected
    const token = permissionStore.getToken()

    const newScenario = await loadAndFormatScenario(this.props.match.params.id, token)
    scenariosStore.setScenario(newScenario)

    scenariosStore.reCalulateStrategicIndex("working")
  }

  private renderScenario = () => {
    return (
      <Redirect
        to={{
          pathname: `/scenarios/${this.props.match.params.id}/Radar`
        }}
      />
    )
  }

  public render(): JSX.Element {
    if (!this.state.mounted) {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <CircularProgress size={200} thickness={7} />
        </div>
      )
    }

    return (
      <div style={styles.columnsWrapper}>
        <div style={styles.leftWrapper}>
          <LeftSideBar reloadData={this.loadScenario} open={true} />
        </div>
        <div style={styles.rightWrapper}>
          <Route
            exact={true}
            path={"/scenarios/:id"}
            render={this.renderScenario}
          />
          <Route path={`/scenarios/:id/Radar`} component={RadarView} />
          <Route path={`/scenarios/:id/Gantt`} component={GanttView} />
          <ScenarioSettingsDialog />
          {/* <Route path={`/scenarios/:id/Settings`} component={SettingsView} /> */}
          <Route
            path={`${this.props.match.path}/capabilityMap`}
            component={CapabilityMap}
          />
          <ProjectSettingsDialog />
          <AddProjectDialog />
          <SaveScenarioDialog />
          <HoverBox />
        </div>
      </div>
    )
  }
}
const styles: { [key: string]: React.CSSProperties } = {
  columnsWrapper: {
    marginBottom: 0,
    display: "flex",
    flex: "0 1 100%",
    overflowY: "hidden"
  },
  leftWrapper: {
    flex: "0 0 400px",
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden"
  },
  rightWrapper: {
    flex: "0 1 100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden"
  }
}

export default withRouter(ScenarioID)
