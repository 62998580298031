import { CSSProperties } from 'react';

const styles: { [key: string]: CSSProperties } = {
    root: {
      marginBottom: 0,
      display: 'flex',
      flex: '0 0 100%',
      overflowY: 'hidden',
      background: '#FFF'
    },
    banner: {
        margin: 'auto',
        marginTop: 200,
        width: 450,
        textAlign: 'center'
    },
    bannerImage: {
      width: 450,
      height: '100%',
      marginBottom: 40
    },
    button: {
      width: 200,
      clear: 'both'
    }
  };
export default styles;