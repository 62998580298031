export var NumberBeautify = {
  // Note: look into Numeral JS for this functionaly
  /**
   * Formats numbers to a string format rounding to catagory (Billion, Million, Thousand, number) with a set number of decimal places
   * altered from https://stackoverflow.com/questions/12900332/how-do-i-round-millions-and-thousands-in-javascript
   * @param input the number to be formated
   * @param decimals the number of decimal places on the number, default: 0
   */
  numberFormat: function(input: number, decimals: number = 0) {
    let parsedInput = Math.abs(Number(input))

    // Nine Zeroes for Billions
    if (parsedInput >= 1.0e9) {
      return (input / 1.0e9).toFixed(decimals) + " B"
    }
    // Six Zeroes for Millions
    else if (parsedInput >= 1.0e6) {
      return (input / 1.0e6).toFixed(decimals) + " M"
    }
    // Three Zeroes for Thousands
    else if (parsedInput >= 1.0e3) {
      return (input / 1.0e3).toFixed(decimals) + " K"
    } else {
      return input.toFixed(decimals)
    }
  }
}

// /**
//  * Formats numbers to a string format rounding to catagory (Billion, Million, Thousand, number) with a set number of decimal places
//  * altered from https://stackoverflow.com/questions/12900332/how-do-i-round-millions-and-thousands-in-javascript
//  * @param input the number to be formated
//  * @param decimals the number of decimal places on the number, default: 0
//  * @param hundredDecimal if numbers that arnt shortend should have a fixed number of decimals
//  */
// export function NumberFormat(
//   input: number,
//   decimals: number = 0,
//   hundredDecimal: boolean = false
// ) {
//   // Nine Zeroes for Billions
//   return Math.abs(Number(input)) >= 1.0e9
//     ? (Math.abs(Number(input)) / 1.0e9).toFixed(decimals) + "B"
//     : // Six Zeroes for Millions
//     Math.abs(Number(input)) >= 1.0e6
//     ? (Math.abs(Number(input)) / 1.0e6).toFixed(decimals) + "M"
//     : // Three Zeroes for Thousands
//     Math.abs(Number(input)) >= 1.0e3
//     ? (Math.abs(Number(input)) / 1.0e3).toFixed(decimals) + "K"
//     : hundredDecimal === true
//     ? Math.abs(Number(input)).toFixed(decimals)
//     : Math.abs(Number(input))
// }

export default NumberBeautify.numberFormat
