import * as React from 'react'
import { ProjectCircle } from '../../mobx/ConfigStore'
import { toJS } from 'mobx'

export interface Props {
  Catagories: ProjectCircle[]
  getSizeFrom: string
}

class CostLegend extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
  }

  public render(): JSX.Element {
    const Catagories = toJS(
      this.props.Catagories.slice().sort((a, b) => {
        return a.min - b.min
      })
    )
    const returnItems = (
      <div style={{ padding: '10px', backgroundColor: 'none' }}>
        <div style={{ paddingBottom: '2px', fontSize: '110%' }}>
          {'PROJECT ' + this.props.getSizeFrom.toUpperCase()}
        </div>
        <div style={styles.KeyHolder}>
          {Catagories.map((item: ProjectCircle, index: number) => {
            const width = 'calc(' + item.size + 'px * 2)'
            const height = 'calc(' + item.size + 'px * 2)'
            // const displayValue = NumberBeautify.shorten(item)
            return (
              <div
                key={`costItem${item.size}`}
                id={`cost${item.size}`}
                style={styles.ItemContainer}
              >
                <div
                  style={{
                    ...styles.Item,
                    width,
                    height
                  }}
                />
                <div style={{ marginTop: 5 }}>{item.label}</div>
              </div>
            )
          })}
        </div>
      </div>
    )

    return returnItems
  }
}

export const styles: { [key: string]: React.CSSProperties } = {
  KeyHolder: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  ItemContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '80%',
    marginBottom: '3px',
    marginRight: '20px'
  },
  Item: {
    backgroundColor: '#656565',
    borderRadius: '100px',
    border: 'solid 1px white',
    marginRight: '4px',
    flexShrink: 0
  }
}

export default CostLegend
