import * as React from "react"

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Label
} from "recharts"
import numberFormat from "../utils/NumberBeautify"
import { isUndefined } from "util"

export interface Props {
  width: number
  height: number
  yAxisDataKey: string | undefined
  yAxisLabel?: string
  xAxisDataKey: string
  xAxisLabel?: string
  chartData: any[]
  CustomToolTip: any
  lines: Array<{
    dataKey: string
    name: string
    stroke: string | null | undefined
  }>
  drop?: number
}

// tslint:disable-next-line:no-empty-interface
export interface State {}

const TickFormatter = (props: any) => {
  return numberFormat(Number(props), 1)
}

class CustomLineCharts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  public static defaultProps: Partial<Props> = {
    yAxisLabel: "",
    xAxisLabel: "",
    drop: -1
  }

  public render(): JSX.Element {
    const {
      xAxisDataKey,
      yAxisDataKey,
      yAxisLabel,
      xAxisLabel,
      width,
      height,
      CustomToolTip,
      lines,
      drop
    } = this.props

    let dropped = <div />
    if (isUndefined(drop) || drop >= 0) {
      dropped = <ReferenceLine x={drop} stroke="red" strokeDasharray="3 3" />
    }
    return (
      <LineChart
        width={width}
        height={height}
        data={this.props.chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
      >
        <XAxis
          dataKey={xAxisDataKey}
          minTickGap={5}
          tickFormatter={TickFormatter}
        >
          <Label value={xAxisLabel} offset={0} position="insideBottom" />
        </XAxis>
        <YAxis
          dataKey={yAxisDataKey}
          minTickGap={5}
          tickFormatter={TickFormatter}
        >
          <Label
            value={yAxisLabel}
            offset={-10}
            position="insideLeft"
            angle={-90}
          />
        </YAxis>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={CustomToolTip} />
        <Legend verticalAlign="top" />
        {lines.map((line: any, index: number) => {
          return (
            <Line
              key={index}
              type="monotone"
              dataKey={line.dataKey}
              name={line.name}
              stroke={line.stroke}
              activeDot={{ r: 8 }}
            />
          )
        })}
        {dropped}
      </LineChart>
    )
  }
}

export default CustomLineCharts
