import { observable, computed, toJS } from "mobx"
import { isUndefined } from "util"
import RootStore from "./RootStore"
import BaselineStore from "./BaselineStore"

export interface CapabilityMultiplier {
  capId: string
  multiId: number
  overrided: boolean | null // flag for data from scenario
}

export interface MultiplierLevel {
  id: number
  name: string
  multiplier: number
  color: string
  overrided: boolean | null // flag for data from scenario
  deleted: boolean
}

class CapabilityStore {
  public rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  // look at moving these into this store
  //   @observable public capabilities: Capability[] = []
  //   @observable public capabilityMap: CapabilityMap[] = []

  @observable public baseMultiplierLevels: MultiplierLevel[] = [
    {
      id: 1,
      color: "#F44336",
      multiplier: 1,
      name: "low",
      overrided: false,
      deleted: false
    },
    {
      id: 2,
      color: "#FFC107",
      multiplier: 2,
      name: "medium",
      overrided: false,
      deleted: false
    },
    {
      id: 3,
      color: "#4CAF50",
      multiplier: 5,
      name: "high",
      overrided: false,
      deleted: false
    }
  ] // make private with public get

  // user function to access these
  @observable public multiplierLevelsOverrides: MultiplierLevel[] = []
  @observable public baseCapabilityMultipliers: CapabilityMultiplier[] = []
  @observable public capabilityMultipliersOverrides: CapabilityMultiplier[] = []
  @observable public updated: boolean = false

  /***
   * Returns a list of MultiplierLevels using both BaselineMultipliers
   * and overrides from ScenarioMultiplier
   */
  @computed public get multiplierLevelsList() {
    let compiledList = toJS(this.baseMultiplierLevels)
    // look at optimisations for this
    if (this.multiplierLevelsOverrides.length > 0) {
      this.multiplierLevelsOverrides.forEach(override => {
        let index = compiledList.findIndex(m => m.id === override.id)
        if (index !== -1) {
          if (override.deleted) {
            compiledList.splice(index, 1)
          } else {
            compiledList[index] = toJS(override)
            compiledList[index].overrided = true
          }
        } else if (override.deleted != true) {
          compiledList.push(toJS(override))
          compiledList[compiledList.length - 1].overrided = true
        }
      })
    }
    return compiledList
  }

  public getMultiplierLevel = (id: number) => {
    let multiplier = this.multiplierLevelsOverrides.find(m => m.id === id)
    if (isUndefined(multiplier)) {
      multiplier = this.baseMultiplierLevels.find(m => m.id === id)
    }
    return multiplier
  }

  /***
   * Updates scenarioMultiplierLevels with new multipler
   * updates existing value, otherwise creates new entry
   */
  public set updateMultiplierLevel(newMultiplier: MultiplierLevel) {
    let index = this.multiplierLevelsOverrides.findIndex(
      m => m.id === newMultiplier.id
    )

    if (index !== -1) {
      this.multiplierLevelsOverrides[index] = newMultiplier
    } else {
      this.multiplierLevelsOverrides.push(newMultiplier)
    }
    this.updated = true
  }

  /**
   * merges baseCapabilityMultipliers and CapabilityMultiplerOverrides
   * taking from overrides on doubleups
   *
   */
  @computed public get capabilityMultipliersList() {
    let compiledList = toJS(this.baseCapabilityMultipliers)
    if (this.capabilityMultipliersOverrides.length > 0) {
      this.capabilityMultipliersOverrides.forEach(override => {
        let index = compiledList.findIndex(m => m.capId === override.capId)
        if (index !== -1) {
          compiledList[index] = toJS(override)
        } else {
          compiledList.push(toJS(override))
        }
      })
    }
    return compiledList
  }

  /**
   * get multipler for a specified ID
   */
  public capabilityMultiplier = (ID: string) => {
    let multiplier = this.capabilityMultipliersOverrides.find(
      m => m.capId === ID
    )
    if (isUndefined(multiplier)) {
      multiplier = this.baseCapabilityMultipliers.find(m => m.capId === ID)
    }

    return multiplier
  }

  /**
   *
   */
  public capabilityUpdate = (capID: string, multiID: number) => {
    let multiplier = this.capabilityMultipliersOverrides.findIndex(
      m => m.capId === capID
    )
    if (multiplier === -1) {
      this.capabilityMultipliersOverrides.push({
        capId: capID,
        multiId: multiID,
        overrided: true
      })
    } else {
      this.capabilityMultipliersOverrides[multiplier].multiId = multiID
    }
    this.updated = true
  }

  /**
   * Controls the loading of capabilies from the DB
   * @param data data input from DB
   */
  public importCapabilities(data: any, baselineStore: BaselineStore) {
    if (data != null) {
      this.capabilityMultipliersOverrides = data.scenarioMultipliers
      this.baseCapabilityMultipliers = data.capabilityMultiplier
      this.multiplierLevelsOverrides = data.scenarioMultiplierLevels
      this.baseMultiplierLevels = data.multiplierLevels
    }
    let newbaseCapabilities = this.baseCapabilityMultipliers
    const multiID = this.baseMultiplierLevels[0].id
    baselineStore.capabilities.forEach(capability => {
      const index = newbaseCapabilities.findIndex(
        m => m.capId === capability.code
      )
      if (index === -1) {
        newbaseCapabilities.push({
          capId: capability.code,
          multiId: multiID,
          overrided: null
        })
      }
    })
    this.baseCapabilityMultipliers = newbaseCapabilities
    this.updated = true
  }
}

export default CapabilityStore
