import * as React from "react"
import * as ReactDOM from "react-dom"
import { toJS } from "mobx"

import { snapTools } from "../SnapTools/snapTools"
import { projectUsage } from "../../utils/portfolio/projectUsage"

import { Project } from "../../mobx/Project"
import LoadingSymbol from "./LoadingSymbol"

export interface Props {
  resource: string
  resourceIndex: string
  projectArray: Project[]
  selectedProject: string | undefined
  showHoverbox: (content: any, title?: any) => void
  hideHoverbox(): void
  changeHoverboxPosition: (x: number, y: number) => void
  setSelectedProject: (id: string | undefined) => void
  clearSelection(): void
}
export interface State {
  componentID: string
  displayLoading: boolean
}

class ResourceSplitDial extends React.Component<Props, State> {
  public myState: any
  constructor(props: Props) {
    super(props)
    this.state = {
      componentID: snapTools.createUniqueID(),
      displayLoading: true
    }
    this.myState = {
      componentID: "snapContainer",
      resource: this.props.resource,
      thisSnap: null,
      maxSize: 120,
      padding: 10,
      scale: 1,
      pieSpines: [],
      pieSegments: [],
      selectedDot: null,
      selectedDotClicked: null,
      lastJob: null,
      stateJobInfos: null,
      animationHandler: null,
      animationTick: 0,
      animationEnd: 0,
      animationPreloaded: true,
      frameRate: 30,
      animationTime: 0.5,
      displayLoading: true,
      domElement: null
    }
  }

  public componentDidMount() {
    const thisDom = ReactDOM.findDOMNode(this) as any
    if (thisDom) {
      this.myState.domElement = thisDom.getElementsByTagName("svg")[0]
    }
    if (this.myState.thisSnap == null) {
      this.dialSetup()
      this.fillInDetails()
    }
  }

  private getCentre = () => {
    return {
      x: this.myState.maxSize / 2 + this.myState.padding,
      y: this.myState.maxSize / 2 + this.myState.padding
    }
  }

  private dialSetup() {
    this.setState({ displayLoading: false })

    this.myState.thisSnap = snapTools.createSnap(this.state.componentID)

    snapTools.createCircle(
      this.myState.thisSnap.snap,
      this.myState.maxSize,
      this.myState.padding,
      "rgb(101, 101, 101)"
    )
    const { projectArray } = this.props

    const portfolioUsage: any = projectUsage(
      projectArray,
      this.props.resourceIndex
    )

    const percent = 360 / portfolioUsage.totalValue

    let covered = 0

    for (let i = 0; i < portfolioUsage.projectArray.length; i++) {
      const previousCovered: any = covered + 0
      covered += portfolioUsage.projectArray[i].amount * percent

      const quickLine = snapTools.drawLineAtAngle(
        this.getCentre(),
        covered,
        this.myState.maxSize / 2
      )

      const quickSegment = snapTools.pieSectorPath(
        this.getCentre(),
        0,
        this.myState.maxSize / 2,
        previousCovered - 90,
        covered - previousCovered
      )

      const pieSegmentShape = snapTools.drawPath(
        this.myState.thisSnap.snap,
        quickSegment,
        1,
        "rgba(0,0,0,0.01)",
        "#ccc"
      )

      const pieSegmentObject = {
        project_id: portfolioUsage.projectArray[i].id,
        shape: pieSegmentShape,
        selected: false,
        currentSize: covered - previousCovered,
        oldSize: covered - previousCovered,
        newSize: covered - previousCovered,
        degree:
          previousCovered +
          (portfolioUsage.projectArray[i].amount * percent) / 2,
        amount: portfolioUsage.projectArray[i].amount + 0
      }

      snapTools.applyMouseOver(pieSegmentObject, this.myState.thisSnap)
      snapTools.applyMouseOut(pieSegmentObject, this.myState.thisSnap)

      this.myState.pieSegments.push(pieSegmentObject)

      this.myState.pieSpines.push(
        snapTools.drawPath(
          this.myState.thisSnap.snap,
          quickLine,
          1,
          "none",
          "#ccc"
        )
      )
    }
  }

  private recreatePieSegments() {
    const { projectArray } = this.props

    for (let i = 0; i < this.myState.pieSegments.length; i++) {
      this.myState.pieSegments[i].shape.remove()
    }

    for (let i = 0; i < this.myState.pieSpines.length; i++) {
      this.myState.pieSpines[i].remove()
    }

    const portfolioUsage = projectUsage(projectArray, this.props.resourceIndex)

    const percent = 360 / portfolioUsage.totalValue

    let covered = 0

    this.myState.pieSegments = []
    this.myState.pieSpines = []

    for (let i = 0; i < portfolioUsage.projectArray.length; i++) {
      const previousCovered = covered + 0
      covered += portfolioUsage.projectArray[i].amount * percent

      const quickLine = snapTools.drawLineAtAngle(
        this.getCentre(),
        covered,
        this.myState.maxSize / 2
      )

      const quickSegment = snapTools.pieSectorPath(
        this.getCentre(),
        0,
        this.myState.maxSize / 2,
        previousCovered - 90,
        covered - previousCovered
      )

      const pieSegmentShape = snapTools.drawPath(
        this.myState.thisSnap.snap,
        quickSegment,
        1,
        "rgba(0,0,0,0.01)",
        "#ccc"
      )

      const pieSegmentObject = {
        project_id: portfolioUsage.projectArray[i].id,
        shape: pieSegmentShape,
        selected: false,
        currentSize: covered - previousCovered,
        oldSize: covered - previousCovered,
        newSize: covered - previousCovered,
        degree:
          previousCovered +
          (portfolioUsage.projectArray[i].amount * percent) / 2,
        amount: portfolioUsage.projectArray[i].amount + 0
      }

      snapTools.applyMouseOver(pieSegmentObject, this.myState.thisSnap)
      snapTools.applyMouseOut(pieSegmentObject, this.myState.thisSnap)

      this.myState.pieSegments.push(pieSegmentObject)

      this.myState.pieSpines.push(
        snapTools.drawPath(
          this.myState.thisSnap.snap,
          quickLine,
          1,
          "none",
          "#ccc"
        )
      )
    }
  }

  private animateSegments() {
    const { projectArray } = this.props

    if (this.myState.animationTick <= this.myState.animationEnd) {
      this.myState.animationTick += 1

      const portfolioUsage = projectUsage(
        projectArray,
        this.props.resourceIndex
      )

      const percent = 360 / portfolioUsage.totalValue

      let covered = 0

      const timeToComplete = this.myState.animationTime
      const frameRate = this.myState.frameRate

      const timeByFrames = timeToComplete * frameRate

      for (let i = 0; i < portfolioUsage.projectArray.length; i++) {
        const goalAmount = portfolioUsage.projectArray[i].amount * percent

        let thisPieSegment: any = null
        const pieSegmentsJS = toJS(this.myState.pieSegments)

        for (let j = 0; j < pieSegmentsJS.length; j++) {
          if (
            pieSegmentsJS[j].project_id === portfolioUsage.projectArray[i].id
          ) {
            thisPieSegment = this.myState.pieSegments[j]
            break
          }
        }
        let amount = 0

        if (typeof thisPieSegment !== "undefined" && thisPieSegment !== null) {
          if (Math.abs(thisPieSegment.currentSize - goalAmount) > 0.1) {
            if (thisPieSegment.currentSize - goalAmount >= 0) {
              amount =
                thisPieSegment.currentSize -
                Math.abs(thisPieSegment.oldSize - goalAmount) / timeByFrames
            } else {
              amount =
                thisPieSegment.currentSize +
                Math.abs(thisPieSegment.oldSize - goalAmount) / timeByFrames
            }
          } else {
            amount = goalAmount
          }

          thisPieSegment.currentSize = amount

          thisPieSegment.degree = covered + amount / 2
          thisPieSegment.amount = portfolioUsage.projectArray[i].amount + 0

          const previousCovered = covered + 0
          covered += amount

          const quickSegment = snapTools.pieSectorPath(
            this.getCentre(),
            0,
            this.myState.maxSize / 2,
            previousCovered - 90,
            covered - previousCovered
          )

          thisPieSegment.shape.attr("d", quickSegment)
        }
      }
    } else {
      if (this.myState.animationHandler != null) {
        this.myState.animationTick = 0
        clearInterval(this.myState.animationHandler)
        this.myState.animationHandler = null
      }
    }
  }

  private fillInDetails() {
    const { projectArray } = this.props

    for (let i = 0; i < this.myState.pieSpines.length; i++) {
      this.myState.pieSpines[i].remove()
    }

    this.myState.pieSpines = []

    const portfolioUsage = projectUsage(projectArray, this.props.resourceIndex)

    const percent = 360 / portfolioUsage.totalValue

    let covered = 0

    for (let i = 0; i < portfolioUsage.projectArray.length; i++) {
      covered += portfolioUsage.projectArray[i].amount * percent

      const quickLine = snapTools.drawLineAtAngle(
        this.getCentre(),
        covered,
        this.myState.maxSize / 2
      )

      if (typeof this.myState.pieSegments[i] !== "undefined") {
        this.myState.pieSpines.push(
          snapTools.drawPath(
            this.myState.thisSnap.snap,
            quickLine,
            0,
            "none",
            "#ccc"
          )
        )

        this.myState.pieSegments[i].oldSize = this.myState.pieSegments[
          i
        ].currentSize
        this.myState.pieSegments[i].newSize =
          portfolioUsage.projectArray[i].amount * percent
      }
    }

    if (!this.myState.animationPreloaded) {
      if (this.myState.animationHandler == null) {
        this.myState.animationEnd =
          this.myState.animationTime * this.myState.frameRate
        this.myState.animationTick = 0
        this.myState.animationHandler = setInterval(
          () => this.animateSegments(),
          1000 / this.myState.frameRate
        )
      } else {
        this.myState.animationTick = 0
      }
    } else {
      this.myState.animationPreloaded = false
    }
  }

  public componentDidUpdate() {
    const { projectArray, selectedProject } = this.props

    if (this.myState.thisSnap == null) {
      this.dialSetup()
    }

    let counter = 0

    for (let i = 0; i < this.myState.pieSegments.length; i++) {
      for (let j = 0; j < projectArray.length; j++) {
        if (projectArray[j].code === this.myState.pieSegments[i].project_id) {
          counter++
          break
        }
      }
    }

    if (
      projectArray.length !== counter ||
      this.myState.pieSegments.length !== counter
    ) {
      this.recreatePieSegments()
    }

    this.fillInDetails()

    for (let i = 0; i < this.myState.pieSegments.length; i++) {
      if (this.myState.pieSegments[i].project_id === selectedProject) {
        this.myState.pieSegments[i].shape.attr({ fill: "red" })
      } else {
        this.myState.pieSegments[i].shape.attr({ fill: "rgba(0,0,0,0.01)" })
      }
    }
  }

  public componentWillUnmount() {
    this.myState.thisSnap = null
    const el = document.getElementById(this.state.componentID)
    if (el !== null) {
      el.innerHTML = ""
    }
    if (this.myState.animationHandler != null) {
      clearInterval(this.myState.animationHandler)
    }
  }

  private searchSnap = () => {
    if (typeof this.myState.thisSnap.customProperties !== "undefined") {
      if (
        typeof this.myState.thisSnap.customProperties.selectedProject !==
        "undefined"
      ) {
        this.props.setSelectedProject(
          this.myState.thisSnap.customProperties.selectedProject
        )

        for (let i = 0; i < this.myState.pieSegments.length; i++) {
          if (
            this.myState.pieSegments[i].project_id ===
            this.myState.thisSnap.customProperties.selectedProject
          ) {
            if (this.myState.domElement != null) {
              const thisPosition = this.myState.domElement.getBoundingClientRect()
              const centerCircle = this.getCentre()

              const dividingPosition = snapTools.positionAtAngle(
                centerCircle,
                this.myState.pieSegments[i].degree,
                this.myState.maxSize / 2 + 20
              )

              const content = this.myState.pieSegments[i].amount
              const title = ""
              this.props.showHoverbox(content, title)

              const x = thisPosition.left + dividingPosition.x + centerCircle.x
              const y = thisPosition.top + dividingPosition.y + centerCircle.y
              this.props.changeHoverboxPosition(x, y)

              break
            }
          }
        }
      } else {
        this.props.setSelectedProject(undefined)
      }
    }
  }

  public render(): JSX.Element {
    const returnItems = (
      <div style={{ position: "relative" }}>
        <LoadingSymbol inUse={this.myState.displayLoading}>
          <svg
            id={this.state.componentID}
            style={{
              width: this.myState.maxSize + this.myState.padding * 2,
              height: this.myState.maxSize + this.myState.padding * 2
            }}
            onMouseMove={this.searchSnap}
            onMouseOut={this.props.clearSelection}
          />
        </LoadingSymbol>
      </div>
    )

    return returnItems
  }
}

export default ResourceSplitDial
